<template>
  <div :class="informerClass" v-html="informerHtml" @click="callModal"></div>
  <div :class="`modal fade ${popup ? 'show' : ''}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" :style="`padding-right: 4px; ${popup ? 'display: block' : 'display: none'}`">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div :class="informerTextClass" v-html="informerTextHtml"></div>
        <div class="modal-footer">
          <button @click="callModal" type="button" class="btn btn-danger">Ок</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="`${popup ? 'modal-backdrop-pers' : ''}`"></div>
</template>

<script>
export default {
  name: 'my-informer',
  data() {
    return {
      colorOrange: false,
      colorRed: false,
      colorGreen: false,
      colorBlue: false,
      popup: false
    }
  },
  props: {
    color: String,
    type: String,
    text: String
  },

  methods: {
    showDate(){
      if(this.color === 'orange'){
        this.colorOrange = true
      } else if(this.color === 'red'){
        this.colorRed = true
      } else if(this.color === 'green'){
        this.colorGreen = true
      } else {
        this.colorBlue = true
      }
    },

    callModal(){
      this.popup = !this.popup
    }
  },

  computed: {
    informerHtml() {
      let label = ''
      if (this.type === 'warning') {
        label += `<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>`
      } else if (this.type === 'question') {
        label += `<i class="fa fa-question-circle" aria-hidden="true"></i>`
      } else {
        label += `<i class="fa fa-info-circle" aria-hidden="true"></i>`
      }
      return label
    },

    informerTextHtml() {
      let label = '<p>'
      if (this.type === 'warning') {
        label += `<i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> ${this.text}`
      } else if (this.type === 'question') {
        label += `<i class="fa fa-question-circle  mr-2" aria-hidden="true"></i> ${this.text}`
      } else {
        label += `<i class="fa fa-info-circle  mr-2" aria-hidden="true"></i> ${this.text}`
      }
      label += '</p>'
      return label
    },

    informerClass() {
      return {
        'info-informer i': true,
        'info-color-orange': this.colorOrange,
        'info-color-red': this.colorRed,
        'info-color-green': this.colorGreen,
        'info-color-blue': this.colorBlue,
      };
    },

    informerTextClass() {
      return {
        'modal-body': true,
        'info--text-color-orange': this.colorOrange,
        'info--text-color-blue': this.colorBlue,
        'info--text-color-red': this.colorRed,
        'info--text-color-green': this.colorGreen,
        'info--text-color p': true,
        'info--text-color i': true,
      };
    }
  },

  mounted(){
    this.showDate()
  }

}
</script>

<style>
  .info-color-orange{
    color: #db7e06;
  }
  .info-color-red{
    color: #f42b3d;
  }
  .info-color-green{
    color: #3cb72c;
  }
  .info-color-blue{
    color: #3378ff;
  }
  .info-informer i{
    font-size: 20px;
    cursor: pointer;
  }
  .info--text-color-orange{
    background-color: #db7e06;
  }
  .info--text-color-red{
    background-color: #f42b3d;
  }
  .info--text-color-green{
    background-color: #3cb72c;
  }
  .info--text-color-blue{
    background-color: #3378ff;
    color: #FFF !important;
  }

  .info--text-color p{
    color: #FFF;
    font-size: 17px;
    white-space: normal;
    text-align: left;
  }
  .info--text-color i{
    color: #FFF;
    font-size: 35px !important;
  }
</style>