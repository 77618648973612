import { axiosCreateTariff, axiosGetTariff, axiosAutomaticPayment, axiosEditSettings, axiosSettingsStatus, axiosEditOpenAi, axiosExtendTariff, axiosEditYandexGPT } from '@/api/index'

export const tariffModule = {
  state: () => ({
    loading: false,
    loadingAutoExtends: false,
    loadingSettings: false,
    loadingAutoOpenAI: false,
    loadingEditOpenAI: false,
    loadingAutoYandexGPT: false,
    loadingEditYandexGPT: false,
    error: '',
    message: '',
    messageAutoPay: '',
    modal: false,
    tariff: {},
    type: 'Prime',
    sum: 600,
    endSum: 600,
    notSaleSum: 600,
    percent: 0,
    paymentPeriod: 1,
    allType: ['Prime','Business'],
    allPaymentPeriod: [{id: 1, name: '1 месяц'}, {id: 3, name: '3 месяца'}, {id: 6, name: '6 месяцев'}, {id: 12, name: '12 месяцев'}],
    tokenOpenAI: '',
    tokenYandexGPT: '',
    catalogYandexGPT: '',
    param: {test: ''}
   
  }),

  getters: {
    
  },

  mutations: {
    setLoading(state, v){state.loading = v},
    setLoadingAutoExtends(state, v){state.loadingAutoExtends = v},
    setLoadingAutoOpenAI(state, v){state.loadingAutoOpenAI = v},
    setLoadingSettings(state, v){state.loadingSettings = v},
    setLoadingEditOpenAI(state, v){state.loadingEditOpenAI = v},
    setLoadingAutoYandexGPT(state, v){state.loadingAutoYandexGPT = v},
    setLoadingEditYandexGPT(state, v){state.loadingEditYandexGPT = v},
    setError(state, v){state.error = v},
    setMessage(state, v){state.message = v},
    setMessageAutoPay(state, v){state.messageAutoPay = v},
    setModal(state, v){state.modal = v},
    setTariff(state, v){
      state.tariff = v
      if(Object.keys(v).length){
        state.type = v.type
        state.sum = v.type === 'Prime' ? 600 : 1900
        state.percent = { 3: 5, 6: 10, 12: 15 }[v.payment_period] || 0
        state.paymentPeriod = v.payment_period
        state.notSaleSum = state.sum * v.payment_period
        state.endSum = v.status ? v.end_sum : state.sum * state.paymentPeriod * (1 - state.percent / 100)
      }
    },
    setType(state, v){
      state.type = v
      state.sum = v === 'Prime' ? 600 : 1900
      state.endSum = state.sum * state.paymentPeriod * (1 - state.percent / 100)
      state.notSaleSum = state.sum * state.paymentPeriod
    },
    setPaymentPeriod(state, v){
      state.paymentPeriod = Number(v)
      state.percent = { 3: 5, 6: 10, 12: 15 }[v] || 0
      state.endSum = state.sum * v * (1 - state.percent / 100)
      state.notSaleSum = state.sum * v
    },
    setTokenOpenAI(state, v){state.tokenOpenAI = v},
    setTokenYandexGPT(state, v){state.tokenYandexGPT = v},
    setCatalogYandexGPT(state, v){state.catalogYandexGPT = v},
    setParamText(state, v){state.param.text = v},
  },

  actions: {
    // Получаем тариф
    async actionGetTariff({commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosGetTariff(rootState.token)
        console.log(response.tariff)
        commit('setTariff', response.tariff)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetTariff')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Получаем тариф второстепенно
    async actionSecondaryGetTariff({commit, rootState}){
      try {
        const response = await axiosGetTariff(rootState.token)
        commit('setTariff', response.tariff)
      } catch (e) {
        console.log(e.response.data)
      }
    },

    // Активируем тариф
    async actionCreateTariff({state, commit, rootState, dispatch}){
      try {
        commit('setModal', false)
        commit('setLoading', true)
        const response = await axiosCreateTariff(rootState.token, {type: state.type, payment_period: state.paymentPeriod})
        commit('setMessage', response.message)
        await dispatch('actionUser', null, { root: true })
        await dispatch('actionGetTariff');
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateTariff')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    //Продляем тарифф
    async actionExtendTariff({state, commit, rootState, dispatch}){
      try {
        commit('setModal', false)
        commit('setLoading', true)
        const response = await axiosExtendTariff(rootState.token, {type: state.type, payment_period: state.paymentPeriod})
        commit('setMessage', response.message)
        await dispatch('actionUser', null, { root: true })
        await dispatch('actionGetTariff');
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateTariff')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Вкл/Выкл автоматическое продления
    async actionAutomaticPayment({commit, rootState, dispatch}){
      try {
        commit('setLoadingAutoExtends', true)
        const response = await axiosAutomaticPayment(rootState.token)
        commit('setMessageAutoPay', response.message)
        await dispatch('actionSecondaryGetTariff');
        setTimeout(function () {
          commit('setMessageAutoPay', '')
        }, 5000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionAutomaticPayment')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingAutoExtends', false)
        }, 500);
      }
    },

    // Используем свои данные для интеграции или нет
    async actionSettingsStatus({state, commit, rootState, dispatch}, param){
      commit('setParamText', param)
      try {
        if(state.param.text === 'OpenAI'){
          commit('setLoadingAutoOpenAI', true)
        }
        if(state.param.text === 'YandexGPT'){
          commit('setLoadingAutoYandexGPT', true)
        }
        const response = await axiosSettingsStatus(rootState.token, state.param.text)
        commit('setMessage', response.message)
        commit('setParamText', '')
        await dispatch('actionSecondaryGetTariff');
        setTimeout(function () {
          commit('setMessage', '')
        }, 5000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionSettingsStatus', state.param.text)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
            commit('setLoadingAutoOpenAI', false)
            commit('setLoadingAutoYandexGPT', false)
        }, 500);
      }
    },

    // Сохроняем свой ключ openAI
    async actionEditOpenAi({state, commit, rootState, dispatch}){
      try {
        commit('setLoadingEditOpenAI', true)
        const response = await axiosEditOpenAi(rootState.token, {data: {token: state.tokenOpenAI}})
        commit('setMessage', response.message)
        await dispatch('actionSecondaryGetTariff');
        commit('setTokenOpenAI', '')
        setTimeout(function () {
          commit('setMessage', '')
        }, 5000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditOpenAi')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingEditOpenAI', false)
        }, 500);
      }
    },

    // Сохроняем свой ключ YandexGPT
    async actionEditYandexGPT({state, commit, rootState, dispatch}){
      try {
        commit('setLoadingEditYandexGPT', true)
        const response = await axiosEditYandexGPT(rootState.token, {data: {token: state.tokenYandexGPT, yandexCatalog: state.catalogYandexGPT}})
        commit('setMessage', response.message)
        await dispatch('actionSecondaryGetTariff');
        commit('setTokenYandexGPT', '')
        commit('setCatalogYandexGPT', '')
        setTimeout(function () {
          commit('setMessage', '')
        }, 5000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditYandexGPT')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingEditYandexGPT', false)
        }, 500);
      }
    },


    // Сохронем настройки
    async actionEditSettings({state, commit, rootState, dispatch}){
      try {
        commit('setLoadingSettings', true)
        const response = await axiosEditSettings(rootState.token, {type: state.type, payment_period: state.paymentPeriod})
        await dispatch('actionSecondaryGetTariff');
        const text = Object.keys(state.tariff).length > 0 && state.tariff.status ? `${response.message}. Новые настройки тарифного плана и периода подписки вступят в силу с момента следующего продления.` : `${response.message}`
        commit('setMessage',text)
        setTimeout(function () {
          commit('setMessage', '')
        }, 10000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditSettings')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingSettings', false)
        }, 500);
      }
    },

    
  },
  namespaced: true
}