import { createStore } from 'vuex'
import { ordersModule } from '@/store/ordersModule'
import { balanceHistoryModule } from '@/store/balanceHistoryModule'
import { aiModule } from '@/store/aiModule'
import { partnerModule } from '@/store/partnerModule'
import { tariffModule } from '@/store/tariffModule'
import { chatWidgetModule } from '@/store/chatWidgetModule'
import { supportWidgetModule } from '@/store/supportWidgetModule'
import { axiosReg, axiosLogout, axiosLogin, axiosAuth, axiosUser, axiosEditUser, axiosRequisites, axiosTopUpBalance, axiosTemporaryPayment, axiosTemporaryPeriod, axiosResendCode, axiosEditPhone, axiosResendCodePhone } from '@/api/index'
import router from "@/router";
import VueCookies from 'vue-cookies'

export default createStore({
  state: {
    isAuth: false,
    loading: false,
    loadingSmall: false,
    token: '',
    statusCode: false,
    authCode: '',
    authPhone: '',
    authCountry: 'RU',
    newAuthCountry: 'RU',
    authMask: '+7 (###) ###-##-##',
    authPlaceholder: '+7 (___) ___-__-__',
    newPhone: '',
    country: [{id: 'RU', name: 'RU'}, {id: 'BY', name: 'BY'},{id: 'KZ', name: 'KZ'}],
    reg: {name: '', email: '', phone: '', promocode: '', ref: ''},
    user: [],
    image: null,
    sumTopUpBalance: 100,
    numbering: [{id: 30, name: 30}, {id: 60, name: 60}, {id: 100, name: 100}, {id: 150, name: 150}, {id: 200, name: 200}],
    temporary: {
      upBalanceParam: '',
			upBalanceParamMetod: 'bank_card',
      string: '',
      payment: false,
      period: false,
      num: 0
    },
    modal: false,
    img: null,
    errors: [],
    errorsPass: [],
    errorsRequisites: [],
    errorsBankRequisites: [],
    message: '',
    error: '',
    neuro: false,
    actionSWidget: false
  },
  getters: {
    
  },
  mutations: {
    setStatusCode(state, v) {state.statusCode = v},
    setAuthCode(state, v) {state.authCode = v},
    setAuthPhone(state, v) {state.authPhone = v},
    setNewAuthCountry(state, v) {state.newAuthCountry = v},
    setAuthCountry(state, v) {
      state.authCountry = v
      state.authPhone = ''
      if(v === 'RU' || v === 'KZ'){
        state.authMask = '+7 (###) ###-##-##'
        state.authPlaceholder = '+7 (___) ___-__-__'
      } else if(v === 'BY') {
        state.authMask = '+375 (##) ###-##-##'
        state.authPlaceholder = '+375 (__) ___-__-__'
      }
    },
    setNewPhone(state, v){state.newPhone = v},
    setNeuro(state, v){state.neuro = !state.neuro},
    setErrorsRequisites(state, v){state.errorsRequisites = v},
    setErrorsBankRequisites(state, v){state.errorsBankRequisites = v},
    setErrors(state, error){state.errors = error},
    setError(state, error){state.error = error},
    setErrorsPass(state, error){state.errorsPass = error},
    setMessage(state, message){state.message = message},
    setRegName(state, name){state.reg.name = name},
    setRegPhone(state, phone){state.reg.phone = phone; state.authPhone = phone},
    setRegEmail(state, email){state.reg.email = email},
    setRegRef(state, ref){state.reg.ref = ref},
    setRegPromocode(state, promocode){state.reg.promocode = promocode},
    setRegRole(state, role){state.reg.role = role},
    setLoading(state, loading){state.loading = loading},
    setLoadingSmall(state, loading){state.loadingSmall = loading},
    setIsAuth(state, isAuth) {state.isAuth = isAuth},
    setToken(state, token) {state.token = token},
    setUser(state, user) {state.user = user},
    setImage(state, v){state.image = v},
    setEditName(state, v){state.user.name = v},
    setEditPhone(state, v){state.user.phone = v},
    setEditEmail(state, v){state.user.email = v},
    setUserImage(state, v){state.user.image = v},
    setRequisitesName(state, v){state.user.requisites_yur.requisites.name = v},
    setRequisitesAddress(state, v){state.user.requisites_yur.requisites.address = v},
    setRequisitesCity(state, v){state.user.requisites_yur.requisites.city = v},
    setRequisitesRegion(state, v){state.user.requisites_yur.requisites.region = v},
    setRequisitesPostcode(state, v){state.user.requisites_yur.requisites.postcode = v},
    setRequisitesInn(state, v){state.user.requisites_yur.requisites.inn = v},
    setRequisitesKpp(state, v){state.user.requisites_yur.requisites.kpp = v},
    setRequisitesRs(state, v){state.user.requisites_yur.requisites.rs = v},
    setRequisitesBank(state, v){state.user.requisites_yur.requisites.bank = v},
    setRequisitesBic(state, v){state.user.requisites_yur.requisites.bic = v},
    setRequisitesKs(state, v){state.user.requisites_yur.requisites.ks = v},
    setUserPackage(state, v){state.user.user_package = v},
    setBalance(state, v){state.user.balance.account = v},
    setSumTopUpBalance(state, v){state.sumTopUpBalance = v},
    setTopUpBalance(state, v){state.user.top_up_balance = v},
    setTemporaryUpBalanceParam(state, v){state.temporary.upBalanceParam = v},
		setTemporaryUpBalanceParamMetod(state, v){state.temporary.upBalanceParamMetod = v},
    setTemporaryPayment(state, v){state.temporary.payment = v},
    setTemporaryPeriod(state, v){state.temporary.period = v},
    setTemporaryNum(state, v){state.temporary.num = v},
    setUserRole(state, v){state.user.role = v},
    setModal(state){state.modal = !state.modal},
    setImg(state, v){state.img = v},
    setTemporaryString(state, v){state.temporary.string = v},
    setPaymentObj(state, v){state.payment = v},
    setActionSWidget(state){state.actionSWidget = !state.actionSWidget},
    //setBalance(state, v){state.user.balance.balance = v},
  },

  actions: {

    async actionReg({state, commit}) {
      commit('setRegRef', VueCookies.get('ref_code'))
      let errors = []
      if(!state.reg.name){errors.push('Укажите имя.')}

      if(!state.reg.phone){errors.push('Укажите номер телефона.')} 

      if(errors.length){
        commit('setErrors', errors)
        return
      }
      commit('setErrors', '')

      try{
        const data = {name: state.reg.name, phone: state.reg.phone, email: state.reg.email, country: state.authCountry, ref: state.reg.ref, promocode: state.reg.promocode}
        const response = await axiosReg(data)
        if(response.status === 'ok'){
          commit('setStatusCode', true)
        }
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
      }
    },
    
    async actionResendCode({state, commit}) {
      let errors = []

      if(!state.authPhone){errors.push('Укажите номер телефона.')} 

      if(errors.length){
        commit('setErrors', errors)
        return
      }
      commit('setErrors', '')

      try{
        const response = await axiosResendCode(state.authPhone, state.authCountry)
        if(response.status === 'ok'){
          commit('setStatusCode', true)
        }
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
      }
    },
    
    async actionLogin({state, commit, dispatch}) {
      let errors = []
      try{
        const response = await axiosLogin({phone: state.authPhone, code: state.authCode})
        commit('setLoading', true)
        commit('setToken', response.token)
        await dispatch('actionUser');
        await dispatch('ai/actionGetPromptSystem')
        await dispatch('ai/actionGetModelAi')
        await dispatch('ai/actionGetModelImageAi')
        commit('setStatusCode', false)
        commit('setAuthCode', '')
        commit('setAuthPhone', '')
        router.push('/feed')
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
      } finally {
        commit('setAuthCode', '')
        commit('setLoading', false)
      }
    },

    async actionAuthAccess({commit}){
      try {
        const response = await axiosAuth()
        commit('setToken', response.token)
        commit('setIsAuth', true)
      } catch (e) {
        console.log(e.response.data)
      }
    },

    async actionAuth({commit, dispatch}, link){
      try {
        commit('setLoading', true)
        const response = await axiosAuth()
        commit('setToken', response.token)
        commit('setIsAuth', true)
        await dispatch('actionUser');
        await dispatch('ai/actionGetPromptSystem')
        await dispatch('ai/actionGetModelAi')
        await dispatch('ai/actionGetModelImageAi')
        if(link){
          router.push(link)
        } else {
          router.push('/feed')
        }
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      } catch (e) {
        router.push('/login')
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      } finally {
  
      }
    },

    async actionLogout({commit}){
      try {
        commit('setLoading', true)
        await axiosLogout()
        commit('setToken', '')
        commit('setIsAuth', false)
        router.push('/login')
      } catch (e) {
        console.log(e.response.data)
      } finally {
        commit('setLoading', false)
      }
    },

    async actionUser({state, commit, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosUser(state.token)
        commit('setUser', response)
        commit('setNewAuthCountry', response.country)
        commit('setIsAuth', true)
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      } catch (e) {
        if(e.response.data.statusCode === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionUser')
        }
        console.log(e.response.data)
      }
    },

    async actionAuthorization({commit, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosAuth()
        commit('setToken', response.token)
        commit('setIsAuth', true)
        await dispatch('actionUser');
        await dispatch('ai/actionGetPromptSystem')
        await dispatch('ai/actionGetModelAi')
        await dispatch('ai/actionGetModelImageAi')
        router.push('/feed')
      } catch (e) {
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionEditUser({state, commit, dispatch}){
      let errors = []
      try {
        if(!state.user.name){errors.push('Укажите имя.')}

        if(errors.length){
          commit('setErrors', errors)
          return
        }
        commit('setErrors', '')
        const data = {'name': state.user.name, 'email': state.user.email, 'country': state.user.country}
        const response = await axiosEditUser(state.token, data, state.image)
        commit('setUserImage', response.user.image)
        commit('setMessage', response.message)
        commit('setImg', null)
        commit('setImage', '')
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionEditUser')
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
        console.log(e.response.data)
      }
    },

    async actionEditPhone({state, commit, dispatch}){
      let errors = []
      try {
        const response = await axiosEditPhone(state.token, state.authCode)
        commit('setMessage', response.message)
        await dispatch('actionAuthAccess')
        await dispatch('actionUser')
        commit('setStatusCode', false)
        commit('setAuthCode', '')
        commit('setNewPhone', '')
        commit('setErrors', '')
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionEditPhone')
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
        console.log(e.response.data)
      }
    },

    async actionResendCodePhone({state, commit, dispatch}){
      let errors = []
      try {
        await axiosResendCodePhone(state.token, state.newPhone, state.authCountry)
        commit('setStatusCode', true)
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionResendCodePhone')
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
        console.log(e.response.data)
      }
    },

    async actionRequisites({state, commit}){
      let errors = []
      try {
        if(!state.user.requisites_yur.requisites.name){errors.push('Поле "Название организации" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.address){errors.push('Поле "Адрес" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.inn){errors.push('Поле "ИНН" не должно быть пустым')}

        if(errors.length){
          commit('setErrorsRequisites', errors)
          return
        }
        commit('setErrorsRequisites', '')

        const response = await axiosRequisites(state.token, {"requisites": state.user.requisites_yur.requisites})
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionRequisites')
        } else {
          errors.push(e.response.data)
          commit('setErrorsRequisites', errors)
        }
        console.log(e.response.data)
      }
    },

    async actionBankRequisites({state, commit}){
      let errors = []
      try {
        if (!state.user.requisites_yur.requisites.rs){errors.push('Поле "Расчетный счет" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.bank){errors.push('Поле "Банк" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.bic){errors.push('Поле "БИК" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.ks){errors.push('Поле "Корр. счет" не должно быть пустым')}

        if(errors.length){
          commit('setErrorsBankRequisites', errors)
          return
        }
        commit('setErrorsBankRequisites', '')

        const response = await axiosRequisites(state.token, {"requisites": state.user.requisites_yur.requisites})
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionBankRequisites')
        } else {
          errors.push(e.response.data)
          commit('setErrorsBankRequisites', errors)
        }
        console.log(e.response.data)
      }
    },

    async actionOneYur({state, dispatch}){
      try {
        const response = await axiosRequisites(state.token, {"requisites": state.user.requisites_yur.requisites})
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionOneYur')
        }
        console.log(e.response.data)
      }
    },

    // Оплата баланса
    async actionTopUpBalance({state, commit, dispatch}, param){
      commit('setTemporaryUpBalanceParam', param)
      try {
        const data = {'payment_method': state.temporary.upBalanceParam, 'payment_method_data': state.temporary.upBalanceParamMetod, 'payable': state.sumTopUpBalance}
        const response = await axiosTopUpBalance(state.token, data);
        console.log(response)
        commit('setTopUpBalance', response.payment)
        response.payment.payment_method === 'Безнал' ? router.push('/invoice-balance') : window.location.href = response.url
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionTopUpBalance', state.temporary.upBalanceParam)
        }
        console.log(e.response.data)
      }
    },

    // Автоматическая оплата
    async actionTemporaryPayment({state, commit, dispatch}){
      try {
        commit('setLoadingSmall', true)
        const response = await axiosTemporaryPayment(state.token, state.temporary.payment);
        if(response.status === 'ok'){
          await dispatch('actionUser');
          commit('setMessage', response.message)
          setTimeout(function () {
            commit('setMessage', '')
          }, 5000);
        }

      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionTemporaryPayment', state.temporary.payment)
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingSmall', false)
        }, 500);
      }
    },

    async actionTemporaryPeriod({state, commit, dispatch}){
      try {
        commit('setLoadingSmall', true)
        const response = await axiosTemporaryPeriod(state.token, state.temporary.period);
        if(response.status === 'ok'){
          await dispatch('actionUser');
          commit('setMessage', response.message)
          setTimeout(function () {
            commit('setMessage', '')
          }, 5000);
        }

      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionTemporaryPeriod', state.temporary.period)
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingSmall', false)
        }, 500);
      }
    }

  },
  
  modules: {
    orders: ordersModule,
    balanceHistory: balanceHistoryModule,
    ai: aiModule,
    chatWidget: chatWidgetModule,
    supportWidget: supportWidgetModule,
    partner: partnerModule,
    tariff: tariffModule,
  }
})
