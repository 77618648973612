<template>
  <div class="container">
    <div class="row no-gutters height-self-center">
      <div class="col-sm-12 text-center align-self-center">
        <div class="iq-error position-relative">
          <img src="/images/error/Datum_404.png" class="img-fluid iq-error-img iq-error-img-dark mx-auto" alt="">
          <img src="/images/error/Datum_404.png"  class="img-fluid iq-error-img mb-0" alt="">
          <h2 class="mb-0">Oops! Эта страница не найдена.</h2>
          <p>Возможно, страницы, которую вы хотели открыть, не существует.</p>
          <router-link to="/" class="btn btn-primary mt-3">
            <svg width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <span>На главную</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  mounted(){
    document.title = "404"
  }
}
</script>