<template>
  <pre>
  </pre>
  <div v-if="loading" class="card-body"><loading-small /></div>
  <Accordion v-else class=" accard">
    <AccordionTab v-for="(text, index) in textList" :key="index">
      <template v-slot:header>
        <span>{{ text.name }}</span>
      </template>
      <div class="mb-0">
        <h6 class="mb-2">Промпт:</h6>
        <pre>{{ text.contents.name }}</pre>
        <h6 class="mt-5 mb-2">Ответ:</h6>
        <pre>{{ text.contents.message }}</pre>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import 'primevue/resources/themes/lara-light-green/theme.css'
export default {
  components: {
    Accordion,
    AccordionTab
  },
  name: 'text-list',
  props: {
    textList: Array,
    loading: Boolean
  },
  methods: {
    ...mapMutations({setEditId: 'ai/setEditId', setEditName: 'ai/setEditName', setShow: 'ai/setShow'}),
    ...mapActions({}), 
  },
}
</script>
<style>
.accard .p-icon{
  background: none;
  height: auto;
  width: auto;
  color: #324253;
}

.accard .p-accordion-tab{
  
  margin-bottom: 30px;

}

.accard .p-accordion-header .p-accordion-header-link{
  box-shadow: 0 10px 30px 0 rgba(0,60,179,.05);
    border: 0 solid #dfe7ff;
  background-color: #FFF;
  border-radius: 5px
}

.accard .p-accordion-header-text{
  font-size: 16px;
  color: #01041b;
  font-weight: normal;
}

.icon-edit{
  position: absolute;
  right: 20px;
  padding: 10px;
}

.icon-edit:hover{
  color: red;
}

pre {
  overflow-x: hidden;
}
  
</style>