<template>
  <div class="card">
    <div class="mobile-my card-header d-flex justify-content-between mobile-home">
      <div class="header-title">
        <h4 class="card-title">Последние изображения, созданные AI</h4>
      </div>
      <div class="card-header-toolbar d-flex align-items-center">                  
        <div class="btn-group btn-group-toggle btn-group-sm btn-group-edges mr-5 model-ai-preb"> 
          <span @click="selectNameAiImage('Kandinsky')" :class="`button btn button-icon ${listNameAiImage === 'Kandinsky' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">Kandinsky</span>
          <span @click="selectNameAiImage('DALL-E')" :class="`button btn button-icon ${listNameAiImage === 'DALL-E' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">DALL-E</span>
          <span @click="selectNameAiImage('Midjourney')" :class="`button btn button-icon ${listNameAiImage === 'Midjourney' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">Midjourney</span>
        </div>   
      </div>
    </div>
  </div>
  <div v-if="loading" class="card-body"><loading-small /></div>
  <div v-else class="row">
    <div class="col-md-6 mobile-home" v-for="chat in imageList" :key="chat.id">
      <div class="card">
        <div class="card-body">
          <div class="">
            <div class="mb-2">{{ chat.name }}</div>
            <img :src="`https://cognitiveai.hb.bizmrg.com/${chat.url}.jpg`" width="500">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'latest-images',
  methods: {
    ...mapMutations({setImagesListHome: 'ai/setImagesListHome', setListNameAiImage: 'ai/setListNameAiImage'}),
    ...mapActions({actionGetImageListHome: 'ai/actionGetImageListHome'}),

    selectNameAiImage(param){
      this.setListNameAiImage(param)
      this.actionGetImageListHome();
    },
 
  },

  computed: {
    ...mapState({
      imageList: state => state.ai.imageListHome,
      listNameAiImage: state => state.ai.listNameAiImage,
      loading: state => state.ai.loadingImageHome,
    }),
  },

  mounted(){
    this.actionGetImageListHome();
  },

  unmounted(){
    this.setImagesListHome([])
    this.setListNameAiImage('Kandinsky')
  }
}
</script>