<template>
  <div class="card">
    <div class="mobile-my card-header d-flex justify-content-between mobile-home">
      <div class="header-title">
        <h4 class="card-title">Последние чат-сессии с AI</h4>
      </div>
      <div class="card-header-toolbar d-flex align-items-center">                  
        <div class="btn-group btn-group-toggle btn-group-sm btn-group-edges mr-5 model-ai-preb"> 
          <span @click="selectNameAi('GPT-3.5')" :class="`button btn button-icon ${chatListNameAI === 'GPT-3.5' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">GPT-3.5</span>
          <span @click="selectNameAi('GPT-4')" :class="`button btn button-icon ${chatListNameAI === 'GPT-4' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">GPT-4</span>
          <span @click="selectNameAi('GPT-4o')" :class="`button btn button-icon ${chatListNameAI === 'GPT-4o' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">GPT-4o</span>
          <span @click="selectNameAi('YandexGPT')" :class="`button btn button-icon ${chatListNameAI === 'YandexGPT' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">YandexGPT</span>
        </div>   
      </div>
    </div>
  </div>
  <div v-if="loading" class="card-body"><loading-small /></div>
  <div v-else class="iq-accordion career-style faq-style mobile-home">       
    <div class="card iq-accordion-block chat-choose" v-for="chat in chatList" :key="chat.id" @click="chooseChat({id: chat.id, nameAi: chatListNameAI})">
      <div class="clearfix">
        <div class="container">
          <div class="row py-2">
            <div class="col-sm-12">
              <span>{{ chat.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'latest-chats',
  methods: {
    ...mapMutations({setChatListHome: 'ai/setChatListHome', setChatListNameAI: 'ai/setChatListNameAI'}),
    ...mapActions({actionGetChatListHome: 'ai/actionGetChatListHome', actionChooseChat: 'ai/actionChooseChat'}),

    selectNameAi(param){
      this.setChatListNameAI(param)
      this.actionGetChatListHome();
    },

    chooseChat(data){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.actionChooseChat(data)
      this.$router.push('/chat')
    },
 
  },

  computed: {
    ...mapState({
      chatList: state => state.ai.chatListHome,
      chatListNameAI: state => state.ai.chatListNameAI,
      loading: state => state.ai.loadingChatHome,
    }),
  },

  mounted(){
    this.actionGetChatListHome();
  },

  unmounted(){
    this.setChatListHome([])
    this.setChatListNameAI('GPT-3.5')
  }
}
</script>