<template>
  <select v-model="modelValue" @change="changeOption">
    <option :selected="options[0] ? true : false"
      v-for="option in options"
      :key="option.id"
      :value="option.name"
      >
      {{ option.name }}
      </option>
    </select>
</template>

<script>
export default {
  name: 'my-select-selected',
  props: {
    modelValue: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    changeOption(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>