<template>
  <div :class="`modal fade ${show ? 'show popup' : ''}`" id="editNamePopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" @click="close" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group mb-4">
            <my-input
            type="text"
            :model-value="prompt.name"
            @update:model-value="setEditName"
            placeholder="Короткое название промпта"
            maxlength="39"
            />
          </div>
          <div class="input-group mb-4">
            <my-textarea
            type="text"
            class="line-height"
            :model-value="prompt.text"
            @update:model-value="setEditText"
            placeholder="Промпт"
            />
          </div>
          
        </div>
        <div class="modal-footer">
          <button v-if="prompt.id" :disabled="!prompt.text" type="button" @click="actionEditPrompt" class="btn btn-danger">Изменить</button>
          <button v-else type="button" :disabled="!prompt.text" @click="actionAddPrompt" class="btn btn-danger">Создать</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="`${show ? 'modal-backdrop fade show' : ''}`"></div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'
export default {
  name: 'edit-name-popup',
  props: {
    prompt: Object,
    show: Boolean
  },
  methods: {
    ...mapMutations({setEditName: 'ai/setEditName', setShow: 'ai/setShow', setEditId: 'ai/setEditId', setEditText: 'ai/setEditText'}),
    ...mapActions({actionEditPrompt: 'ai/actionEditPrompt', actionAddPrompt: 'ai/actionAddPrompt'}),

    close(){
      this.setShow(false)
      this.setEditName(null)
      this.setEditText(null)
      this.setEditId(0)
    }
 
  }


}
</script>
<style>
  .popup{
    display: block;
  }
  .line-height{ line-height:18px}
</style>

