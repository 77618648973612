<template>
  <div v-if="payment && payment.payment_method === 'Безнал'" class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between d-npn">
              
            </div>  
            <div v-if="yurStatusName === true && yurStatusInn === true && yurStatusAddress === true && (statusKpp === true ? yurStatusKpp === true : true)">
              <button class="btn btn-light btn-sm mr-3" disabled="disabled"><i class="fa fa-floppy-o" aria-hidden="true"></i> </button>
              <button class="btn btn-info btn-sm mr-3" @click="payPDF"><i class="fa fa-download mr-2" aria-hidden="true"></i> Скачать</button>
              <button class="btn btn-info btn-sm" @click="payPrint"><i class="fa fa-print mr-2" aria-hidden="true"></i> Печать</button>
            </div>
            <div v-else>
              <button v-if="yur.name && yur.inn && (statusKpp === true ? yur.kpp : true)" class="btn btn-danger btn-sm mr-3" @click="saveYurStatus"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
              <button v-else class="btn btn-light btn-sm mr-3" disabled="disabled"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
              <button class="btn btn-light btn-sm mr-3" disabled="disabled"><i class="fa fa-download mr-2" aria-hidden="true"></i> Скачать</button>
              <button class="btn btn-light btn-sm" disabled="disabled"><i class="fa fa-print mr-2" aria-hidden="true"></i> Печать</button>
            </div>                    
          </div>
          <div class="card">
            <div id="pay_print" class="card-body">
              <div class="d-flex justify-content-between align-items-center p-3">
                <div class="">
                  <div width="100%" height="80" valign="middle"><span  style="font-weight:bold; font-size:22px; color:#ff2d2d;">CognitiveAI</span><br></div>
                </div>
                <div class="">
                  <div width="100%" valign="top">
                    <b>ИП: Бондаренко Роман Владимирович</b><br>
                    ИНН/ 243903342393<br>
                    +7 (995) 099-39-28<br>  <a href="https://cognitiveai.ru/" class="text-success">https://cognitiveai.ru/</a>
                  </div>
                </div>
              </div>
              <table width="100%" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr> 
                    <td height="75" colspan="2" valign="top"> 
                    <table width="100%" cellpadding="4" cellspacing="0" class="pay-center2">
                      <tbody><tr> 
                        <td valign="top" height="36" class="pay-border">Банк получателя:<br>
                          <b>ООО «Банк Точка»</b></td>
                        <td valign="top" class="pay-right pay-border">БИК<br>
                          Кор. Сч. №</td>
                    
                        <td valign="top" class="pay-border"><b>044525104<br>
                          </b> <b>30101810745374525104</b> </td>
                      </tr>
                      <tr> 
                        <td width="317" height="36" valign="top" class="pay-border">Получатель:<br>
                          <b>ИП Бондаренко Роман Владимирович</b> <br>ИНН <b>243903342393</b></td>
                        <td width="90" valign="bottom" class="pay-right pay-border">Сч. №</td>
                        <td valign="bottom" width="185" class="pay-border"><b>40802810220000331759</b></td>
                      </tr>
                    </tbody></table>
                  </td>
                </tr>
                <tr>
                  <td><br><div class="font-italic mb-2">Условия для расчетов:</div>
                  <p class="font-weight-bold fs20">
                    1. В назначении платежа, пожалуйста, указывайте номер счета.<br>
                    2. Счет действителен в течение пяти банковских дней.<br><br></p>
                  </td>
                </tr>
                <tr valign="middle" class="pay-center">
                  <td height="45" colspan="2" valign="middle"><h4>СЧЕТ № {{room}}-{{payment.id}} от 
                    <my-date :date="new Date(payment.createdAt)" /></h4></td>
                </tr>
                <tr>
                  <td height="48" colspan="2" valign="top">
                    <span><b>Получатель: </b>ИП Бондаренко Роман Владимирович, ИНН 243903342393, р/с 40802810220000331759, в банке ООО "Банк
                      Точка", БИК 044525104, к/с 30101810745374525104</span><br>
                    <p class="mt-2 mb-2">
                      <b class="mr5">Плательщик: </b>
                      <span v-if="yurStatusName === true" class="pay-txt" @click="editPayTxt('name')">{{yur.name}}</span>
                      <span v-else> 
                        <my-input 
                          placeholder="Название компании" 
                          :class="`h30 ${!yur.name ? 'is-invalid' : ''}`" 
                          :model-value="yur.name" @update:model-value="setRequisitesName"
                          @change="yurChange('name')"
                          type="text"
                          required
                        /></span><span class="mr5">,</span>
                      <span class="mr5">ИНН:</span>
                      <span v-if="yurStatusInn === true" class="pay-txt" @click="editPayTxt('inn')">{{`${yur.inn}`}}</span>
                      <span v-else>
                        <my-input 
                          :class="`h30 ${(!yur.inn || (yur.inn.length < 10)) || !/^(0|[1-9]\d*)$/.test(yur.inn) ? 'is-invalid ': ''}`"
                          placeholder="1111111111" 
                          :model-value="yur.inn" @update:model-value="setRequisitesInn"
                          @change="yurChange('inn')"
                          type="text"
                          required
                        /></span><span class="mr5">,</span>
                      <span v-if="statusKpp === true" class="mr5" @click="editPayTxt('name')">КПП:</span>
                      <span v-if="yurStatusKpp === true && statusKpp === true" class="pay-txt" @click="editPayTxt('kpp')">{{`${yur.kpp}`}}</span>
                      <span v-else-if="statusKpp === true">
                        <my-input 
                          :class="`h30 ${(!yur.kpp || yur.kpp.length !== 9) || !/^(0|[1-9]\d*)$/.test(yur.kpp) ? 'is-invalid' : ''}`" 
                          placeholder="111111111" 
                          :model-value="yur.kpp" @update:model-value="setRequisitesKpp"
                          @change="yurChange('kpp')"
                          type="text"
                        /></span><span v-if="statusKpp === true" class="mr5">,</span>
                      <span class="mr5">Юридический адрес:</span>
                      <span v-if="yurStatusAddress === true" class="pay-txt" @click="editPayTxt('address')">{{yur.address}}</span>
                      <span v-else>
                        <my-input 
                          :class="`h30 ${!yur.address ? 'is-invalid' : ''}`" 
                          placeholder="Адрес" 
                          :model-value="yur.address" @update:model-value="setRequisitesAddress"
                          @change="yurChange('address')"
                          type="text"
                          required
                        />
                      </span>

                    </p>
                  </td>
                </tr>
                <tr> 
                  <td height="100" colspan="2" valign="top"> 
                    <table width="100%" cellpadding="4" cellspacing="0" class="pay-center2">
                      <tbody><tr> 
                      <td width="25" height="38" valign="top" class="pay-border pay-center"><b>№</b></td>
                      <td width="317" valign="top" class="pay-border pay-center"><b>Название товара или услуги</b> </td>
                      <td width="50" valign="top" class="pay-border pay-center"><b>Ед. Изм.</b> </td>
                    
                      <td valign="top" width="150" class="pay-border pay-right"><b>Сумма</b></td>
                      </tr>
                      <tr>
                      <td valign="middle" class="pay-border pay-right">1</td>
                      <td valign="top" class="pay-border pay-left">{{name}}</td>
                      <td valign="top" class="pay-border pay-left">шт.</td>
                    
                      <td valign="middle" class="pay-border pay-right">{{Number(this.payment.payable)}}.00</td>
                      </tr>
                      <tr>
                            <td valign="top" colspan="3" class="pay-border pay-right"><b>Итого:</b></td>
                            <td height="22" valign="top" class="pay-border pay-right"><b>{{Number(payment.payable)}}.00</b></td>
                    </tr>
                    <tr>
                      <td valign="top" colspan="3" class="pay-border pay-right"><b>Без налога (НДС):</b></td>
                            <td height="22" valign="top" class="pay-border pay-right"><b>-</b></td>
                    </tr>
                    <tr>
                      <td valign="top" colspan="3" class="pay-border pay-right"><b>Всего к оплате:</b></td>
                            <td height="22" valign="top" class="pay-border pay-right"><b>{{Number(this.payment.payable)}}.00</b></td>
                        </tr>
                      </tbody></table>
                    </td>
                  </tr>

                <tr> 
                  <td height="15" colspan="3" valign="bottom" class="pay-left"><h6><br><br>К оплате: <my-sum-words :sum="Number(payment.payable)" /> 
                  </h6></td>
                </tr>

                <tr valign="bottom"> 
                  <td height="70" colspan="2" valign="bottom">
                <div style="position:absolute; left:220px;"><img src="/images/p.png" width="200" ></div>       <br><br>
                <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4"></div>
                ИП: &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Бондаренко Р. В.)<br><br><br><br><br><br></td>
                </tr>

 
                </tbody>
              </table>   
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import MyDate from '@/components/UI/MyDate.vue'
import * as html2pdf from 'html2pdf.js'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'invoice',
  components: {
    MyDate
  },
  data() {
    return {
      yurStatusName: false,
      yurStatusInn: false,
      yurStatusKpp: false,
      statusKpp: true,
      yurStatusAddress: false

    }
  },

  props: {
    name: String,
    room: String,
    payment: [Object, String]
  },

  methods: {
    ...mapMutations(['setRequisitesName', 'setRequisitesAddress', 'setRequisitesCity', 'setRequisitesRegion', 'setRequisitesPostcode', 'setRequisitesInn', 'setRequisitesKpp', 'actionRequisites']),
    ...mapActions(['actionOneYur']),

    payPDF(){
      const element = document.getElementById('pay_print');
      html2pdf(element, {filename: `invoice_SC_${this.room}-${this.payment.id}.pdf`});
    },

    payPrint(){
      window.print();
    },

    saveYurStatus(){
      this.yurStatusName = true
      this.yurStatusInn = true
      this.yurStatusAddress = true
      this.statusKpp === true ? this.yurStatusKpp = true : this.yurStatusKpp = false
      this.actionRequisites()
    },

    yurChange(name){
      this.actionOneYur()
      if(name === 'name'){
        const ooo = this.yur.name.toUpperCase().includes('ооо'.toUpperCase())
        const ob = this.yur.name.toUpperCase().includes('общество'.toUpperCase())
        ooo === true || ob === true ? this.statusKpp = true : this.statusKpp = false
        this.yur.name ? this.yurStatusName = true : this.yurStatusName = false
      } else if (name === 'inn'){
        this.yur.inn ? this.yurStatusInn = true : this.yurStatusInn = false
      } else if (name === 'kpp'){
        this.yur.kpp ? this.yurStatusKpp = true : this.yurStatusKpp = false
      } else if (name === 'region'){
        this.yur.region ? this.yurStatusRegion = true : this.yurStatusRegion = false
      } else if (name === 'address'){
        this.yur.address ? this.yurStatusAddress = true : this.yurStatusAddress = false
      }
    },

    editPayTxt(name){
      if(name === 'name'){
        this.yurStatusName = false
      } else if (name === 'inn'){
        this.yurStatusInn = false
      } else if (name === 'kpp'){
        this.yurStatusKpp = false
      } else if (name === 'address'){
        this.yurStatusAddress = false
      }
    },

  },

  computed: {
    ...mapState({
      yur: state => state.user.requisites_yur.requisites,
      moscow: state => state.user.requisites_yur.requisites.city.toUpperCase().includes('москва'.toUpperCase()),
      spb: state => state.user.requisites_yur.requisites.city.toUpperCase().includes('санкт'.toUpperCase()),
      user: state => state.user
    }),
  },

  mounted(){
    if(!this.payment || this.payment.payment_method !== 'Безнал'){
      this.$router.push('/404')
      return
    }
    const ooo = this.yur.name.toUpperCase().includes('ооо'.toUpperCase())
    const ob = this.yur.name.toUpperCase().includes('общество'.toUpperCase())
    ooo === true || ob === true ? this.statusKpp = true : this.statusKpp = false
    this.yur.name ? this.yurStatusName = true : false
    this.yur.inn ? this.yurStatusInn = true : false
    this.yur.kpp ? this.yurStatusKpp = true : false
    this.yur.address ? this.yurStatusAddress = true : false
  }

  
}
</script>
<style>
  .pay-right{
    text-align: right;
  }

  .pay-center{
    text-align: center;
  }
  .pay-left{
    text-align: left;
  }
  .pay-border{
    border: 1px solid black; 
    border-width: 0px 0px 1px 1px;
  }
  .pay-center2{
    border: 1px solid black; 
    border-width: 1px 1px 0px 0px;
  }
  .pl-pl{
    padding-left: 14px;
  }
  .h30{
    height: 30px;
    margin-bottom: 8px;
  }
  .mr5{
    margin-right:5px;
  }
  .yar-kpp{
    color: red;
    margin: 5px;
    cursor: pointer;
  }
  .pay-txt{
    cursor: pointer;
  }
  .pay-txt:hover{
    color: red;
  }
  .fs20{
    font-size:18px;
  }

  @media print {
    body {
      visibility: hidden;
    }
    #pay_print{
      visibility: visible;
    }
    .iq-top-navbar, .d-npn{
      display: none;
    }
  }

</style>