<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Мои промпты</h4>
                </div> 
                <div class="create-workform">
                  <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="modal-product-search d-flex">
                      <button @click="addPrompt" class="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg> Создать промпт
                      </button>
                    </div>
                  </div>
                </div>  
              </div>
              
              <div class="iq-accordion career-style faq-style  ">
                <prompt-list :textList="promptList" :loading="loading"/>
              </div> 
            </div>
          </div>
          <edit-name-popup :prompt="prompt" :show="show"/>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import PromptList from '@/components/PromptList'
import EditNamePopup from '@/components/EditNamePopup'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    PromptList,
    EditNamePopup
  },
  methods: {
    ...mapMutations({setTextList: 'ai/setTextList', setPage: 'ai/setPage', setLength: 'ai/setLength', setShow: 'ai/setShow'}),
    ...mapActions({actionAiTextList: 'ai/actionAiTextList'}),

    editPage(e){
      this.setPage(e)
      this.actionAiTextList()
    },

    addPrompt(){
      this.setShow(true)
    }
  },

  computed: {

    ...mapState({
      promptList: state => state.user.prompt,
      loading: state => state.ai.loading, 
      show: state => state.ai.show,
      prompt: state => state.ai.edit
    }),
  },

  mounted(){
    this.actionAiTextList();
    document.title = 'Промпты - CognitiveAI"'
  },

  unmounted(){
    this.setTextList([])
    this.setPage(1)
    this.setLength(0)
  }
}
</script>