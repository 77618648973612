import { axiosGetWidget, axiosCreateWidget, axiosEditStrWidget, axiosEditStatusWidget, axiosEditAiDataWidget, axiosRemoveWidget, axiosStyleWidget } from '@/api/index'

export const chatWidgetModule = {
  state: () => ({
    loading: false,
    error: '',
    message: '',
    widgetList: [],
    domain: '',
    modal: false,
    activeIndex: null,
    style: null,
    temporary: {
      id: 0,
      data: {}
    },
    page: 1,
    length: 0,
    limit: 10,
    
  }),

  getters: {
   
  },

  mutations: {
    setLoading(state, v){state.loading = v},
    setError(state, v){state.error = v},
    setMessage(state, v){state.message = v},
    setWidgetList(state, v){state.widgetList = v},
    setDomain(state, v){state.domain = v},
    setModal(state, v){state.modal = v},
    setActiveIndex(state, v){state.activeIndex = v},
    setStyle(state, v){state.style = v},
    setTemporaryId(state, v){state.temporary.id = v},
    setTemporaryData(state, v){state.temporary.data = v},
    setPage(state, v){state.page = v},
    setLength(state, v){state.length = v},
    setMaxAtm(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, max_amt: data.name} : widget)},
    setSecretWord(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, secret_word: data.name} : widget)},
  },

  actions: {
    // Создаем виджет
    async actionCreateWidget({state, commit, rootState, dispatch}){
      try {
        const response = await axiosCreateWidget(rootState.token, {domain: state.domain})
        commit('setMessage', response.message)
        commit('setDomain', '')
        await dispatch('actionGetWidget');
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateWidget')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Получаем виджеты
    async actionGetWidget({commit, state, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosGetWidget(rootState.token, state.page, state.limit)
        commit('setWidgetList', response.widget)
        commit('setLength', response.count)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetWidget')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Получаем виджеты приватно
    async actionGetWidgetPrivate({commit, state, rootState}){
      try {
        const response = await axiosGetWidget(rootState.token, state.page, state.limit)
        commit('setWidgetList', response.widget)
        commit('setLength', response.count)
      } catch (e) {
        console.log(e.response.data)
      }
    },

    // Редактируем лимит цены и проверочное слово
    async actionEditStrWidget({commit, state, rootState, dispatch}, id){
      commit('setTemporaryId', id)
      try {
        const data = {id: state.temporary.id, maxAmt: state.widgetList.find(widget => widget.id === state.temporary.id)?.max_amt, secretWord: state.widgetList.find(widget => widget.id === state.temporary.id)?.secret_word}
        const response = await axiosEditStrWidget(rootState.token, data)
        commit('setTemporaryId', 0)
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditStrWidget', state.temporary.id)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Публикуем или нет
    async actionEditStatusWidget({commit, state, rootState, dispatch}, id){
      commit('setTemporaryId', id)
      try {
        const response = await axiosEditStatusWidget(rootState.token, state.temporary.id)
        commit('setTemporaryId', 0)
        dispatch('actionGetWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditStatusWidget', state.temporary.id)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Добавляем или удаляем нейронные сети
    async actionEditAiDataWidget({commit, state, rootState, dispatch}, data){
      commit('setTemporaryData', data)
      try {
        const response = await axiosEditAiDataWidget(rootState.token, state.temporary.data)
        commit('setTemporaryData', {})
        dispatch('actionGetWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditAiDataWidget', state.temporary.data)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Удаляем виджет
    async actionRemoveWidget({commit, state, rootState, dispatch}){
      try {
        const response = await axiosRemoveWidget(rootState.token, state.temporary.id)
        commit('setTemporaryId', 0)
        commit('setModal', false)
        dispatch('actionGetWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionRemoveWidget')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Добавляем свои стили
    async actionStyleWidget({commit, state, rootState, dispatch}, id){
      commit('setTemporaryId', id)
      try {
        const response = await axiosStyleWidget(rootState.token, {id: state.temporary.id, data: state.style})
        commit('setTemporaryId', 0)
        dispatch('actionGetWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionStyleWidget', state.temporary.id)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },



  },
  namespaced: true
}