<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="widget.message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ widget.message }} </p>
      </div>
      <div v-if="widget.error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ widget.error }}</p>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Виджеты техподдержки на базе ИИ</h4>
            </div>
            <div class="create-workform">
              <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div class="modal-product-search d-flex">
                  <div class="btn-group btn-group-toggle btn-group-sm btn-group-edges mr-5 model-ai-preb"> 
                    <my-input
                    :model-value="widget.domain" @update:model-value="setDomain"
                    type="text"
                    required
                    placeholder="Введите ваш домен, например: my-domain.ru "
                    />
                  </div>
                  <button @click="actionCreateSupportWidget" class="btn btn-primary position-relative d-flex align-items-center justify-content-between mch">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>Создать виджет
                  </button>
                </div>
              </div>
            </div> 
          </div>
          
          <div class="pb-5" v-if="widget.loading"><loading-small /></div>
          <div v-else v-for="w in widget.widgetList" :key="w.id" class="card card-block card-stretch">
            <div class="card-header justify-content-between widget-block">
              <div class="header-title widget-head-block">
                <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule">
                  <h4 @click="toggleContent(w.id)" class="card-title text-primary">{{ w.domain }}
                    <span v-if="widget.activeIndex === w.id">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" /></svg>
                    </span>
                    <span v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg>
                    </span>
                  </h4>
                </div>
                <div class="widget-block-right">
                  <button @click="openModal(w.id)" class="badge bg-danger" title="Удалить" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <transition name="slide-fade">
              <div class="card-body p-0 widget-content" v-show="widget.activeIndex === w.id">
                <div class="table-responsive">
                  <table class="table table-spacing mb-0">
                    <tbody>
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Текстовые настройки</h6>
                        </td>
                        <td class="pl-0 py-3">
                          <div class="row">
                            <div class="form-group col-12 col-sm-6">
                              <label>Название в триггере</label>
                              <my-input-array
                                type="text"
                                :model-value="w.name_trigger"
                                :id="w.id"
                                @update:model-value="setNameTrigger"
                              />
                            </div>
                            <div class="form-group col-12 col-sm-6">
                              <label>Название в открытом окне чата</label>
                              <my-input-array
                                type="text"
                                :id="w.id"
                                :model-value="w.name_chat"
                                @update:model-value="setNameChat"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-12 col-sm-6">
                              <label>Начало диалога</label>
                              <my-textarea-array
                                :id="w.id"
                                :model-value="w.greetings"
                                @update:model-value="setGreetings"
                              />
                            </div>
                            <div class="form-group col-12 col-sm-3">
                              <label>Цвет фона виджета</label>
                              <my-input-array
                                :id="w.id"
                                :model-value="w.color_fon"
                                @update:model-value="setColorFon"
                              />
                            </div>
                            <div class="form-group col-12 col-sm-3">
                              <label>Цвет текста на фоне</label>
                              <my-input-array
                                :id="w.id"
                                :model-value="w.color_fon_icon"
                                @update:model-value="setColorIcon"
                              />
                            </div>
                            <!--<div class="form-group col-12 col-sm-6">
                              <label>Благодарность за контакты</label>
                              <my-textarea-array
                                :id="w.id"
                                :model-value="w.thanks_contact"
                                @update:model-value="setThanksContact"
                              />
                            </div>-->
                          </div>
                          <div class="row">
                            <div class="form-group col-12 col-sm-6">
                              <div class="row">
                                <label>Ваши контакты (Email, Телефон) </label>
                                <div class="ml-2">
                                <my-informer color="orange" type="warning" text="Рекомендуем вствить ваши контактные данные, чтобы пользователи могли связаться с вами в случае, если нейронная сеть не окажет необходимой помощи." />
                                </div>
                              </div>
                              <div v-for="(contact, index) in w.contacts" :key="`${index}-${contact}`">{{ contact }} <span @click="removeContact({id: w.id, index})" class="w-remove-contact">×</span></div>
                              <div class="row mt-2 mb-3">
                                <div class="col-12 col-sm-10">
                                  <my-input
                                    type="text"
                                    :class="`${widget.contactInputFlag ? 'in-valid' : ''}`"
                                    :model-value="widget.contactInput"
                                    @update:model-value="setContactInput"
                                  /> 
                                </div>
                                <div class="col-12 col-sm-2 block-contact-input">
                                  <div :class="`add-contact-input ${widget.contactInput? 'aci-active' : ''}`" @click="addContact(w.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-12 col-sm-4">
                              <button @click="actionEditStrSupportWidget(w.id)" type="button" class="btn btn-outline-success btn-block text-center mt-4">Сохранить</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Настройки нейросети</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div class="row">
                            <label>Доступные нейронные сети <my-required-field /> </label>
                            <div class="ml-3">
                              <my-informer color="" type="" text="Выберите нейронную сеть, которая будет использоваться в виджете технической поддержки. Для получения качественных и обоснованных ответов рекомендуем использовать нейронные сети из серии GPT-4, или yandexgpt" />
                            </div>
                          </div>
                          <div class="mb-1" v-for="ai in aiList" :key="ai.name">
                            <div class="ml-3">
                              <div class="mt-2 custom-control custom-radio custom-control-inline" v-for="model in ai.data" :key="model.model">
                                <input @click="closedAi({ai: {id: w.id, nameAi: ai.name, modelAi: model.model}, limit: model.maxTokens})" type="radio" :name="`ai${w.id}`" class="custom-control-input" :id="`supplierCheck-${model.model}-${w.id}`" :checked="model.model === w.model_ai">
                                <label class="custom-control-label" :for="`supplierCheck-${model.model}-${w.id}`">{{ model.model }} <span class="small text-warning">({{ ai.name }})</span></label>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-sm-12 col-lg-6">
                              <div class="form-group">
                                <div class="row">
                                  <label>Температура </label>
                                  <div class="ml-3">
                                    <my-informer color="" type="" text="Температура определяет точность и корректность ответов нейронной сети. Этот параметр установлен на оптимальное значение, однако вы можете его скорректировать в соответствии с вашими потребностями." />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12 col-lg-12">
                                    <my-range-array 
                                      :model-value="w.temp" @update:model-value="setTemp"
                                      :min="1" 
                                      :max="10" 
                                      :step="1"
                                      :id="w.id"
                                    />
                                  </div>
                                  <div class="col-sm-12 col-lg-12">
                                    {{ w.temp }}/10
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-6">
                              <div class="form-group mb-4">
                                <div class="row">
                                  <label>Максимальное количество токенов </label> 
                                  <div class="ml-3">
                                    <my-informer color="" type="" text="Максимальное количество токенов для нейронной сети определяет объем текста, который искусственный интеллект может произвести за один запрос. Минимальное значение этого параметра приведет к более лаконичным ответам." />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12 col-lg-12">
                                    <my-range-array 
                                      :model-value="w.max_tokens" @update:model-value="setMaxTokens"
                                      :min="50" 
                                      :max="w.name_ai && w.model_ai ? setAi(w.name_ai, w.model_ai) : widget.limitTokens" 
                                      :step="1"
                                      :id="w.id"
                                    />
                                  </div>
                                  
                                  <div class="col-sm-12 col-lg-12">
                                    {{ w.max_tokens }}/{{ w.name_ai && w.model_ai ? setAi(w.name_ai, w.model_ai) : widget.limitTokens }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-12 col-sm-4">
                              <button @click="actionChooseAiSupportWidget(w.id)" type="button" class="btn btn-outline-success btn-block text-center mt-4">Сохранить</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Статус</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div  class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline">
                            <div class="custom-switch-inner">
                              <input type="checkbox" @click="actionBoolSupportWidget({id: w.id, value: 'status'})" class="custom-control-input bg-success" :id="`customSwitch-${w.id}`" :checked="w.status">
                              <label class="custom-control-label" :for="`customSwitch-${w.id}`">
                                <span class="switch-icon-left"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M20 2L6.95024 16.822C6.40984 17.1746 5.69527 16.9862 5.38235 16.4084L2 10.1635" stroke="white" stroke-linecap="round"></path></g></svg></span>
                                <span class="switch-icon-right"><svg width="13" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M4 20L20 4M20 20L4 4" stroke="black" stroke-linecap="round"></path></g></svg></span>
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <!--<tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Триггер</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div  class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline">
                            <div class="custom-switch-inner">
                              <input type="checkbox" @click="actionBoolSupportWidget({id: w.id, value: 'trigger'})" class="custom-control-input bg-success" :id="`trigger-${w.id}`" :checked="w.trigger_view">
                              <label class="custom-control-label" :for="`trigger-${w.id}`"></label>
                            </div>
                            <div class="ml-3">
                              <my-informer color="" type="" text="Внешний вид виджета триггера" />
                            </div>
                          </div>
                        </td>
                      </tr>-->
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Конфигурация для API</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div  class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline mb-2">
                            <div class="custom-switch-inner">
                              <input type="checkbox" @click="actionBoolSupportWidget({id: w.id, value: 'prompt_from_site'})" class="custom-control-input bg-success" :id="`promptFromSite-${w.id}`" :checked="w.check_prompt_from_site">
                              <label class="custom-control-label" :for="`promptFromSite-${w.id}`">
                                <span class="switch-icon-left"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M20 2L6.95024 16.822C6.40984 17.1746 5.69527 16.9862 5.38235 16.4084L2 10.1635" stroke="white" stroke-linecap="round"></path></g></svg></span>
                                <span class="switch-icon-right"><svg width="13" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M4 20L20 4M20 20L4 4" stroke="black" stroke-linecap="round"></path></g></svg></span>
                              </label>
                            </div>
                            <div class="ml-3">
                              <my-informer color="" type="" text="Передача баз знаний осуществляется посредством API-запросов, при условии, что такая возможность предусмотрена разработчиками виджета." />
                            </div>
                            <div class="ml-2">
                              <my-informer color="red" type="warning" text="
                                Мы настоятельно рекомендуем воздержаться от самостоятельного изменения данной настройки, за исключением случаев, когда в рекомендациях от разработчиков, применяющих нашу технологию технической поддержки на базе нейросетей, прямо указано обратное." />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="w.check_prompt_from_site">
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">ID API Виджета</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <p v-if="!w.model_ai" class="small text-secondary mt-2">Чтобы отобразить ID API виджета, выберите нейронную сеть в настройках выше.</p>
                          <div v-else>{{ w.id_api_widget }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Учитывать контекст</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div  class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline mb-2">
                            <div class="custom-switch-inner">
                              <input type="checkbox" @click="actionBoolSupportWidget({id: w.id, value: 'context'})" class="custom-control-input bg-success" :id="`ctxAccount-${w.id}`" :checked="w.ctx_account">
                              <label class="custom-control-label" :for="`ctxAccount-${w.id}`">
                                <span class="switch-icon-left"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M20 2L6.95024 16.822C6.40984 17.1746 5.69527 16.9862 5.38235 16.4084L2 10.1635" stroke="white" stroke-linecap="round"></path></g></svg></span>
                                <span class="switch-icon-right"><svg width="13" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M4 20L20 4M20 20L4 4" stroke="black" stroke-linecap="round"></path></g></svg></span>
                              </label>
                            </div>
                            <div class="ml-3"><my-informer color="" type="" text="Функция учета контекста позволяет нейросети учитывать весь контекст в ходе диалога с пользвотелем. Это означает, что при включении данной функции нейронная сеть будет анализировать все предыдущие вопросы и ответы, возникшие <b>в рамках текущего диалога</b>, и принимать эту информацию во внимание при формулировке новых ответов. В случае отключения этой функции каждый вопрос пользователя будет обрабатываться как новый, без учета предыдущего контекста. Важно осознавать, что активация учета контекста может увеличить стоимость из-за необходимости обработки всей истории в рамках одного диалога в промпте." /></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Выбор раздела с базой знаний</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div  class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline mb-2 flex-tariff">
                            <div class="custom-switch-inner">
                              <input v-if="!tariff || !tariff.status || tariff.type !== 'Business'" type="checkbox" class="custom-control-input bg-success" disabled :id="`checkAutoSection-${w.id}`">
                              <input v-else type="checkbox" @click="actionBoolSupportWidget({id: w.id, value: 'section'})" class="custom-control-input bg-success" :id="`checkAutoSection-${w.id}`" :checked="w.check_auto_section">
                              <label class="custom-control-label" :for="`checkAutoSection-${w.id}`">
                                <span class="switch-icon-left"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M20 2L6.95024 16.822C6.40984 17.1746 5.69527 16.9862 5.38235 16.4084L2 10.1635" stroke="white" stroke-linecap="round"></path></g></svg></span>
                                <span class="switch-icon-right"><svg width="13" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M4 20L20 4M20 20L4 4" stroke="black" stroke-linecap="round"></path></g></svg></span>
                              </label>
                            </div>
                            <div class="ml-3">
                              <my-informer color="" type="" text="Выбор раздела с базой знаний может быть настроен на автоматическое определение или ручной выбор пользователями в виджете.<br><br>
                               Автоматическое распознавание релевантного раздела базы знаний выполняется с использованием нейронной сети, которая анализирует текст вопроса, заданного пользователем. Этот процесс влечёт за собой дополнительные расходы на использование нейросети. Применяется модель gpt-4.<br><br>

                                При ручном выборе пользователь в интерфейсе службы поддержки самостоятельно определяет необходимый раздел, нажимая на кнопку с названием интересующей базы знаний перед тем, как задать свой вопрос.  
                                " />
                            </div>
                            <div class="ml-2">
                              <my-informer color="orange" type="warning" text="Если вы пользуетесь единственной базой знаний, активация функции автоматического выбора базы знаний не требуется." />
                            </div>
                            
                            <div v-if="!tariff || !tariff.status || tariff.type !== 'Business'" class="bg-warning-light pl-3 pr-3 pt-2 pb-2 rounded pskdi ml-5">Данная опция доступна при активации подписки на тарифный план «Business».</div>
                          </div>
                          <!--<div v-if="w.check_auto_section">
                            <my-informer color="" type="" text="Вы можете указать в настройках сервиса ссылки на определённые страницы вашего сайта. Это позволит системе выбирать соответствующую базу знаний в зависимости от страницы, с которой пользователь отправляет вопрос. Если вы предпочитаете, чтобы нейронная сеть сама определяла подходящую базу знаний, достаточно оставить эти поля пустыми." />
                            <div v-for="(url, index) in w.auto_section_data" :key="`${index}-${url}`">{{ url.url }} : {{ url.catalog }} <span @click="removeSectionData({id: w.id, index})" class="w-remove-contact">×</span></div>
                            <div class="row mt-2 mb-3">
                              <div class="col-12 col-sm-5">
                                <div class="input-group">
                                  <my-input
                                    type="text"
                                    :class="`${widget.sectionDataInputUrlFlag ? 'in-valid' : ''}`"
                                    :model-value="widget.sectionDataInputUrl"
                                    @update:model-value="setSectionDataInputUrl"
                                    placeholder="Url страница вашего сайте"
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"><my-informer color="green" type="question" text="Пожалуйста, введите URL (адрес веб-страницы) вашего сайта. Например: mysite.com/catalog/red-shirt<br><br>Если вы хотите, чтобы настройка применялась ко всему разделу сайта, добавьте в конец URL звездочку (*). Например: mysite.com/catalog/*<br><br>С этой настройкой правило будет распространяться на все страницы, URL которых начинается с mysite.com/catalog/, независимо от дополнительных сегментов в адресе после указанного пути." /></span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-5">
                                <my-input
                                    type="text"
                                    :class="`${widget.sectionDataInputCatalogFlag ? 'in-valid' : ''}`"
                                    :model-value="widget.sectionDataInputCatalog"
                                    @update:model-value="setSectionDataInputCatalog"
                                  />
                              </div>
                              <div class="col-12 col-sm-2 block-contact-input">
                                <div :class="`add-contact-input ${widget.sectionDataInputUrl && widget.sectionDataInputCatalog ? 'aci-active' : ''}`" @click="addSectionData(w.id)">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-12 col-sm-4">
                                <button @click="actionEditSectionDataSupportWidget(w.id)" type="button" class="btn btn-outline-success btn-block text-center mt-4">Сохранить</button>
                              </div>
                            </div>
                          </div>-->
                        </td>
                      </tr>
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Копирайт</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div  class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline mb-2 flex-tariff">
                            <div class="custom-switch-inner">
                              <input v-if="!tariff || !tariff.status || tariff.type !== 'Business'" type="checkbox" class="custom-control-input bg-success" disabled :id="`copyright-${w.id}`" :checked="w.copyright">
                              <input v-else type="checkbox" @click="actionBoolSupportWidget({id: w.id, value: 'copyright'})" class="custom-control-input bg-success" :id="`copyright-${w.id}`" :checked="w.copyright">
                              <label class="custom-control-label" :for="`copyright-${w.id}`">
                                <span class="switch-icon-left"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M20 2L6.95024 16.822C6.40984 17.1746 5.69527 16.9862 5.38235 16.4084L2 10.1635" stroke="white" stroke-linecap="round"></path></g></svg></span>
                                <span class="switch-icon-right"><svg width="13" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M4 20L20 4M20 20L4 4" stroke="black" stroke-linecap="round"></path></g></svg></span>
                              </label>
                            </div>
                            <div class="ml-3">
                              <my-informer color="" type="" text="Включить/Отключить в виджете ссылку на платформу CognitiveAI" />
                            </div>
                            <div v-if="!tariff || !tariff.status || tariff.type !== 'Business'" class="bg-warning-light pl-3 pr-3 pt-2 pb-2 rounded pskdi ml-5">Данная опция доступна при активации подписки на тарифный план «Business».</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <h6 class="mb-0 text-uppercase text-secondary">ID виджета</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <p v-if="!w.model_ai" class="small text-secondary mt-2">Чтобы отобразить ID виджета, выберите нейронную сеть в указанных выше настройках.</p>
                          <div v-else>{{  w.code }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <h6 class="mb-0 text-uppercase text-secondary">Код виджета</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <p class="small text-secondary mt-2">{{ w.model_ai ? 'Вставьте код виджета технической поддержки в шаблон вашего сайта, поместив его между тегами `&lt;body&gt;`' : 'Для отображения кода виджета выберите нейронную сеть в настройках выше.' }}</p>
                          <kbd v-if="w.model_ai" class="bg-dark"><pre id="form-grid" class="text-white">
                            <code>
&lt;div class="cognitiveai-support-widget-support__trigger" id="cognitiveaiSupportWidgetOpenChat" style="background-color: {{ w.color_fon }}"&gt;&lt;span class="cognitiveai-support-widget-support__title" style="color:{{ w.color_fon_icon }}"&gt; {{ w.name_trigger }} &lt;/span&gt; {{ w.copyright ? '&lt;a href="https://cognitiveai.ru/" target="_blank" class="cognitiveai-support-widget-support__logo">&lt;img src="https://widget.cognitiveai.ru/support/cognitiveai.png"&gt;&lt;/a&gt;' : '' }} &lt;/div&gt;
&lt;script  id="cognitiveaiSupportWidgetId" src="https://widget.cognitiveai.ru/support/widgets.js?widgetId={{ w.code }}" async&gt;&lt;/script&gt;
</code></pre></kbd>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <support-widget :trigger="w.name_trigger" :nameChat="w.name_chat" :color="w.color_fon" :colorIcon="w.color_fon_icon" :copyright="w.copyright" :contacts="w.contacts" />
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="!widget.loading && widget.length > widget.limit" class="col-lg-12">
          <pagination :pages="Math.ceil(widget.length / widget.limit)" :model-value="widget.page" @update:model-value="editPage"/>
        </div>
      </div>
    </div>
  </div>
  <div :class="`modal fade ${widget.popup ? 'show' : ''}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" :style="`padding-right: 4px; ${widget.popup ? 'display: block' : 'display: none'}`">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5>Вы уверены, что хотите удалить виджет?</h5>
          <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <p>Все базы знаний и диалоги, связанные с данным виджетом, будут удалены автоматически.</p>
        </div>
        <div class="modal-footer">
          <button @click="actionRemoveSupportWidget" type="button" class="btn btn-danger">Да</button>
          <button @click="closeModal" type="button" class="btn btn-secondary">Отмена</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="`${widget.popup ? 'modal-backdrop-pers' : ''}`"></div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import SupportWidget from '@/components/SupportWidget'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    Loading,
    SupportWidget
  },
  methods: {
    ...mapMutations({setWidgetList: 'supportWidget/setWidgetList', setPage: 'supportWidget/setPage', setLength: 'supportWidget/setLength', setDomain: 'supportWidget/setDomain', setTemporaryId: 'supportWidget/setTemporaryId', setPopup: 'supportWidget/setPopup', setActiveIndex: 'supportWidget/setActiveIndex', setNameTrigger: 'supportWidget/setNameTrigger', setNameChat: 'supportWidget/setNameChat', setGreetings: 'supportWidget/setGreetings', setThanksContact: 'supportWidget/setThanksContact', setContactInput: 'supportWidget/setContactInput', setColorFon: 'supportWidget/setColorFon', setAddContacts: 'supportWidget/setAddContacts', setContactInputFlag: 'supportWidget/setContactInputFlag', setRemoveContacts: 'supportWidget/setRemoveContacts', setNameModalAi: 'supportWidget/setNameModalAi', setLimitTokens: 'supportWidget/setLimitTokens', setTemp: 'supportWidget/setTemp', setMaxTokens: 'supportWidget/setMaxTokens', setSectionDataInputUrl: 'supportWidget/setSectionDataInputUrl', setSectionDataInputUrlFlag: 'supportWidget/setSectionDataInputUrlFlag', setSectionDataInputCatalog: 'supportWidget/setSectionDataInputCatalog', setSectionDataInputCatalogFlag: 'supportWidget/setSectionDataInputCatalogFlag', setAddSectionData: 'supportWidget/setAddSectionData', setRemoveSectionData: 'supportWidget/setRemoveSectionData', setColorIcon: 'supportWidget/setColorIcon'}),

    ...mapActions({actionCreateSupportWidget: 'supportWidget/actionCreateSupportWidget', actionGetSupportWidget: 'supportWidget/actionGetSupportWidget', actionEditStatusSupportWidget: 'supportWidget/actionEditStatusSupportWidget', actionRemoveSupportWidget: 'supportWidget/actionRemoveSupportWidget', actionEditStrSupportWidget: 'supportWidget/actionEditStrSupportWidget', actionChooseAiSupportWidget: 'supportWidget/actionChooseAiSupportWidget', actionBoolSupportWidget: 'supportWidget/actionBoolSupportWidget', actionEditSectionDataSupportWidget: 'supportWidget/actionEditSectionDataSupportWidget'}),

    closedAi(data){
      this.setNameModalAi(data.ai)
      this.setLimitTokens(data.limit)
    },

    addSectionData(id){
      if(!this.widget.sectionDataInputUrl){
        this.setSectionDataInputUrlFlag(true)
      } else if(!this.widget.sectionDataInputCatalog){
        this.setSectionDataInputCatalogFlag(true)
      } else {
        this.setAddSectionData(id)
      }
    },

    removeSectionData(data){
      this.setRemoveSectionData(data)
    },
    
    addContact(id){
      if(!this.widget.contactInput){
        this.setContactInputFlag(true)
      } else {
        this.setAddContacts(id)
      }
    },

    removeContact(data){
      this.setRemoveContacts(data)
    },

    openModal(param){
      this.setPopup(true)
      this.setTemporaryId(param) 
    },
    closeModal(){
      this.setPopup(false)
      this.setTemporaryId(0)
    },
    editPage(e){
      this.setPage(e)
      this.actionGetSupportWidget()
    },
    toggleContent(index) {
      this.setActiveIndex(this.widget.activeIndex === index ? null : index)
    },
    setAi(nameAi, modelAi){
      return this.aiList.find(net => net.name === nameAi).data.find(m => m.model === modelAi).maxTokens
    },
  },

  computed: {
    ...mapState({
      widget: state => state.supportWidget,
      aiList: state => state.ai.modelAiList,
      tariff: state => state.user.tariff
    }),
  },

  mounted(){
    this.actionGetSupportWidget()
    this.setPage(1)
    document.title = "Создание и настройка виджетов техподдержки для сайта - CognitiveAI"
  },

  unmounted(){
    /*this.setWidgetList([])*/
  }
}
</script>

<style>
.info-small{
  color: red !important;
  font-size: 12px;
}
.add-contact-input{
  cursor: pointer;
  color: #f1f1f1;
  width: 30px;
}

.aci-active{
  color: #3378ff;
}

.block-contact-input{
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
}
.in-valid{
  border-color: #f42b3d;
}
.w-remove-contact{
  color: red !important;
  cursor: pointer;
  font-size: 150%;
  margin-left: 10px;
}
.flex-tariff{
  display: flex !important;
  align-items: center !important;
}

</style>