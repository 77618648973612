<template>
  <form @submit.prevent>
    <div class="row">
      <div v-if="errors.length" class="col-lg-12">
        <div class="form-group">
          <div>
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Название организации <my-required-field /></label>
          <my-input
            :model-value="requisites.name" @update:model-value="setRequisitesName"
            type="text"
            required
          />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>ИНН <my-required-field /></label>
          <my-input 
            :model-value="requisites.inn" @update:model-value="setRequisitesInn"
            type="text"
            required
          /> 
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>КПП </label>
          <my-input 
            :model-value="requisites.kpp" @update:model-value="setRequisitesKpp"
            type="text"
          /> 
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>Адрес <my-required-field /></label>
          <my-input 
            :model-value="requisites.address" @update:model-value="setRequisitesAddress"
            type="text"
            required
          /> 
        </div>
      </div>
      <my-button type="submit" class="btn btn-primary mr-2 pl-5 pr-5 mt-3 mb-5" @click="actionRequisites">Сохранить</my-button>
    </div>
  </form>
  <form @submit.prevent>
    <div class="row">
      <div v-if="errorsBank.length" class="col-lg-12">
        <div class="form-group">
          <div>
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errorsBank">{{error}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <h5>Банковские реквизиты: </h5>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Расчетный счет <my-required-field /></label>
          <my-input 
            :model-value="requisites.rs" @update:model-value="setRequisitesRs"
            type="text"
            required
          /> 
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Банк <my-required-field /></label>
          <my-input 
            :model-value="requisites.bank" @update:model-value="setRequisitesBank"
            type="text"
            required
          /> 
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>БИК <my-required-field /></label>
          <my-input 
            :model-value="requisites.bic" @update:model-value="setRequisitesBic"
            type="text"
            required
          /> 
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Корр. счет <my-required-field /></label>
          <my-input 
            :model-value="requisites.ks" @update:model-value="setRequisitesKs"
            type="text"
            required
          /> 
        </div>
      </div>
      <my-button type="submit" class="btn btn-primary mr-2 pl-5 pr-5 mt-3 mb-5" @click="actionBankRequisites">Сохранить</my-button>
    </div>
  </form>
  
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'requisites-yur-form',
  methods: {
    ...mapMutations(['setRequisitesName', 'setRequisitesAddress', 'setRequisitesCity', 'setRequisitesRegion', 'setRequisitesPostcode', 'setRequisitesInn', 'setRequisitesKpp', 'setRequisitesRs', 'setRequisitesBank', 'setRequisitesBic', 'setRequisitesKs']),

    ...mapActions(['actionRequisites', 'actionBankRequisites']),  
  },
  computed: {
    ...mapState({
      requisites: state => state.user.requisites_yur.requisites,
      errors: state => state.errorsRequisites,
      errorsBank: state => state.errorsBankRequisites
    }),
  }
}
</script>