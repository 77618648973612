<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">История расчетов</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">История расчетов</h4>
            </div>                    
          </div>
          <div class="card card-block card-stretch">
            <div class="card-body p-0"> 
              <div class="row">
                <div class="col-sm-12">
                  <table class="table data-table mb-0 no-footer">
                    <thead class="table-color-heading">
                      <tr class="text-light">
                        <th class="dates sorting">Дата</th>
                        <th class="dates sorting text-right">Сумма</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingSmall"><td colspan="2"><loading-small /></td></tr>
                      <tr v-else v-for="bl in sortPagination.balanceHistory" :key="bl.id" class="white-space-no-wrap">
                        <td><my-date :date="new Date(bl.createdAt)" /></td>
                        <td>
                          <div v-if="bl.arrival_departure === true" class="text-success text-right">{{Number(bl.sum).toLocaleString()}} <i class="fa fa-rub"></i></div>
                          <div v-else class="text-danger text-right">— {{Number(bl.sum).toLocaleString()}} <i class="fa fa-rub"></i></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="!loadingSmall && sortPagination.count > balanceHistory.limit" class="col-lg-12">
                  <pagination :pages="Math.ceil(sortPagination.count / balanceHistory.limit)" :model-value="balanceHistory.page" @update:model-value="setPage"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
export default {
  components: {Header},
  methods: {
    ...mapActions({actionGetBalanceHistory: 'balanceHistory/actionGetBalanceHistory'}),
    ...mapMutations({setPage: 'balanceHistory/setPage', setBalanceHistoryList: 'balanceHistory/setBalanceHistoryList'})
  },

  computed: {
    ...mapState({balanceHistory: state => state.balanceHistory, loadingSmall: state => state.loadingSmall}),
    ...mapGetters({sortPagination: 'balanceHistory/sortPagination'})
  },

  mounted(){
    this.actionGetBalanceHistory()
    document.title = "История расчетов - CognitiveAI"
  },

  unmounted(){
    this.setBalanceHistoryList([])
  }
}
</script>