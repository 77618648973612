<template>
  <div class="loading">
    <div class="e-loadholder">
    <div class="m-loader">
      <img src="images\loader.gif" >
     
    </div>
  </div>
  <div id="particleCanvas-Blue"></div>
  <div id="particleCanvas-White"></div>
  </div>
</template>


<script>
export default {
  name: 'loading',

  methods: {
    
  },

  computed: {
   
  },

  mounted(){
    

  }

  
}
</script>
<style>
  

.loading {
  position: fixed;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f9fbfe;
  z-index: 9998;
  /*opacity: 0.9;*/
}

.loading img{
  position: absolute;
  left: 50%;
  top:50%;
  margin-left: -140px;
  margin-top: -140px;
}


</style>