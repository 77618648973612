<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Системные запросы к нейросети</h4>
                </div>  
              </div>
              
              <div class="iq-accordion career-style faq-style  ">
                <system-list :systemList="systemList" :loading="loading"/>
              </div> 
            </div>
            <div v-if="!loading && length > limit" class="col-lg-12">
              <pagination :pages="Math.ceil(length / limit)" :model-value="page" @update:model-value="editPage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import SystemList from '@/components/SystemList'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    SystemList
  },
  methods: {
    ...mapMutations({setSystemList: 'ai/setSystemList', setPage: 'ai/setPage', setLength: 'ai/setLength'}),
    ...mapActions({actionSystemList: 'ai/actionSystemList'}),

    editPage(e){
      this.setPage(e)
      this.actionSystemList()
    }
  },

  computed: {

    ...mapState({
      systemList: state => state.ai.systemList,
      loading: state => state.ai.loading, 
      page: state => state.ai.page,
      length: state => state.ai.length,
      limit: state => state.ai.limit,
      show: state => state.ai.show,
      name: state => state.ai.edit.name
    }),
  },

  mounted(){
    this.actionSystemList();
    document.title = 'Системные запросы к нейросети - CognitiveAI"'
  },

  unmounted(){
    this.setSystemList([])
    this.setPage(1)
    this.setLength(0)
  }
}
</script>