<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }} </p>
      </div>
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Диалоги</h4>
            </div>
          </div>
          <div class="card card-block card-stretch">
            <div class="card-body p-0">
              <div v-if="widgetNameList.length > 0" class="d-flex justify-content-between align-items-center p-3">
                <div>
                  <label>Виджеты техподдержки</label>
                  <div class="form-group mb-4">
                    <my-select-arr
                    class="custom-select"
                    :model-value="widgetName"
                    @update:model-value="openInfoBase"
                    :options="widgetNameList.map(({ id, domain: name }) => ({ id, name }))"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="col-lg-12 col-md-12"><p class="bg-warning-light pl-3 pr-3 pt-2 pb-2 rounded pskdi">Вы пока не создали ни одного виджета технической поддержки. <router-link to="/support-widget">Создать виджет</router-link></p></div>
            </div>
          </div>
          
          <div class="pb-5" v-if="loading"><loading-small /></div>
          <div v-else v-for="w in dialogsList" :key="w.id" class="card card-block card-stretch">
            <div class="card-header justify-content-between widget-block">
              <div class="header-title widget-head-block">
                <div @click="toggleContent(w.id)" class="d-flex flex-wrap align-items-center justify-content-between my-schedule">
                  <h4 class="card-title text-primary">{{ w.name ? w.name : 'Без названия' }}</h4> 
                  <div class="ml-3">{{ w.modelAi }}</div>
                  <h3><span v-if="activeIndex === w.id">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" /></svg>
                    </span>
                    <span v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg>
                    </span></h3>
                </div>
                <div class="widget-block-right">
                  <span class="date mr-3">{{ new Date(w.createdAt).toLocaleString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' }).replace(' г.,', 'г.').replace(/^0+/, '') }}</span>
                  <button @click="actionRemoveDialogsSupportWidget(w.id)" class="badge bg-danger" title="Удалить" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <transition name="slide-fade">
              <div class="card-body" v-show="activeIndex === w.id">
                <div class="cognitiveai-support-widget-support__content">
                  <div class="cognitiveai-support-widget-support__window">
                    <div style="display: contents" v-for="(dialog, index) in w.data" :key="index">
                      <div v-if="dialog.context" :class="`cognitiveai-support-widget-support__text ${dialog.context.role === 'user' ? 'cognitiveai-support-widget-support__message' : 'cognitiveai-support-widget-support__response'}`">
                        <p>{{ dialog.context.content }}</p>
                      </div>
                     <div v-if="dialog.info" class="mt-2 fz-12">
                      <span class="text-secondary ml-3"> Промпт-токены: {{ dialog.info.enter_tokens  }}</span>
                      <span class="text-secondary ml-3"> Ответ-токены: {{ dialog.info.exit_tokens }}</span>
                      <span class="text-danger ml-3">(- {{ dialog.info.num }})</span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="!loading && length > limit" class="col-lg-12">
          <pagination :pages="Math.ceil(length / limit)" :model-value="page" @update:model-value="editPage"/>
        </div>
      </div>
    </div>
  </div>
  <div :class="`${popup ? 'modal-backdrop-pers' : ''}`"></div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import SupportWidget from '@/components/SupportWidget'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    Loading,
    SupportWidget
  },
  methods: {
    ...mapMutations({setWidgetNameList: 'supportWidget/setWidgetNameList', setWidgetName: 'supportWidget/setWidgetName', setPopup: 'supportWidget/setPopup', setTemporaryId: 'supportWidget/setTemporaryId', setActiveIndex: 'supportWidget/setActiveIndex', setPage: 'supportWidget/setPage', setDialogsList: 'supportWidget/setDialogsList'}),

    ...mapActions({actionGetNameDialogsSupportWidget: 'supportWidget/actionGetNameDialogsSupportWidget', actionGetDialogsSupportWidget: 'supportWidget/actionGetDialogsSupportWidget', actionEditInfoBaseSupportWidget: 'supportWidget/actionEditInfoBaseSupportWidget', actionCreateInfoBaseSupportWidget: 'supportWidget/actionCreateInfoBaseSupportWidget', actionRemoveInfoBaseSupportWidget: 'supportWidget/actionRemoveInfoBaseSupportWidget', actionRemoveDialogsSupportWidget: 'supportWidget/actionRemoveDialogsSupportWidget'}),

    openInfoBase(obj){
      this.setWidgetName(obj)
      this.actionGetDialogsSupportWidget()
    },

    openModal(param){
      this.setPopup(true)
      this.setTemporaryId(param) 
    },
    closeModal(){
      this.setPopup(false)
      this.setTemporaryId(0)
    },
    editPage(e){
      this.setPage(e)
      this.actionGetDialogsSupportWidget()
    },
    toggleContent(index) {
      this.setActiveIndex(this.activeIndex === index ? null : index)
    },

  },

  computed: {
    ...mapState({
      loading: state => state.supportWidget.loading,
      length: state => state.supportWidget.length,
      limit: state => state.supportWidget.limit,
      page: state => state.supportWidget.page,
      widgetNameList: state => state.supportWidget.widgetNameList,
      dialogsList: state => state.supportWidget.dialogsList,
      widgetName: state => state.supportWidget.widgetName,
      popup: state => state.supportWidget.popup,
      message: state => state.supportWidget.message,
      error: state => state.supportWidget.error,
      activeIndex: state => state.supportWidget.activeIndex,
      tariff: state => state.user.tariff,
    }),
  },

  mounted(){
    this.actionGetNameDialogsSupportWidget()
    document.title = "Диалоги пользователей обратившись через виджет техподдержки - CognitiveAI"
  },

  unmounted(){
    this.setDialogsList([])
    this.setWidgetName({id: 0, name: null})
    this.setPage(1)
  }
}
</script>

<style scoped>
.date{
    font-size: 12px;
    color: #cd2433;
  }
.cognitiveai-support-widget-support__text {
  position: relative;
  max-width: 80%;
  border-radius: 15px;
  padding: 10px;
}
.cognitiveai-support-widget-support__response {
  background-color: #dfe7ff;
}
.cognitiveai-support-widget-support__response p {
  margin: 0;
}
.cognitiveai-support-widget-support__message {
  background-color: #3378ff;
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}
.cognitiveai-support-widget-support__message p {
  margin: 0;
  color: #fff;
}
.cognitiveai-support-widget-support__message .support__time {
  color: rgba(255, 255, 255, 0.5);
}
.cognitiveai-support-widget-support__window {
  padding: 10px 20px;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto; /* Использование auto вместо scroll */
  gap: 10px;
  scrollbar-width: thin;
  scrollbar-color: #3378ff #e0e0e0;
}
</style>