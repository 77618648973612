<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }}</p>
      </div>
      <div v-if="ai.error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ ai.error }}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">

              <div class="row">
                <div class="col-sm-12 col-lg-9 chat-right-wrapper">
                  <div class="chat-content animate__animated animate__fadeIn   active">
                    <div class="card">
                      <div class="card-body msg-content" ref="chat">
                        <div class="msg-list">
                          <div v-for="chatData in ai.chatData" :key="chatData.content" :class="`single-msg ${chatData.role === 'user' ? 'user' : ''}`">
                            <div :class="`single-msg-shap ${chatData.role === 'user' ? 'triangle-topright' : 'triangle-topleft'}` "></div>
                            <div class="single-msg-content">
                              <div class="msg-detail">
                                {{ chatData.content /*chatData.content.replace(/```([\s\S]+?)```/g, '&lt;h1&gt;$1</h1>')*/ }}
                              </div>
                            </div>
                          </div>
                          <div v-if="ai.loading">
                            <loading-small />
                          </div>
                        </div>
                      </div>
                      <div class="mt-2 fz-12"><span class="text-secondary ml-3"> Промпт-токены: <b>{{ ai.enterTokens }}</b></span><span class="text-secondary ml-3"> Ответ-токены: <b>{{ ai.exitTokens }}</b></span><span class="text-danger ml-3"> ( - {{ ai.amountDebited }} руб.)</span></div>
                      <div class="card-footer px-3 py-3">
                        <div class="input-group">
                          <my-textarea
                            @keyup.enter="startEnter"
                            :model-value="ai.prompt" @update:model-value="setPrompt"
                            class="form-text-chat"
                            placeholder="Диалог с нейронной сетью"
                            rows="1"
                          />
                          <div class="input-group-append mobile-but">
                            <span id="basic-addon2" class="input-group-text chat-icon">
                              <div v-if="ai.prompt && !ai.loading" @click="start" class="chat-button-chat chat-button-active">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                </svg>
                              </div>
                              <div v-else class="chat-button-chat">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                </svg>
                              </div>
                            
                            
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-3">
                  <div class="card ">
                    <div class="card-body">
                      <button type="button" @click="newDialog" class="mb-3 btn btn-outline-success btn-sm btn-block">Начать новый диалог</button>
                      <div class="form-group">
                        <label>Модель</label><br>
                        <my-select-array
                        class="custom-select"
                        :model-value="ai.nameAi"
                        @update:model-value="selectNameAI"
                        :options="nameAi"
                        />
                      </div>
                      <div class="form-group mb-4">
                        <my-select-array
                        class="custom-select"
                        :model-value="ai.modelAi"
                        @update:model-value="setModelAi"
                        :options="modelAi()"
                        />
                      </div>
                      <div class="form-group mb-4">
                        <label>Креативность нейросети <my-required-field /></label><br>
                        <div class="row">
                          <div class="col-sm-12 col-lg-12">
                            <my-range 
                              :model-value="ai.temp" @update:model-value="setTemp"
                              :min="1" 
                              :max="10" 
                              :step="1"
                            />
                          </div>
                          <div class="col-sm-12 col-lg-12">
                            {{ ai.temp }}/10
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-4">
                        <label>Максимальное количество токенов <my-required-field /><my-required-field /></label><br>
                        <div class="row">
                          <div class="col-sm-12 col-lg-12">
                            <my-range 
                              :model-value="ai.maxToken" @update:model-value="setMaxToken"
                              :min="50" 
                              :max="ai.limitToken" 
                              :step="1"
                            />
                          </div>
                          <div class="col-sm-12 col-lg-12">
                            {{ ai.maxToken }}/{{ ai.limitToken }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body item-list">
                      <ul class="item-container p0">
                        <li v-for="chat in ai.chatListLimit" :key="chat.id" class="simple-item hover">
                          <div class="simple-item-body">
                            <div class="simple-item-title">
                              <div @click="chooseChat({id: chat.id, nameAi: ai.nameAi})" class="title-text">{{ chat.name }}</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <button @click="$router.push('/my-chat')" type="button" class="btn mt-2 mr-1 btn-secondary btn-sm btn-block">Все диалоги</button>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12">
                  <div class="alert alert-primary" role="alert">
                    <div class="iq-alert-text">
                      <h5><b>Рекомендации по использованию чата на основе нейронной сети</b></h5><br>
                      <ul>
                        <li class="mb-2">Нейронные сети обладают обширными знаниями, но иногда они могут выдумывать информацию, которая не соответствует действительности. Поэтому важно всегда проверять сгенерированную информацию на точность перед ее использованием.</li>
                        <li class="mb-2">Каждый раз, когда вы задаете вопрос или ведете диалог с нейронной сетью, система учитывает всю предыдущую переписку в чате в рамках текущего диалога. Это означает, что контекст вашего общения непрерывно расширяется и включает в себя все сообщения от начала диалога до текущего момента. Однако с ростом количества сообщений увеличивается количество входящих токенов, что делает каждый новый ответ нейронной сети дороже. Чтобы сброить весь контекст и начать диалог сначала, воспользуйтесь кнопкой "Начать новый диалог"</li>
                        <li class="mb-2"><my-required-field /> Параметр "Креативность нейронной сети" определяет, насколько оригинальными или тривиальными будут её ответы. Низкая креативность приводит к более точным и консервативным ответам, в то время как высокая креативность приводит к более творческим и менее предсказуемым результатам. Подстройка уровня креативности позволяет вам выбирать между надёжностью и оригинальностью в ответах нейронной сети. По умолчанию стоят оптимальные настройки креативности</li>
                        <li class="mb-2"> <my-required-field /><my-required-field /> "Максимальное количество токенов" для нейронной сети определяет объем текста, который искусственный интеллект может произвести за один запрос. Минимальное значение этого параметра приведет к более лаконичным ответам. Если ваша цель — получение обширного текста, например статьи, следует установить более высокий лимит токенов, что позволит модели развернуть мысль и представить более полную информацию. Это управление помогает находить баланс между информативностью ответов и их экономической эффективностью.</li>
                        
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import CopyText from '@/components/CopyText'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  data(){
    return {
      svgRotate: false,
    }
  },
  components: {
    Header,
    CopyText
  },
  methods: {
    ...mapMutations({setPrompt: 'ai/setPrompt', setReadyPrompt: 'ai/setReadyPrompt', setNameAi: 'ai/setNameAi', setModelAi: 'ai/setModelAi', setTemp: 'ai/setTemp', setMaxToken: 'ai/setMaxToken', setChat: 'ai/setChat', setAmountDebited: 'ai/setAmountDebited', setEnterTokens: 'ai/setEnterTokens', setExitTokens: 'ai/setExitTokens', setChatData: 'ai/setChatData', setChatId: 'ai/setChatId', setChatListLimit: 'ai/setChatListLimit'}),
    ...mapActions({actionAiContext: 'ai/actionAiContext', actionGetChatNameLimit: 'ai/actionGetChatNameLimit', actionChooseChat: 'ai/actionChooseChat'}),
    ...mapGetters({sortTypeAi: 'ai/sortTypeAi', modelAi: 'ai/modelAi'}),

    scrollToBottom() {
      this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
    },
    
    promptShowClose() {
      this.svgRotate = !this.svgRotate
      this.sortTypeAi()
    },

    selectNameAI(param){
      this.setNameAi(param)
      this.actionGetChatNameLimit()
      this.setAmountDebited(0)
      this.setEnterTokens(0)
      this.setExitTokens(0)
      this.setChatData([])
      this.setChatId(0)
    },

    startEnter(event){
      if (event.shiftKey) {
        return
      }
      this.start()
    },

    newDialog(){
      this.setAmountDebited(0)
      this.setEnterTokens(0)
      this.setExitTokens(0)
      this.setChatData([])
      this.setChatId(0)
    },

    chooseChat(data){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.actionChooseChat(data)
    },

    start(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.promptShowClose()
      const data = this.ai.chatData
      data.push({role: 'user', content: this.ai.prompt})
      this.setChatData(data)
      this.actionAiContext()
    }
  },

  computed: {
    ...mapState({
      ai: state => state.ai,
      nameAi: state => state.ai.modelAiList.map(({name}) => name),
      message: state => state.message,
    }),
  },

  mounted(){
    this.setChat(true)
    this.scrollToBottom()
    this.actionGetChatNameLimit()
    document.title = 'Чат - CognitiveAI"'
  },

  updated() {
    this.scrollToBottom()
  },

  unmounted(){
    this.setAmountDebited(0)
    this.setEnterTokens(0)
    this.setExitTokens(0)
    this.setPrompt(null)
    this.setChatData([])
    this.setChat(false)
    this.setChatId(0)
    this.setChatListLimit([])
  }
}
</script>

<style>
  .form-text-chat{
    line-height: 17px;
    min-height: 150px;
  }

  .fz-13{
    font-size: 13px;
  }

  .svg-rotate{
    rotate:45deg;
  }

  .svg-click{
    cursor: pointer;
  }

  datalist::-webkit-calendar-picker-indicator, input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
.w-272{
  width: 272px;
}
.form-text-chat.form-control{
  resize: both;
}

.chat-button-chat svg{
  color: #f1f1f1;
  width: 25px;
}
.chat-button-active{
  cursor: pointer;
}

.chat-button-active svg{
  color: #3378ff;
}

.chat-button-active:hover{
  border-radius: 5px;
  background-color: #f1f1f1;
}

.fz-12{
  font-size: 12px;
}

.p0{
  padding: 0;
}

.simple-item{
  padding: 10px 0px 10px 5px;
}

.simple-item .edit-c{
  font-size: 17px;
  margin-left: 10px;
}

.simple-item .edit-c:hover{
  color: #f42b3d;
}

.msg-detail{white-space: pre-wrap;}
.input-group-text{
  flex-direction: column-reverse;
}

.simple-item .simple-item-title .title-text{
  overflow: auto;
}
@media (max-width: 767px) {
  .form-text-chat{
    width: 100% !important;
  }
  .mobile-but{width: 100%; display: block;;}
}
  
</style>