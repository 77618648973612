<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Партнерская программа</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Партнерская программа</h4>
                </div> 
              </div>

              <div v-if="partner" class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="d-flex flex-wrap justify-content-start align-items-center">
                            <h5 class="mb-0 font-weight-bold">Партнерские ссылки:</h5>
                            <p class="mb-0 ml-3 text-success font-weight-bold"><a :href="`https://cognitiveai.ru/?ref=${partner.ref}`">https://cognitiveai.ru/?ref={{ partner.ref }}</a></p>
                            <p class="mb-0 ml-3 text-success font-weight-bold"><a :href="`https://cognitive-ai.ru/?ref=${partner.ref}`">https://cognitive-ai.ru/?ref={{ partner.ref }}</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card-body">
                    <h5 class="mb-0 font-weight-bold">Партнерское вознаграждение</h5>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div>
                          <p class="mb-2 text-secondary">Тарифы на автоматизацию прайс-листов поставщиков и доступ к API</p>
                          <div class="d-flex flex-wrap justify-content-start align-items-center">
                            <p class="mb-0 ml-3 text-success font-weight-bold">{{ partner.percent_api }}%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div>
                          <p class="mb-2 text-secondary">Нейронная сеть</p>
                          <div class="d-flex flex-wrap justify-content-start align-items-center">
                            <p class="mb-0 ml-3 text-success font-weight-bold">{{ partner.percent_ai }}%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div v-else class="row">
                <div class="col-sm-12 col-lg-12">
                  <partner-form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import PartnerForm from '@/components/PartnerForm'
PartnerForm
import {mapState, mapActions} from 'vuex'
export default {
  components: {
    Header,
    PartnerForm
  },

  computed: {
    ...mapState({
      partner: state => state.user.partner,
      error: state => state.partner.error,
    }),
  },

  mounted(){
    document.title = 'Партнер - CognitiveAI"'
  },

}
</script>

<style>

</style>