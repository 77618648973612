<template>
  <div class="iq-sidebar  sidebar-default ">
    <div class="iq-sidebar-logo d-flex align-items-end justify-content-between">
      <a href="https://cognitiveai.ru" class="header-logo">
        <img v-if="!active" src="/images/logo.png" class="img-fluid rounded-normal light-logo" alt="logo">
        <img v-else src="/images/logo_min.png" class="img-fluid rounded-normal light-logo lp-img" alt="logo">
        <span></span>            
      </a>
      <div class="side-menu-bt-sidebar-1" @click="sidebarMain">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1">
      <div class="scroll-content">
        <nav class="iq-sidebar-menu">
          <ul class="side-menu">
            <li @click="hideMain" v-bind:class="[this.$route.path === '/' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/feed" class="svg-icon">
                <i class="fa fa-home" aria-hidden="true"></i>
                <span class="ml-2">Home </span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/price' ? 'active sidebar-layout' : 'sidebar-layout']">
              <a href="https://cognitiveai.ru/prices/" target="_blank" class="svg-icon">
                <i class="fa fa-rub" aria-hidden="true"></i>
                <span class="ml-2">Цены</span>
              </a>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-tariff' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-tariff" class="svg-icon">
                <i class="fa fa-credit-card" aria-hidden="true"></i>
                <span class="ml-2">Платная подписка</span>
              </router-link>
            </li>
          </ul>
          <ul v-if="loading" class="side-menu">
            <li class="sidebar-layout">
              <span class="text-uppercase small font-weight-bold"><br><loading-small /></span>
            </li>
          </ul>
          
          <ul v-else class="side-menu">
            <li @click="hideMain" v-bind:class="[this.$route.path === '/api' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/api" class="svg-icon">
                <i class="fa fa-exchange" aria-hidden="true"></i>
                <span class="ml-2">API</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/chat' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/chat" class="svg-icon">
                <i class="fa fa-comments-o" aria-hidden="true"></i>
                <span class="ml-2">Chat</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/create-text' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/create-text" class="svg-icon">
                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                <span class="ml-2">Сгенерировать текст</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/create-image' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/create-image" class="svg-icon">
                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                <span class="ml-2">Сгенерировать изображение</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-prompts' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-prompts" class="svg-icon">
                <i class="fa fa-comment-o" aria-hidden="true"></i>
                <span class="ml-2">Промпты</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-chat' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-chat" class="svg-icon">
                <i class="fa fa-comments" aria-hidden="true"></i>
                <span class="ml-2">Мои чаты</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-images' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-images" class="svg-icon">
                <i class="fa fa-clone" aria-hidden="true"></i>
                <span class="ml-2">Мои изображения</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-text' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-text" class="svg-icon">
                <i class="fa fa-list-alt" aria-hidden="true"></i>
                <span class="ml-2">Мои тексты</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-system' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-system" class="svg-icon">
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span class="ml-2">Системные</span>
              </router-link>
            </li>

            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">Для бизнеса</span>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/chat-widget' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/chat-widget" class="svg-icon">
                <i><svg width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M18 18.86H17.24C16.44 18.86 15.68 19.17 15.12 19.73L13.41 21.42C12.63 22.19 11.36 22.19 10.58 21.42L8.87 19.73C8.31 19.17 7.54 18.86 6.75 18.86H6C4.34 18.86 3 17.53 3 15.89V4.97998C3 3.33998 4.34 2.01001 6 2.01001H18C19.66 2.01001 21 3.33998 21 4.97998V15.89C21 17.52 19.66 18.86 18 18.86Z" fill="#292D32"/>
                  <path d="M16.5805 9.56998C16.3905 9.29998 16.0705 9.15 15.6905 9.15H13.7405C13.6105 9.15 13.4905 9.09998 13.4105 8.99998C13.3305 8.89998 13.2905 8.76998 13.3105 8.62998L13.5505 7.06998C13.6505 6.60998 13.3405 6.07997 12.8805 5.92997C12.4505 5.76997 11.9405 5.98995 11.7405 6.28995L9.80048 9.16996V8.80997C9.80048 8.10997 9.50047 7.81998 8.76047 7.81998H8.27048C7.53048 7.81998 7.23047 8.10997 7.23047 8.80997V13.59C7.23047 14.29 7.53048 14.58 8.27048 14.58H8.76047C9.46047 14.58 9.76047 14.31 9.79047 13.67L11.2605 14.8C11.4605 15 11.9105 15.11 12.2305 15.11H14.0805C14.7205 15.11 15.3605 14.63 15.5005 14.04L16.6705 10.48C16.8005 10.16 16.7705 9.82998 16.5805 9.56998Z" fill="#292D32"/>
                </svg></i>
                <span class="ml-2">Виджеты чата с нейросетями</span>
              </router-link>
            </li>

            <li @click="hideMain" v-bind:class="[this.$route.path === '/support-info-base' || this.$route.path === '/support-widget-dialogs' || this.$route.path === '/support-widget' ? 'active sidebar-layout' : 'sidebar-layout']">
              <a href="#" @click="setActionSWidget" class="svg-icon" data-toggle="collapse" aria-expanded="true">
                <i class="pos-rel">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                  </svg>
                </i>
                <span class="ml-2">Техподдержка на базе ИИ  <beta /></span>
                <svg xmlns="http://www.w3.org/2000/svg" :class="`svg-icon iq-arrow-right ${actionSWidget ? 'arrow-active' : ''}`" width="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </a>
              <ul :class="`submenu collapse ${actionSWidget ? 'show' : ''}`" data-parent="#iq-sidebar-toggle" style="">
                <li @click="hideMain" v-bind:class="[this.$route.path === '/support-widget' ? 'active sidebar-layout' : 'sidebar-layout']">
                  <router-link to="/support-widget" class="svg-icon">
                    <i class="">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                      </svg>

                    </i>
                    <span class="ml-2">Виджеты</span>
                  </router-link>
                </li>
                <li @click="hideMain" v-bind:class="[this.$route.path === '/support-info-base' ? 'active sidebar-layout' : 'sidebar-layout']">
                  <router-link to="/support-info-base" class="svg-icon">
                    <i class="">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                      </svg>

                    </i>
                    <span class="ml-2">Базы знаний</span>
                  </router-link>
                </li>
                <li @click="hideMain" v-bind:class="[this.$route.path === '/support-widget-dialogs' ? 'active sidebar-layout' : 'sidebar-layout']">
                  <router-link to="/support-widget-dialogs" class="svg-icon">
                    <i class="">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                      </svg>
                    </i>
                    <span class="ml-2">Диалоги</span>
                  </router-link>
                </li>
                <!--<li @click="hideMain" v-bind:class="[this.$route.path === '/support-widget-contacts' ? 'active sidebar-layout' : 'sidebar-layout']">
                  <router-link to="/support-widget-contacts" class="svg-icon">
                    <i class="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"></path>
                      </svg>
                    </i>
                    <span class="ml-2">Контакты пользователей</span>
                  </router-link>
                </li>-->
              </ul>
            </li>

            <!--<li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">Партнерам</span>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-information' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-information" class="svg-icon">
                <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                <span class="ml-2">Информация</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-promocode' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-promocode" class="svg-icon">
                <i class="fa fa-percent" aria-hidden="true"></i>
                <span class="ml-2">Промокоды</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-referrals' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-referrals" class="svg-icon">
                <i class="fa fa-hand-rock-o" aria-hidden="true"></i>
                <span class="ml-2">Рефералы</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-payments' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-payments" class="svg-icon">
                <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                <span class="ml-2">Начисления и выплаты</span>
              </router-link>
            </li>-->

            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">Поддержка</span>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/support' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/support" class="svg-icon">
                <i class="fa fa-life-ring" aria-hidden="true"></i>
                <span class="ml-2">Поддержка</span>
              </router-link>
            </li>
          </ul>
          <br><br><br><br><br><br><br><br>
        </nav>
      </div>
    </div>
  </div>

  
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div  v-on:click="sidebarMain">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
        <div class="d-flex align-items-center">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-label="Toggle navigation" @click="clickNav()">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
          </button>
          <div v-if="loading" class="side-menu">      
            <span class="text-uppercase small font-weight-bold"><br><loading-small /></span>      
          </div>
          <div v-else :class="`navbar-collapse collapse ${show}`">
            <ul class="navbar-nav ml-auto navbar-list align-items-center">
              
              <li class="nav-item nav-icon dropdown"> 
                <a href="#" class="search-toggle dropdown-toggle" id="notification-dropdown" >
                  <span>{{ user.balance ? user.balance.account : 0}} ₽  <span class="mt-3 badge border border-success text-success mt-3" data-toggle="modal" data-target="#topUpBalanceModal"> Пополнить</span></span>
                  <button v-if="user.top_up_balance && user.top_up_balance.payment_method === 'Безнал'" class="btn btn-dark btn-sm ml-2" @click="$router.push('/invoice-balance')">Счет</button>
                  <span class="bg-primary"></span>
                </a> 
              </li>
              <li class="nav-item nav-icon search-content">
                <a href="#" class="search-toggle rounded" id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    
                </a>
                <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                    <form action="#" class="searchbox p-2">
                        <div class="form-group mb-0 position-relative">
                        <input type="text" class="text search-input font-size-12" placeholder="type here to search...">
                        <a href="#" class="search-link">
                            <svg xmlns="http://www.w3.org/2000/svg" class="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </a> 
                        </div>
                    </form>
                </div>
            </li>
            <li class="nav-item nav-icon dropdown">
                <a href="#" class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  
                    <img v-if="user_image" :src="`https://cognitive-ai.ru/images/u${user_id}/user/${user_image}`" class="img-fluid avatar-rounded" alt="user" />
                    <img v-else src="/images/user/1.jpg" class="img-fluid avatar-rounded" alt="user" />
                    <span class="mb-0 ml-2 user-name">{{user_name}}</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <li class="dropdown-item d-flex svg-icon">
                        <svg class="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <router-link to="/my-profile">Мой профиль</router-link>
                    </li>
                    
                    <div>
                      <li class="dropdown-item d-flex svg-icon">
                        <i class="fa fa-credit-card svg-icon mr-0 text-secondary" aria-hidden="true"></i>
                        <router-link to="/payment-details-yur" class="reh22">Реквизиты для оплаты</router-link>
                      </li>
                      <li class="dropdown-item d-flex svg-icon">
                        <i class="fa fa-history svg-icon mr-0 text-secondary" aria-hidden="true"></i>
                        <router-link to="/my-balance-history" class="reh33">История расчетов</router-link>
                      </li>
                    </div>
                    <li class="dropdown-item  d-flex svg-icon border-top">
                        <svg class="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        <a href="#" @click="actionLogout">Выход</a>
                        
                    </li>
                </ul>
            </li> 
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <top-up-balance-modal />
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import TopUpBalanceModal from '@/components/TopUpBalanceModal'
export default {
  components: {
    TopUpBalanceModal,
  },
  data(){
    return {
      show: '',
      active: false,
    }
  },
  methods: {
    sidebarMain() {
      this.active = !this.active;
      let siteBody = document.body;
      this.active ? siteBody.classList.add("sidebar-main") : siteBody.classList.remove("sidebar-main");
    },

    hideMain(){
      let siteBody = document.body;
      siteBody.classList.remove("sidebar-main");
    },

    clickNav(){
      this.show ? this.show = '' : this.show = 'show'
    },

    ...mapActions(['actionLogout', 'actionUser', 'actionCheckTariff']),
    ...mapMutations(['setNeuro', 'setActionSWidget'])
      
  },

  computed: {
    ...mapState({
      user_id: state => state.user.id,
      user_status: state => state.user.status,
      user_email: state => state.user.email,
      user_name: state => state.user.name,
      user_image: state => state.user.image,
      user: state => state.user,
      loading: state => state.loading,
      neuro: state => state.neuro,
      actionSWidget: state => state.actionSWidget
    })
  },

  mounted(){
    jQuery('.data-scrollbar').each(function () {
      const attr = $(this).attr('data-scroll');
      if (typeof attr !== typeof undefined && attr !== false){
      let Scrollbar = window.Scrollbar;
      const a = jQuery(this).data('scroll');
      Scrollbar.init(document.querySelector('div[data-scroll= "' + a + '"]'));
      }
    })
  }

}
</script>

<style>
 .pos-rel{position: relative; left: 4px;}
 .mt-3{margin-left: 20px;}
 .red{color: red}
 .f-fold{font-size:30px}
 .reh1{
  font-size:22px !important;
  padding-right: 5px;
 }
 .reh11{
  padding-top: 3px !important;
 }
 .reh22{
  position: relative;
  left: 4px;
  top: -3px;
 }
 .reh33{
  position: relative;
  left: 7px;
  top: -3px;
 }
 .lp-img{
  height: auto !important;
 }
 .iq-sidebar-menu .side-menu li .active-menu{
  color: #fff;
    text-decoration: none;
    background-color: hsla(0,0%,100%,.08) !important;
 }
 .iq-sidebar-menu .side-menu li .active-menu a {
  color: #fff;
 }
</style>
