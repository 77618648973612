import { axiosCreateSupportWidget, axiosGetSupportWidget, axiosRemoveSupportWidget, axiosEditStrSupportWidget, axiosChooseAiSupportWidget, axiosBoolSupportWidget, axiosEditSectionDataSupportWidget, axiosGetNameSupportWidget, axiosGetInfoBaseSupportWidget, axiosCreateInfoBaseSupportWidget, axiosRemoveInfoBaseSupportWidget, axiosEditInfoBaseSupportWidget, axiosGetDialogsSupportWidget, axiosGetContactsSupportWidget, axiosRemoveDialogsSupportWidget } from '@/api/index'

export const supportWidgetModule = {
  state: () => ({
    loading: false,
    error: '',
    message: '',
    widgetList: [],
    domain: '',
    style: null,
    popup: false,
    activeIndex: null,
    contactInput: null,
    contactInputFlag: false,
    sectionDataInputUrl: null,
    sectionDataInputUrlFlag: false,
    sectionDataInputCatalog: null,
    sectionDataInputCatalogFlag: false,
    limitTokens: 1000,
    temporary: {
      id: 0,
      data: {}
    },
    page: 1,
    length: 0,
    limit: 10,

    // Базы знаний
    widgetNameList: [],
    widgetName: null,
    widgetId: 0,
    infoBaseList: [],

    // Диалоги
    dialogsList: [],
    //
    contactsList: [],

  }),

  getters: {
   
  },

  mutations: {
    setLoading(state, v){state.loading = v},
    setError(state, v){state.error = v},
    setMessage(state, v){state.message = v},
    setWidgetList(state, v){state.widgetList = v},
    setDomain(state, v){state.domain = v},
    setStyle(state, v){state.style = v},
    setPopup(state, v){state.popup = v},
    setActiveIndex(state, v){state.activeIndex = v},
    setTemporaryId(state, v){state.temporary.id = v},
    setTemporaryData(state, v){state.temporary.data = v},
    setPage(state, v){state.page = v},
    setLength(state, v){state.length = v},

    // Добавления текстовых днных
    setNameTrigger(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, name_trigger: data.name} : widget)},
    setNameChat(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, name_chat: data.name} : widget)},
    setGreetings(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, greetings: data.name} : widget)},
    setThanksContact(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, thanks_contact: data.name} : widget)},
    setColorFon(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, color_fon: data.name} : widget)},
    setColorIcon(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, color_fon_icon: data.name} : widget)},

    // Добавляем нейросети
    setTemp(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, temp: Number(data.name)} : widget)},
    setMaxTokens(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, max_tokens: Number(data.name)} : widget)},
    setNameModalAi(state, data){state.widgetList = state.widgetList.map(widget => widget.id === data.id ? {...widget, name_ai: data.nameAi, model_ai: data.modelAi} : widget)},

    setAddContacts(state, id){
      state.widgetList = state.widgetList.map(widget => {
        if (widget.id === id) {
          const updatedContacts = [...widget.contacts, state.contactInput];
          return { ...widget, contacts: updatedContacts };
        }
        return widget;
      });
      state.contactInput = null
    },
    setRemoveContacts(state, data){
      state.widgetList = state.widgetList.map(widget => {
        if (widget.id === data.id) {
          const filteredContacts = widget.contacts.filter((_, index) => index !== data.index);
          return { ...widget, contacts: filteredContacts };
        }
        return widget;
      });
    },

    // Добавляем урлы и ктаалог для автоматическо определеня базы знаний
    setAddSectionData(state, id){
      state.widgetList = state.widgetList.map(widget => {
        if (widget.id === id) {
          const updatedSectionData = [...widget.auto_section_data, {url: state.sectionDataInputUrl, catalog: state.sectionDataInputCatalog}];
          return { ...widget, auto_section_data: updatedSectionData };
        }
        return widget;
      });
      state.sectionDataInputUrl = null
      state.sectionDataInputCatalog = null
    },
    setRemoveSectionData(state, data){
      state.widgetList = state.widgetList.map(widget => {
        if (widget.id === data.id) {
          const filteredSectionData = widget.auto_section_data.filter((_, index) => index !== data.index);
          return { ...widget, auto_section_data: filteredSectionData };
        }
        return widget;
      });
    },

    setContactInput(state, v){state.contactInput = v; state.contactInputFlag = false},
    setContactInputFlag(state, v){state.contactInputFlag = v},
    setSectionDataInputUrl(state, v){state.sectionDataInputUrl = v; state.sectionDataInputUrlFlag = false},
    setSectionDataInputUrlFlag(state, v){state.sectionDataInputUrlFlag = v},
    setSectionDataInputCatalog(state, v){state.sectionDataInputCatalog = v; state.sectionDataInputCatalogFlag = false},
    setSectionDataInputCatalogFlag(state, v){state.sectionDataInputCatalogFlag = v},

    // Базы занний
    setWidgetNameList(state, v){
      state.widgetNameList = v
      if(v.length > 0){
        state.widgetName = v[0].domain
        state.widgetId = Number(v[0].id)
      }
    },
    setWidgetName(state, v){state.widgetName = v.name; state.widgetId = Number(v.id)},
    setInfoBaseList(state, v){state.infoBaseList = v},
    setNameInfoBase(state, data){state.infoBaseList = state.infoBaseList.map(widget => widget.id === data.id ? {...widget, name: data.name} : widget)},
    setTextInfoBase(state, data){state.infoBaseList = state.infoBaseList.map(widget => widget.id === data.id ? {...widget, text: data.name} : widget)},
    setDopTextInfoBase(state, data){state.infoBaseList = state.infoBaseList.map(widget => widget.id === data.id ? {...widget, dop_text: data.name} : widget)},

    // Диалоги
    setDialogsList(state, v){state.dialogsList = v},
    setContactsList(state, v){state.contactsList = v},

  },

  actions: {
    // Создаем виджет
    async actionCreateSupportWidget({state, commit, rootState, dispatch}){
      try {
        const response = await axiosCreateSupportWidget(rootState.token, {domain: state.domain})
        commit('setMessage', response.message)
        commit('setDomain', '')
        await dispatch('actionGetSupportWidget');
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateSupportWidget')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Получаем виджеты
    async actionGetSupportWidget({commit, state, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosGetSupportWidget(rootState.token, state.page, state.limit)
        commit('setWidgetList', response.widgets)
        commit('setLength', response.count)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetSupportWidget')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Получаем виджеты приватно
    async actionGetSupportWidgetPrivate({commit, state, rootState}){
      try {
        const response = await axiosGetSupportWidget(rootState.token, state.page, state.limit)
        commit('setWidgetList', response.widgets)
        commit('setLength', response.count)
      } catch (e) {
        console.log(e.response.data)
      }
    },

    //Редактируем текстовый настройки
    async actionEditStrSupportWidget({commit, state, rootState, dispatch}, id){
      try {
        commit('setTemporaryId', id)
        const data = (({ id, name_trigger, name_chat, greetings, contacts, dialog_session, color_fon, color_fon_icon, thanks_contact }) => ({ id, name_trigger, name_chat, greetings, contacts, dialog_session, color_fon, color_fon_icon, thanks_contact }))(state.widgetList.find(obj => obj.id === state.temporary.id));
        const response = await axiosEditStrSupportWidget(rootState.token, data)
        commit('setTemporaryId', 0)
        dispatch('actionGetSupportWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditStrSupportWidget', state.temporary.id)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Выбираем нейросеть
    async actionChooseAiSupportWidget({commit, state, rootState, dispatch}, id){
      try {
        commit('setTemporaryId', id)
        const objectWithId = state.widgetList.find(obj => obj.id === state.temporary.id);
        const data = {id: state.temporary.id, nameAi: objectWithId.name_ai, modelAi: objectWithId.model_ai, temp: objectWithId.temp, maxTokens: objectWithId.max_tokens}
        const response = await axiosChooseAiSupportWidget(rootState.token, data)
        commit('setTemporaryId', 0)
        commit('setTemporaryData', {})
        dispatch('actionGetSupportWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
        commit('setError', '')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionChooseAiSupportWidget', state.temporary.id)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 14000);
        }
        console.log(e.response.data)
      }
    },

    // Редактируем все буливые значения
    async actionBoolSupportWidget({commit, state, rootState, dispatch}, data){
      commit('setTemporaryData', data)
      try {
        const response = await axiosBoolSupportWidget(rootState.token, state.temporary.data)
        commit('setTemporaryData', {})
        dispatch('actionGetSupportWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionBoolSupportWidget', state.temporary.data)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Редактируем массив ссылок связанных с каталогом
    async actionEditSectionDataSupportWidget({commit, state, rootState, dispatch}, id){
      try {
        commit('setTemporaryId', id)
        const data = (({ auto_section_data }) => ({ auto_section_data }))(state.widgetList.find(obj => obj.id === state.temporary.id));
        const response = await axiosEditSectionDataSupportWidget(rootState.token, {id: state.temporary.id, data: data.auto_section_data})
        commit('setTemporaryId', 0)
        dispatch('actionGetSupportWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditSectionDataSupportWidget', state.temporary.id)
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    // Удаляем виджет
    async actionRemoveSupportWidget({commit, state, rootState, dispatch}){
      try {
        const response = await axiosRemoveSupportWidget(rootState.token, state.temporary.id)
        commit('setTemporaryId', 0)
        commit('setPopup', false)
        dispatch('actionGetSupportWidgetPrivate')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionRemoveSupportWidget')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 8000);
        }
        console.log(e.response.data)
      }
    },

    /** 
   * База знаний
   */
  // Получаем название виджетов по id
  async actionGetNameSupportWidget({commit, rootState, dispatch}){
    try {
      const response = await axiosGetNameSupportWidget(rootState.token)
      commit('setWidgetNameList', response.widgets)
      await dispatch('actionGetInfoBaseSupportWidget')
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionGetNameSupportWidget')
      }
      console.log(e.response.data)
    }
  },

  // Получаем базу знаний согласно id виджет
  async actionGetInfoBaseSupportWidget({commit, state, rootState, dispatch}){
    try {
      commit('setLoading', true)
      const response = await axiosGetInfoBaseSupportWidget(rootState.token, state.widgetId)
      commit('setInfoBaseList', response.base)
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionGetInfoBaseSupportWidget')
      }
      console.log(e.response.data)
    } finally {
      setTimeout(function () {
        commit('setLoading', false)
      }, 500);
    }
  },

  // Получаем базу знаний по id приватно
  async actionGetInfoBaseSupportWidgetPrivate({commit, state, rootState}){
    try {
      const response = await axiosGetInfoBaseSupportWidget(rootState.token, state.widgetId)
      commit('setInfoBaseList', response.base)
    } catch (e) {
      console.log(e.response.data)
    }
  },

  // Создаем базу знаний
  async actionCreateInfoBaseSupportWidget({state, commit, rootState, dispatch}){
    try {
      commit('setLoading', true)
      const response = await axiosCreateInfoBaseSupportWidget(rootState.token, state.widgetId)
      commit('setMessage', response.message)
      await dispatch('actionGetInfoBaseSupportWidgetPrivate');
      setTimeout(function () {
        commit('setMessage', '')
      }, 3000);
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionCreateInfoBaseSupportWidget')
      } else {
        commit('setError', e.response.data.message)
        setTimeout(function () {
          commit('setError', '')
        }, 8000);
      }
      console.log(e.response.data)
    } finally {
      setTimeout(function () {
        commit('setLoading', false)
      }, 500);
    }
  },

  // Удаляем базу знаний
  async actionRemoveInfoBaseSupportWidget({commit, state, rootState, dispatch}){
    try {
      const response = await axiosRemoveInfoBaseSupportWidget(rootState.token, state.temporary.id, state.widgetId)
      commit('setTemporaryId', 0)
      commit('setPopup', false)
      dispatch('actionGetInfoBaseSupportWidgetPrivate')
      commit('setMessage', response.message)
      setTimeout(function () {
        commit('setMessage', '')
      }, 3000);
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionRemoveInfoBaseSupportWidget')
      } else {
        commit('setError', e.response.data.message)
        setTimeout(function () {
          commit('setError', '')
        }, 8000);
      }
      console.log(e.response.data)
    }
  },

  // Редактируем базу знаний
  async actionEditInfoBaseSupportWidget({commit, state, rootState, dispatch}, id){
    try {
      commit('setTemporaryId', id)
      const data = (({id, id_support_widget, name, text, dop_text, type }) => ({ id, id_support_widget, name, text, dop_text, type }))(state.infoBaseList.find(obj => obj.id === state.temporary.id));
      const response = await axiosEditInfoBaseSupportWidget(rootState.token, data)
      commit('setTemporaryId', 0)
      dispatch('actionGetInfoBaseSupportWidgetPrivate')
      commit('setMessage', response.message)
      setTimeout(function () {
        commit('setMessage', '')
      }, 3000);
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionEditInfoBaseSupportWidget', state.temporary.id)
      } else {
        commit('setError', e.response.data.message)
        setTimeout(function () {
          commit('setError', '')
        }, 8000);
      }
      console.log(e.response.data)
    }
  },


   /** 
   * Диалоги
   */
  // Получаем название виджетов по id
  async actionGetNameDialogsSupportWidget({commit, rootState, dispatch}){
    try {
      const response = await axiosGetNameSupportWidget(rootState.token)
      commit('setWidgetNameList', response.widgets)
      await dispatch('actionGetDialogsSupportWidget')
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionGetNameDialogsSupportWidget')
      }
      console.log(e.response.data)
    }
  },

  // Получаем диалоги по странично согласно id виджет
  async actionGetDialogsSupportWidget({commit, state, rootState}){
    try {
      commit('setLoading', true)
      const response = await axiosGetDialogsSupportWidget(rootState.token, state.widgetId, state.page, state.limit)
      commit('setDialogsList', response.dialogs)
      commit('setLength', response.count)
    } catch (e) {
      console.log(e.response.data)
    } finally {
      setTimeout(function () {
        commit('setLoading', false)
      }, 500);
    }
  },

  // Удаляем диалоги
  async actionRemoveDialogsSupportWidget({commit, state, rootState, dispatch}, id){
    try {
      commit('setTemporaryId', id)
      const response = await axiosRemoveDialogsSupportWidget(rootState.token, state.temporary.id, state.widgetId)
      commit('setTemporaryId', 0)
      dispatch('actionGetDialogsSupportWidget')
      commit('setMessage', response.message)
      setTimeout(function () {
        commit('setMessage', '')
      }, 3000);
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionRemoveDialogsSupportWidget', state.temporary.id)
      } else {
        commit('setError', e.response.data.message)
        setTimeout(function () {
          commit('setError', '')
        }, 8000);
      }
      console.log(e.response.data)
    }
  },

  /** 
   * Контакты
   */
  // Плучаем контакты по странично
  async actionGetContactsSupportWidget({commit, state, rootState, dispatch}){
    try {
      commit('setLoading', true)
      const response = await axiosGetContactsSupportWidget(rootState.token, state.page, state.limit)
      commit('setContactsList', response.contacts)
      commit('setLength', response.count)
    } catch (e) {
      if(e.response.status === 419){
        await dispatch('actionAuthAccess', null, { root: true })
        await dispatch('actionGetContactsSupportWidget')
      }
      console.log(e.response.data)
    } finally {
      setTimeout(function () {
        commit('setLoading', false)
      }, 500);
    }
  },
    

  },
  namespaced: true
}