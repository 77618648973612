<template>
  <Header></Header>
  <div class="content-page">
  <div class="container-fluid">
    <div v-if="message" class="form-group col-12 mess">
      <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
    </div>
    <div v-if="user.role === 1">
      <div class="row">
        <div class="col-lg-12 col-md-12 mobile-home">
          <div class="row">
            <div class="col-md-4">
              <div class="">
                <div class="card-body">
                  <div class="align-center">
                    <div>
                      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                        <router-link to="/chat" type="button" class="btn btn-outline-success mt-2 btn-with-icon btn-block btn-lg">
                          Начать чат
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="">
                <div class="card-body">
                  <div class="align-center">
                    <div>
                      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                        <router-link to="/create-text" type="button" class="btn btn-outline-success mt-2 btn-with-icon btn-block btn-lg">
                          Сгенерировать текст
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="">
                <div class="card-body">
                  <div class="align-center">
                    <div>
                      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                        <router-link to="/create-image"  type="button" class="btn btn-outline-success mt-2 btn-with-icon btn-block btn-lg">
                          Сгенерировать изображение
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(user.tariff === null || (user.tariff && user.tariff.status === false))" class="col-lg-12 col-md-12 mb-5">
          <div class="row block-sale">
            <div class="col-md-6 block-sale-text">
              <p v-html="textLabelHtml" class="mt-5"></p>
              <div class="col-lg-12 col-md-12 text-center mt-t10">
                <router-link to="/my-tariff" type="button" class="btn btn-dark mt-2 btn-with-icon btn-lg"><span v-html="buttonLabelHtml"></span></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 mb-5">
          <latest-chats />
        </div>
        <div class="col-lg-12 col-md-12 mb-5">
          <latest-images />
        </div>
      </div>
    </div>
  </div>
</div>
<my-footer />
</template>


<script>
import {mapState, mapActions} from 'vuex'
import Header from '@/components/Header'
import CopyToken from '@/components/CopyToken'
import LatestChats from '@/components/LatestChats'
import LatestImages from '@/components/LatestImages'
export default {
components: {
  Header,
  CopyToken,
  LatestChats,
  LatestImages
},

methods: {
  ...mapActions({actionLogout: 'actionLogout', actionUser: 'actionUser', actionSupplier: 'actionSupplier', actionCategories: 'suppliersList/actionCategories', actionCheckTariff: 'actionCheckTariff'}),

  copyToken(){
    navigator.clipboard.writeText(this.user.user_package.token).then(() => {
        this.setMessage('Токен скопирован в буфер обмена')
        setTimeout(() => this.setMessage(''), 2000)
      })
  }
    
},

computed: {
  ...mapState({
    message: state => state.message,
    user: state => state.user,
    tariff: state => state.tariff,
  }),

  buttonLabelHtml() {
    return this.user.tariff && this.user.tariff.status === false ? 'Продлить подписку' : 'Подключить за 600 <i class="fa fa-rub" aria-hidden="true"></i>/мес.'
  },

  textLabelHtml() {
    return this.user.tariff && this.user.tariff.status === false ? '<b>Ваша подписка истекла!</b><br> Продлите её сегодня, чтобы снова погрузиться в мир гигантских скидок и не прерывать вашу экономию на максимум. Не упустите свои привилегии.' : '<b>Включите экономию на максимум.</b><br> Оформите подписку и откройте мир гигантских скидок'
  }
},

mounted(){
  document.title = 'Единая платформа для Нейросетей. Личный кабинет - CognitiveAI'
  const progressBar = document.getElementsByClassName('circle-progress')
  if (typeof progressBar[1] !== 'undefined'){
    //progressBar[1].remove();
  }
  Array.from(progressBar, (elem) => {
    const minValue = elem.getAttribute('data-min-value')
    const maxValue = elem.getAttribute('data-max-value')
    const value = elem.getAttribute('data-value')
    const  type = elem.getAttribute('data-type')

    if (elem.getAttribute('id') !== '' && elem.getAttribute('id') !== null) {
      new CircleProgress('#'+elem.getAttribute('id'), {
      min: minValue,
      max: maxValue,
      value: value,
      textFormat: type,
      });
    }
  })
},
  

}
</script>
<style>
.w210{
  width: 210px;
}
.token{
  max-width: 300px;
  display:inline-block;
  overflow: auto;
}
.token-bt{
  float: right;
}
.cf12{
  color: red !important;
  font-size: 13px;
}
.block-sale{
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  height: 670px;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  background-image: url('/public/images/sale.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.block-sale-text p{
  text-align: center;
  font-size: 28px;
  color: white;
  padding: 25px 30px;
  background-color: rgba(167, 51, 187, 0.9);
  border: 1px rgba(0, 0, 0, 0.3) solid;
  border-radius: 5px;
}
.mt-t10{
  margin-top: 40px;
}
img{
  max-width: 100%;
}
@media (max-width: 960px) {
  .block-sale{
    background-image: none;
    height: auto;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .mobile-home .card-body {padding: 0;}
  .mobile-home .d-flex{display: block;}
  .mobile-home .header-title{margin-bottom: 15px;}
  .msg-list .single-msg{
    max-width: 90%;
  }
  .modal-product-search .btn.btn-primary{
    height: 47px;
  }
}
@media (max-width: 500px) {
  .block-sale-text p{
    font-size: 20px;
  }
 
  .msg-list .single-msg .single-msg-content{
    display: block;
  }

}
</style>