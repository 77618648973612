<template>
  <input type="checkbox" v-bind:checked="modelValue" :value="val" v-on:change="updateInput" />
</template>

<script>
export default {
  name: 'my-checkbox-val',
  props: {
    modelValue: Boolean,
    val: [String, Number]
  },

  methods: {
    updateInput(event){
      this.$emit('update:modelValue', {'status': event.target.checked, 'val': event.target.value})
    }
  }

}
</script>