<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="tariff.message || tariff.messageAutoPay" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ tariff.message }} <span v-html="messAutoPay"></span> </p>
      </div>
      <div v-if="tariff.error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ tariff.error }}</p>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card card-block card-stretch">
            <div class="card-header justify-content-between">
              <div class="header-title">
                <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule">
                  <h4 class="card-title">Подписка
                    <button @click="setModal(true)" v-if="Object.keys(tariff.tariff).length === 0" v-html="buttonLabelHtml" class="btn btn-outline-primary ml-5 mr-2"></button>
                    <button @click="setModal(true)" v-else-if="!tariff.tariff.status" class="btn btn-outline-primary ml-5 mr-2" v-html="buttonLabelHtml"></button>
                    <span class="text-success fo" v-else-if="tariff.tariff.status">активна</span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="card-body p-0">
              <div class="pb-5" v-if="tariff.loading"><loading-small /></div>
              <div v-else class="table-responsive">
                <table class="table table-spacing mb-0">
                  <tbody>
                    <tr class="white-space-no-wrap">
                      <td class="w210">
                        <h6 class="mb-0 text-uppercase text-secondary">План и период</h6>
                      </td>
                      <td class="pl-0 py-3">
                        <my-select-array 
                          class="custom-select wm300"
                          :model-value="tariff.type"
                          @update:model-value="setType"
                          :options="tariff.allType"
                        />
                        <my-select-id
                          class="custom-select wm300 ml-2"
                          :model-value="tariff.paymentPeriod"
                          @update:model-value="setPaymentPeriod"
                          :options="tariff.allPaymentPeriod"
                        />
                        <span class="load-settings" v-if="tariff.loadingSettings"><loading-small-two /></span>
                        <button @click="actionEditSettings" v-else-if="(Object.keys(tariff.tariff).length > 0 && tariff.type !== tariff.tariff.type) || (Object.keys(tariff.tariff).length > 0 && tariff.paymentPeriod !== tariff.tariff.payment_period)" type="button" class="btn btn-outline-success ml-5">Сохранить</button>
                      </td>
                    </tr>
                    <tr v-if="Object.keys(tariff.tariff).length > 0" class="white-space-no-wrap">
                      <td >
                        <h6 class="mb-0 text-uppercase text-secondary">Автоматическое продление</h6>
                      </td>
                      <td class="pl-0 py-3">
                        <div v-if="tariff.loadingAutoExtends"><loading-small-two /></div>
                        <div v-else class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline">
                          <div class="custom-switch-inner">
                            <input type="checkbox" @click="actionAutomaticPayment" class="custom-control-input bg-success" id="customSwitch-2" :checked="tariff.tariff.automatic_payment">
                            <label class="custom-control-label" for="customSwitch-2">
                              <span class="switch-icon-left"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M20 2L6.95024 16.822C6.40984 17.1746 5.69527 16.9862 5.38235 16.4084L2 10.1635" stroke="white" stroke-linecap="round"></path></g></svg></span>
                              <span class="switch-icon-right"><svg width="13" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M4 20L20 4M20 20L4 4" stroke="black" stroke-linecap="round"></path></g></svg></span>
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="Object.keys(tariff.tariff).length > 0" class="white-space-no-wrap">
                      <td >
                        <h6 class="mb-0 text-uppercase text-secondary">Дата окончания</h6>
                      </td>
                      <td class="pl-0 py-3">
                        <div class="text-danger" v-if="!tariff.tariff.status"><b>Истек</b></div>
                        <div v-else v-html="date"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-4 mt-1">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="font-weight-bold ml-3">Подключить свои ключи для интеграции</h4>
          </div>
        </div>
        <div v-if="Object.keys(tariff.tariff).length === 0 || Object.keys(tariff.tariff).length > 0 && !tariff.tariff.status" class="col-lg-12 col-md-12"> 
          <p class="bg-warning-light pl-3 pr-3 pt-2 pb-2 rounded pskdi">Опция доступна при активной подписке.<br>Будут доступны для подключения по своим ключам для интеграции: OpenAI, YandexGPT.
          </p>
          
        </div>
        <div v-else class="col-lg-12 col-md-12 row">
          <div class="col-lg-6 col-md-12">
            <div class="card card-block card-stretch card-height">
                <div class="card-header card-header-border pl-5 pr-5 justify-content-between">
                  <div class="header-title">
                    <div class="row">
                      <div class="col-md-3"><h5 class="card-title">OpenAI </h5></div>
                      <div class="col-md-9">
                        <span v-if="!tariff.tariff.open_ai" class="mb-0 text-danger text-right font-p">
                          <small><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                          <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle></svg>
                          </small>Интеграционные данные не сохранены.
                        </span>
                        <span v-else class="mb-0 text-success text-right font-p">
                            <small><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                            <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                            </small>Интеграционные данные успешно добавлены.
                        </span>
                      </div>
                    </div>
                      
                  </div>
                </div>
                <div class="card-body-list pl-5 pr-5 pt-3 pb-3">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox custom-control-inline">
                      <my-checkbox
                        class="custom-control-input"
                        :disabled="!tariff.tariff.open_ai"
                        id="check1"
                        :model-value="tariff.tariff.open_ai_status"
                        @update:model-value="actionSettingsStatus('OpenAI')"
                      />
                      <label class="custom-control-label" for="check1"><span v-if="tariff.loadingAutoOpenAI"><loading-small-two /></span><span v-else>Использовать свои данные для интеграции</span></label>
                    </div>    
                  </div>          
                  <div class="form-group">
                    <label for="pwd">Введите ваш токен API из учетной записи OpenAI: <my-required-field /></label>
                    <my-input 
                      :model-value="tariff.tokenOpenAI" @update:model-value="setTokenOpenAI"
                      type="text"
                      placeholder="API keys"
                    /> 
                  </div>
                  <div class="form-group">
                    <div class="text-center" v-if="tariff.loadingEditOpenAI"><loading-small/></div>
                    <button v-else @click="actionEditOpenAi" type="button" class="btn btn-outline-success btn-block" :disabled="tariff.tokenOpenAI ? false : true">Сохранить</button>
                  </div>
                </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="card card-block card-stretch card-height">
                <div class="card-header card-header-border pl-5 pr-5 justify-content-between">
                  <div class="header-title">
                    <div class="row">
                      <div class="col-md-4"><h5 class="card-title">YandexGPT</h5></div>
                      <div class="col-md-8">
                        <span v-if="!tariff.tariff.yandex_gpt" class="mb-0 text-danger text-right font-p">
                          <small><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                          <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle></svg>
                          </small>Интеграционные данные не сохранены.
                        </span>
                        <span v-else class="mb-0 text-success text-right font-p">
                            <small><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                            <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                            </small>Интеграционные данные успешно добавлены.
                        </span>
                      </div>
                    </div>
                      
                  </div>
                </div>
                <div class="card-body-list pl-5 pr-5 pt-3 pb-3">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox custom-control-inline">
                      <my-checkbox
                        class="custom-control-input"
                        :disabled="!tariff.tariff.yandex_gpt"
                        id="check2"
                        :model-value="tariff.tariff.yandex_gpt_status"
                        @update:model-value="actionSettingsStatus('YandexGPT')"
                      />
                      <label class="custom-control-label" for="check2"><span v-if="tariff.loadingAutoYandexGPT"><loading-small-two /></span><span v-else>Использовать свои данные для интеграции</span></label>
                    </div>    
                  </div>          
                  <div class="form-group">
                    <label for="pwd">Введите ваш секретный ключ <my-required-field /></label>
                    <my-input 
                      :model-value="tariff.tokenYandexGPT" @update:model-value="setTokenYandexGPT"
                      type="text"
                      placeholder="API KEY"
                    /> 
                  </div>
                  <div class="form-group">
                    <label for="pwd">Идентификатор каталога <my-required-field /></label>
                    <my-input 
                      :model-value="tariff.catalogYandexGPT" @update:model-value="setCatalogYandexGPT"
                      type="text"
                      placeholder="Каталог"
                    /> 
                  </div>
                  <div class="form-group">
                    <div class="text-center" v-if="tariff.loadingEditYandexGPT"><loading-small/></div>
                    <button v-else @click="actionEditYandexGPT" type="button" class="btn btn-outline-success btn-block" :disabled="tariff.tokenYandexGPT && tariff.catalogYandexGPT ? false : true">Сохранить</button>
                  </div>
                </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  <div :class="`modal fade ${tariff.modal ? 'show' : ''}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" :style="`padding-right: 4px; ${tariff.modal ? 'display: block' : 'display: none'}`">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ `${Object.keys(tariff.tariff).length === 0 ? 'Подключить подписку' : 'Продлить подписку'} ${tariff.type}` }}</h5>
          <button @click="setModal(false)" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td v-html="paymentResultPeriodHtml"></td>
                  <td class="text-right">{{ `${tariff.paymentPeriod > 1 ? tariff.notSaleSum : tariff.sum}`}} <i class="fa fa-rub" aria-hidden="true"></i></td>
                </tr>
                <tr v-if="tariff.percent > 1">
                  <td>Скидка</td>
                  <td class="text-right">{{ tariff.percent }}%</td>
                </tr>
                <tr >
                  <td>Оплачиваемый период</td>
                  <td class="text-right" v-html="paymentPeriodHtml"></td>
                </tr>
                <tr>
                  <td><b>Итого к оплате</b></td>
                  <td class="text-right" v-html="paymentResultHtml"></td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
        <div class="modal-footer">
          <button @click="actionCreateTariff" v-if="Object.keys(tariff.tariff).length === 0" type="button" class="btn btn-dark" data-dismiss="modal"><span class="mr-2">Оплатить</span> {{ tariff.endSum }} <i class="fa fa-rub" aria-hidden="true"></i></button>
          <button @click="actionExtendTariff" v-else type="button" class="btn btn-dark" data-dismiss="modal"><span class="mr-2">Оплатить</span> {{ tariff.endSum }} <i class="fa fa-rub" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div :class="`${tariff.modal ? 'modal-backdrop-pers' : ''}`"></div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    Loading
  },
  methods: {
    ...mapMutations({setType: 'tariff/setType', setPaymentPeriod: 'tariff/setPaymentPeriod', setModal: 'tariff/setModal', setTokenOpenAI: 'tariff/setTokenOpenAI', setTokenYandexGPT: 'tariff/setTokenYandexGPT', setCatalogYandexGPT: 'tariff/setCatalogYandexGPT'}),
    ...mapActions({actionGetTariff: 'tariff/actionGetTariff', actionAutomaticPayment: 'tariff/actionAutomaticPayment', actionCreateTariff: 'tariff/actionCreateTariff', actionEditSettings: 'tariff/actionEditSettings', actionSettingsStatus: 'tariff/actionSettingsStatus', actionEditOpenAi: 'tariff/actionEditOpenAi', actionExtendTariff: 'tariff/actionExtendTariff', actionEditYandexGPT: 'tariff/actionEditYandexGPT'}),

    pay(){this.modal = true},
    closePay(){this.modal = false}


  },

  computed: {
    ...mapState({
      tariff: state => state.tariff,
    }),

    date(){
      if(Object.keys(this.tariff.tariff).length > 0 && this.tariff.tariff.end_date){
        const endDate = new Date(this.tariff.tariff.end_date)
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};
        return new Intl.DateTimeFormat('ru-RU', options).format(endDate);
      }
      return 
    },

    messAutoPay(){
      let label = `<span class="text-danger">${this.tariff.messageAutoPay}</span>`
      if(this.tariff.tariff.automatic_payment){
        label = `<span class="text-primary">${this.tariff.messageAutoPay}</span>`
      }
      return label
    },

    paymentResultHtml(){
      let label = '<b>'
      if(this.tariff.notSaleSum > this.tariff.endSum){
        label += `<del class="color-not-sale mr-2"> ${this.tariff.notSaleSum > this.tariff.endSum ? this.tariff.notSaleSum : ''} <i class="fa fa-rub" aria-hidden="true"></i></del>`
      }
      label += ` ${this.tariff.endSum} <i class="fa fa-rub" aria-hidden="true"></i></b>`
      return label
    },

    paymentPeriodHtml(){
      let  label = ''
      if(this.tariff.paymentPeriod === 3){
        label = '3 месяца'
      } else if(this.tariff.paymentPeriod === 6){
        label = '6 месяцев'
      } else if(this.tariff.paymentPeriod === 12) {
        label = '1 год'
      } else {
        label = '1 месяц'
      }
      return label
    },

    paymentResultPeriodHtml(){
      let label = `Цена при оплате за ${this.tariff.paymentPeriod > 1 ? this.tariff.paymentPeriod : ''}`
      if(this.tariff.paymentPeriod == 3){
        label += ' месяца'
      } else if(this.tariff.paymentPeriod === 6 || this.tariff.paymentPeriod === 12){
        label += ' месяцев'
      } else {
        label += ' месяц'
      }
      return label
    },

    buttonLabelHtml() {
      let label = Object.keys(this.tariff.tariff).length === 0 || Object.keys(this.tariff.tariff).length > 0 && this.tariff.tariff.status ? 'Подключить за ' : 'Продлить за '
      if (this.tariff.notSaleSum > this.tariff.endSum) {
        label += `<del class="color-not-sale">${this.tariff.notSaleSum} <i class="fa fa-rub" aria-hidden="true"></i></del>`;
      } 
      label += ` ${this.tariff.endSum} <i class="fa fa-rub" aria-hidden="true"></i> /${this.tariff.paymentPeriod > 1 ? this.tariff.paymentPeriod+' ' : ''}мес.`
      return label;
    }
  },

  mounted(){
    this.actionGetTariff()
    document.title = "Моя подписка - CognitiveAI"
  }
}
</script>

<style>
.h3prof{
  position: relative;
  z-index: 9;
  margin-bottom: 30px;
}
.mess {
  position: fixed;
  z-index: 999;
  top:80px;
  width: auto;
}
.wm300{
  max-width: 200px;
}
.color-not-sale{
  color: rgba(0,0,0,.5);
  font-size: 0.8rem;
}
.modal-backdrop-pers {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}
.fo{
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 20px;
  margin-left: 151px;
}
.load-settings{
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}
.pskdi{
  text-align: center;
  font-size: 17px;
}
.font-p{
  font-size: 16px;
  font-weight: 100;
}
</style>