<template>
  <form enctype="multipart/form-data" @submit.prevent>
    <div class="row">
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">телефон:</label>
        <div class="input-group mb-4">
          <div class="input-group-prepend select-country">
            <div class="input-group-text">
              <div class="custom-control custom-switch custom-switch-color">
                <my-select-id
                  class="custom-select"
                  :model-value="authCountry"
                  @update:model-value="setAuthCountry"
                  :options="country"
                />
              </div>
            </div>
          </div>
          <my-input
          :model-value="newPhone" @update:model-value="setNewPhone"
          type="tel"
          :placeholder="authPlaceholder"
          v-mask="authMask"
        />
        </div>
        <div v-if="statusCode">
          <div class="col-lg-12 mt-0">
            <div class="alert alert-primary al-svg">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
              </svg>
              <div v-if="authCountry === 'RU'" class="iq-alert-text"> Для подтверждения кода введите последние четыре цифры номера, с которого поступит звонок.</div>
              <div v-else class="iq-alert-text"> Ответьте на звонок, робот произнесёт код, который нужно ввести в поле для подтверждения.</div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label class="text-secondary">Введите код подтверждения:</label>
              <my-input
                id="four-digits"
                :model-value="authCode" @update:model-value="setAuthCode"
                type="text"
                placeholder="____"
                v-mask="'####'"
                required
              />
            </div>
          </div>
          <div class="col-lg-12 mt-2">
            <div class="mb-3">
              <div class="text-secondary text-12" v-if="secure">Запросить повторный код можно через <span id="countdown">01:00</span> секунд</div>
              <button class="btn mt-2 mr-1 btn-outline-primary btn-sm" v-else @click="actionResendCodePhone">Выслать код еще раз</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="!statusCode" type="button" class="btn mt-2 mr-1 btn-outline-success" @click="start">Получить код</button>
    <my-button v-else type="submit" class="btn-primary mt-2" @click="actionEditPhone">Подтвердить</my-button>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mask} from 'vue-the-mask'
export default {
  directives: {mask},
  data() {
    return {
      timeleft: 59,
      downloadTimer: null,
      secure: true
    }
  },
  name: 'my-profile-phone',
  methods: {
    ...mapMutations({
      setNewPhone: 'setNewPhone', setAuthCountry: 'setAuthCountry', setAuthCode: 'setAuthCode'}),

    ...mapActions({actionEditPhone: 'actionEditPhone', actionResendCodePhone: 'actionResendCodePhone'}),

    startCountdown() {
      this.downloadTimer = setInterval(() => {
        if (this.timeleft <= 0) {
          clearInterval(this.downloadTimer);
          this.secure = false;
        } else {
          const countdownElement = document.getElementById("countdown");
          if (countdownElement) {
            countdownElement.innerHTML = `00:${this.timeleft < 10 ? '0' : ''}` + this.timeleft;
          }
        }
        this.timeleft -= 1;
      }, 1000);
    },

    start(){
      this.actionResendCodePhone()
      this.startCountdown();
    },

    restartCountdown() {
      this.actionResendCode();
      clearInterval(this.downloadTimer);
      this.timeleft = 59;
      this.secure = true;
      this.startCountdown();
      document.getElementById("countdown").innerHTML = "01:00";
    }
    
  },
  computed: {
    ...mapState({
      statusCode: state => state.statusCode,
      authCode: state => state.authCode,
      newPhone: state => state.newPhone,
      authCountry: state => state.authCountry,
      country: state => state.country,
      authMask: state => state.authMask,
      authPlaceholder: state => state.authPlaceholder,
      errors: state => state.errors,
    }),

  },

  watch: {
    secure(newValue) {
      if (!newValue) {
        this.secure = false;
      }
    }
  }
}
</script>