<template>
  <textarea :value="modelValue" @input="updateTextarea" class="form-control textarea-line-height" rows="3">
    <slot></slot>
  </textarea>
</template>

<script>
export default {
  name: 'my-textarea-array',
  props: {
    modelValue: [String, Number],
    id: [String, Number]
  },

  methods: {
    updateTextarea(event){
      this.$emit('update:modelValue', {id: this.id, name: event.target.value}) 
    }
  }

}
</script>
<style>
.textarea-line-height{
  line-height: 20px;
}
</style>