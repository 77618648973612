<template>
  <form @submit.prevent>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <div v-if="errors.length">
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors" :key="error">{{error}}</p>
          </div>
          
          <label class="text-secondary">Телефон</label>
          <div class="input-group mb-4">
            <div class="input-group-prepend select-country">
              <div class="input-group-text">
                <div class="custom-control custom-switch custom-switch-color">
                  <my-select-id
                    class="custom-select"
                    :model-value="authCountry"
                    @update:model-value="setAuthCountry"
                    :options="country"
                  />
                </div>
              </div>
            </div>
            <my-input 
              :model-value="authPhone" @update:model-value="setAuthPhone"
              type="tel"
              required
              :placeholder="authPlaceholder"
              v-mask="authMask"
            />
        </div>
        </div>
      </div>
    </div>
    <my-button type="submit" class="btn-primary btn-block mt-2" @click="actionResendCode">Войти</my-button>
    <div class="col-lg-12 mt-3">
      <p class="mb-0 text-center">Нет аккаунта? <router-link to="/registration">Регистрация</router-link></p>
    </div>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mask} from 'vue-the-mask'
export default {
  directives: {mask},
  name: 'login-form',
  methods: {
    ...mapMutations(['setAuthPhone', 'setErrors', 'setAuthCountry']),

    ...mapActions(['actionResendCode']),  
  },
  computed: {
    ...mapState({
      authPhone: state => state.authPhone,
      authCountry: state => state.authCountry,
      country: state => state.country,
      authMask: state => state.authMask,
      authPlaceholder: state => state.authPlaceholder,
      errors: state => state.errors
    }),
  },

  unmounted(){
    this.setErrors('')
  }
}
</script>

<style>
  .select-country .input-group-text{
    padding: 0;
  }
  .select-country .custom-switch, .select-country .custom-control{
    padding: 0;
  }
  .select-country .custom-select{
    border: none;
    box-shadow:none
  }
</style>