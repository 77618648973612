import axios from 'axios'
axios.defaults.withCredentials = true;

const url = 'https://cognitive-ai.ru/'
// 'https://cognitive-ai.ru/'
// 'http://localhost:11000/'

const instals = () => {
  return axios.create({
    withCredentials: true,
    baseURL: url
  })
}

const instalsAuth = (token) => {
  return axios.create({
    withCredentials: true,
    baseURL: url,
    headers: { Authorization: `Bearer ${token}` },
  })
}

const instalsAuthFile = (token) => {
  return axios.create({
    withCredentials: true,
    baseURL: url,
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const axiosReg = async (data) => {
  const response = await instals().post('auth/registration', data)
  return response.data
}

export const axiosLogin = async (data) => {
  const response = await instals().post('auth/login', data)
  return response.data
}

export const axiosResendCode = async (phone,country) => {
  const response = await instals().post(`auth/resend-code/${phone}/${country}`)
  return response.data
}

export const axiosLogout = async () => {
  const response = await instals().post('auth/logout')
  return response.data
}

export const axiosAuth = async () => {
  const response = await instals().get('auth/refresh')
  return response.data
}

export const axiosUser = async (token) => {
  const response = await instalsAuth(token).get('users/user')
  return response.data
}

// Редактирование пользователя

export const axiosEditUser = async (token, data, file) => {
  let formData = new FormData();
  formData.append('files', file);
  formData.append('name', data.name);
  formData.append('country', data.country);
  formData.append('phone', data.phone);
  formData.append('email', data.email);
  const response = await instalsAuthFile(token).put('users/edit', formData)
  return response.data
}

export const axiosEditPhone = async (token, code) => {
  const response = await instalsAuth(token).put(`users/edit-phone/${code}`)
  return response.data
}

export const axiosResendCodePhone = async (token, phone, country) => {
  const response = await instalsAuth(token).post(`users/resend-code-phone/${phone}/${country}`)
  return response.data
}

export const axiosRequisites = async (token, data) => {
  const response = await instalsAuth(token).post('users/requisites-yur', data)
  return response.data
}

// Баланс история

export const axiosTopUpBalance = async (token, data) => {
  const response = await instalsAuth(token).post('users/top-up-balance', data)
  return response.data
}

export const axiosGetOrders = async (token) => {
  const response = await instalsAuth(token).get('users/get-orders')
  return response.data
}

export const axiosGetBalanceHistory = async (token) => {
  const response = await instalsAuth(token).get('users/get-balance-history')
  return response.data
}

// Автоматическая оплата
export const axiosTemporaryPayment = async (token, param) => {
  const response = await instalsAuth(token).put(`users/automatic-payment/${param}`)
  return response.data
}
export const axiosTemporaryPeriod = async (token, param) => {
  const response = await instalsAuth(token).put(`users/automatic-period/${param}`)
  return response.data
}

// Нейросеть

export const axiosAiContext = async (token, data) => {
  const response = await instalsAuth(token).post('neurnetwork/context-ai', data)
  return response.data
}

export const axiosAiImage = async (token, data) => {
  const response = await instalsAuth(token).post('neurnetwork/image-ai', data)
  return response.data
}

export const axiosAiRewriteText = async (token, data) => {
  const response = await instalsAuth(token).post('neurnetwork/rewrite', data)
  return response.data
}

export const axiosAiTextList = async (token, page) => {
  const response = await instalsAuth(token).get(`neurnetwork/get/${page}`)
  return response.data
}

export const axiosGetModelAi = async (token, param) => {
  const response = await instalsAuth(token).get(`neurnetwork/get-model-ai/${param}`)
  return response.data
}

export const axiosCreateAiToken = async (token) => {
  const response = await instalsAuth(token).post(`users/create-token-neurnetwork/`)
  return response.data
}

export const axiosGetChatNameLimit = async (token, nameAI, page, limit) => {
  const response = await instalsAuth(token).get(`neurnetwork/get-name-chat/${nameAI}/${page}/${limit}`)
  return response.data
}

export const axiosGetImageList = async (token, nameAI, page, limit) => {
  const response = await instalsAuth(token).get(`neurnetwork/get-name-images/${nameAI}/${page}/${limit}`)
  return response.data
}

export const axiosChooseChat = async (token, nameAi, id) => {
  const response = await instalsAuth(token).get(`neurnetwork/get-id-chat/${nameAi}/${id}`)
  return response.data
}

export const axiosSystemList = async (token, page, limit) => {
  const response = await instalsAuth(token).get(`neurnetwork/get-system/${page}/${limit}`)
  return response.data
}

export const axiosRemoveSystemList = async (token, id) => {
  const response = await instalsAuth(token).delete(`neurnetwork/remove-system/${id}`)
  return response.data
}

export const axiosGetListApiDomain = async (token) => {
  const response = await instalsAuth(token).get(`users/get-domains/`)
  return response.data
}

export const axiosCreateApiDomain = async (token, data) => {
  const response = await instalsAuth(token).post(`users/add-domain`, data)
  return response.data
}

export const axiosRemoveApiDomain = async (token, id) => {
  const response = await instalsAuth(token).delete(`users/remove-domain/${id}`)
  return response.data
}

// Промпты

export const axiosGetPromptSystem = async (token) => {
  const response = await instalsAuth(token).get(`prompt/get-prompt-system`)
  return response.data
}

export const axiosEditPrompt = async (token, data) => {
  const response = await instalsAuth(token).put(`prompt/edit-prompt-user`, data)
  return response.data
}

export const axiosAddPrompt = async (token, data) => {
  const response = await instalsAuth(token).post(`prompt/create-prompt-user`, data)
  return response.data
}

// Партнеры

export const axiosCreatePartner = async (token, data) => {
  const response = await instalsAuth(token).post(`partners/create`, data)
  return response.data
}

export const axiosPromocodeList = async (token) => {
  const response = await instalsAuth(token).get(`partners/get-promocodes`)
  return response.data
}

export const axiosCreatePromocode = async (token, data) => {
  const response = await instalsAuth(token).post(`partners/create-promocode`, data)
  return response.data
}

export const axiosStatusPromocode = async (token, param) => {
  const response = await instalsAuth(token).put(`partners/edit-status-promocode/${param}`)
  return response.data
}

export const axiosRemovePromocode = async (token, param) => {
  const response = await instalsAuth(token).delete(`partners/remove-promocode/${param}`)
  return response.data
}

export const axiosReferralsList = async (token) => {
  const response = await instalsAuth(token).get(`partners/get-referrals`)
  return response.data
}

export const axiosPartnerRequestPayments = async (token, param) => {
  const response = await instalsAuth(token).put(`partners/request-payments/${param}`)
  return response.data
}

export const axiosPartnerAccrualList = async (token, id, page, limit) => {
  const response = await instalsAuth(token).get(`partners/get-referrals-accruals/${id}/${page}/${limit}`)
  return response.data
}

export const axiosPartnerReferralPaymentsList = async (token, id, page, limit) => {
  const response = await instalsAuth(token).get(`partners/get-referrals-action/${id}/${page}/${limit}`)
  return response.data
}

export const axiosEditPartnerPaymentDetails = async (token, data) => {
  const response = await instalsAuth(token).put(`partners/edit-payment-details`, data)
  return response.data
}

export const axiosGetPartnerPaymentDetails = async (token) => {
  const response = await instalsAuth(token).get(`partners/get-payment-details`)
  return response.data
}

//Тариф

export const axiosGetTariff = async (token) => {
  const response = await instalsAuth(token).get(`tariff/get`)
  return response.data
}

export const axiosCreateTariff = async (token, data) => {
  const response = await instalsAuth(token).post(`tariff/create`, data)
  return response.data
}

export const axiosExtendTariff = async (token, data) => {
  const response = await instalsAuth(token).put(`tariff/extend`, data)
  return response.data
}

export const axiosAutomaticPayment = async (token) => {
  const response = await instalsAuth(token).put(`tariff/edit-automatic-payment`)
  return response.data
}

export const axiosEditSettings = async (token, data) => {
  const response = await instalsAuth(token).put(`tariff/edit-settings`, data)
  return response.data
}

export const axiosSettingsStatus = async (token, param) => {
  const response = await instalsAuth(token).put(`tariff/edit-status/${param}`)
  return response.data
}

export const axiosEditOpenAi = async (token, data) => {
  const response = await instalsAuth(token).put(`tariff/edit-openai`, data)
  return response.data
}

export const axiosEditYandexGPT = async (token, data) => {
  const response = await instalsAuth(token).put(`tariff/edit-yandexgpt`, data)
  return response.data
}

// Виджеты чата для сайтов

export const axiosGetWidget = async (token, page, limit) => {
  const response = await instalsAuth(token).get(`chatwidget/get/${page}/${limit}`)
  return response.data
}

export const axiosCreateWidget = async (token, data) => {
  const response = await instalsAuth(token).post(`chatwidget/create`, data)
  return response.data
}

export const axiosEditStrWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`chatwidget/edit-str`, data)
  return response.data
}

export const axiosEditStatusWidget = async (token, param) => {
  const response = await instalsAuth(token).put(`chatwidget/edit-status/${param}`)
  return response.data
}

export const axiosEditAiDataWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`chatwidget/edit-ai-data`, data)
  return response.data
}

export const axiosRemoveWidget = async (token, param) => {
  const response = await instalsAuth(token).delete(`chatwidget/remove/${param}`)
  return response.data
}

export const axiosStyleWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`chatwidget/edit-style-css`, data)
  return response.data
}

// Виджет техподдержки для сайта
export const axiosCreateSupportWidget = async (token, data) => {
  const response = await instalsAuth(token).post(`supportwidget/create-widget`, data)
  return response.data
}

export const axiosGetSupportWidget = async (token, page, limit) => {
  const response = await instalsAuth(token).get(`supportwidget/get-wedget/${page}/${limit}`)
  return response.data
}

export const axiosRemoveSupportWidget = async (token, param) => {
  const response = await instalsAuth(token).delete(`supportwidget/remove-widget/${param}`)
  return response.data
}

export const axiosEditStrSupportWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`supportwidget/edit-str-widget`, data)
  return response.data
}

export const axiosChooseAiSupportWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`supportwidget/edit-ai-widget`, data)
  return response.data
}

export const axiosBoolSupportWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`supportwidget/edit-bool-widget/${data.id}/${data.value}`, data)
  return response.data
}

export const axiosEditSectionDataSupportWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`supportwidget/edit-data-widget`, data)
  return response.data
}

// базы знаний
export const axiosGetNameSupportWidget = async (token) => {
  const response = await instalsAuth(token).get(`supportwidget/get-name-widget`)
  return response.data
}

export const axiosGetInfoBaseSupportWidget = async (token, id) => {
  const response = await instalsAuth(token).get(`supportwidget/get-info-base/${id}`)
  return response.data
}

export const axiosCreateInfoBaseSupportWidget = async (token, param) => {
  const response = await instalsAuth(token).post(`supportwidget/create-info-base/${param}`)
  return response.data
}

export const axiosRemoveInfoBaseSupportWidget = async (token, id, id_support_widget) => {
  const response = await instalsAuth(token).delete(`supportwidget/remove-info-base/${id}/${id_support_widget}`)
  return response.data
}

export const axiosEditInfoBaseSupportWidget = async (token, data) => {
  const response = await instalsAuth(token).put(`supportwidget/edit-info-base`, data)
  return response.data
}

export const axiosGetDialogsSupportWidget = async (token, id, page, limit) => {
  const response = await instalsAuth(token).get(`supportwidget/get-dialogs/${id}/${page}/${limit}`)
  return response.data
}

export const axiosGetContactsSupportWidget = async (token, page, limit) => {
  const response = await instalsAuth(token).get(`supportwidget/get-contacts-users/${page}/${limit}`)
  return response.data
}

export const axiosRemoveDialogsSupportWidget = async (token, id, id_support_widget) => {
  const response = await instalsAuth(token).delete(`supportwidget/remove-dialogs-support-widget/${id}/${id_support_widget}`)
  return response.data
}


