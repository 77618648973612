<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="widget.message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ widget.message }} </p>
      </div>
      <div v-if="widget.error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ widget.error }}</p>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Виджеты чата для сайта</h4>
            </div>
            <div class="create-workform">
              <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div class="modal-product-search d-flex">
                  <div class="btn-group btn-group-toggle btn-group-sm btn-group-edges mr-5 model-ai-preb"> 
                    <my-input
                    :model-value="widget.domain" @update:model-value="setDomain"
                    type="text"
                    required
                    placeholder="Введите ваш домен, например: domain.ru "
                    />
                  </div>
                  <button @click="actionCreateWidget" class="btn btn-primary position-relative d-flex align-items-center justify-content-between mch">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>Создать виджет
                  </button>
                </div>
              </div>
            </div> 
          </div>
          
          <div class="pb-5" v-if="widget.loading"><loading-small /></div>
          <div v-else v-for="w in widget.widgetList" :key="w.id" class="card card-block card-stretch">
            <div class="card-header justify-content-between widget-block">
              <div class="header-title widget-head-block">
                <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule">
                  <h4 @click="toggleContent(w.id)" class="card-title text-primary">{{ w.domain }}
                    <span v-if="widget.activeIndex === w.id">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" /></svg>
                    </span>
                    <span v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg>
                    </span>
                  </h4>
                </div>
                <div class="widget-block-right">
                  <button @click="openModal(w.id)" class="badge bg-danger" title="Удалить" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <transition name="slide-fade">
              <div class="card-body p-0 widget-content" v-show="widget.activeIndex === w.id">
                <div class="table-responsive">
                  <table class="table table-spacing mb-0">
                    <tbody>
                      <tr>
                        <td class="w210 white-space-no-wrap">
                          <h6 class="mb-0 text-uppercase text-secondary">Настройки ограничений</h6>
                        </td>
                        <td class="pl-0 py-3">
                          <div class="row">
                            <div class="form-group col-12 col-sm-4">
                              <label>Дневной лимит (₽) <my-required-field /></label>
                              <my-input-array
                                type="number"
                                :model-value="w.max_amt.split('.')[0]"
                                :id="w.id"
                                @update:model-value="setMaxAtm"
                              />
                            </div>
                            <div class="form-group col-12 col-sm-4">
                              <label>Проверочное слово <my-required-field /><my-required-field /></label>
                              <my-input-array
                                class="ml-2"
                                :model-value="w.secret_word"
                                :id="w.id"
                                @update:model-value="setSecretWord"
                                placeholder="Например: Мираж"
                              />
                            </div>
                            <div class="form-group col-12 col-sm-4">
                              <button @click="actionEditStrWidget(w.id)" type="button" class="btn btn-outline-success mt-4">Сохранить</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td >
                          <h6 class="mb-0 text-uppercase text-secondary">Статус</h6>
                        </td>
                        <td class="pl-0 py-3">
                          <div  class="custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline">
                            <div class="custom-switch-inner">
                              <input type="checkbox" @click="actionEditStatusWidget(w.id)" class="custom-control-input bg-success" :id="`customSwitch-${w.id}`" :checked="w.status">
                              <label class="custom-control-label" :for="`customSwitch-${w.id}`">
                                <span class="switch-icon-left"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M20 2L6.95024 16.822C6.40984 17.1746 5.69527 16.9862 5.38235 16.4084L2 10.1635" stroke="white" stroke-linecap="round"></path></g></svg></span>
                                <span class="switch-icon-right"><svg width="13" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path id="Polygon 13" d="M4 20L20 4M20 20L4 4" stroke="black" stroke-linecap="round"></path></g></svg></span>
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <h6 class="mb-0 text-uppercase text-secondary">Выберите нейронные сети</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <div class="mb-1" v-for="ai in aiList" :key="ai.name">
                            <div class="ml-3">
                              <div class="mt-2 custom-control custom-checkbox custom-control-inline" v-for="model in ai.data" :key="model.model">
                                <input @click="actionEditAiDataWidget({id: w.id, nameAi: ai.name, modelAi: model.model})" type="checkbox" class="custom-control-input" :id="`supplierCheck-${model.model}-${w.id}`" :checked="ai.name in w.name_ai_data && w.name_ai_data[ai.name].some(m => m.model === model.model)">
                                <label class="custom-control-label" :for="`supplierCheck-${model.model}-${w.id}`">{{ model.model }} <span class="small text-warning">({{ ai.name }})</span></label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <h6 class="mb-0 text-uppercase text-secondary">ID виджета</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          {{ w.code }}
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <h6 class="mb-0 text-uppercase text-secondary">Код виджета</h6>
                        </td>
                        <td class="pl-0 py-3 checkbox-ai-table">
                          <p class="small text-secondary mt-2">
                            Вставьте данный код виджета между тегами `&lt;body&gt;` в файл шаблона административного интерфейса или в другой соответствующий файл, который управляет подключением скриптов и виджетов в административной панели вашего сайта.<br>Помните, что размещение кода за пределами административной зоны сделает виджет доступным для всех пользователей вашего сайта.</p>
                          <kbd class="bg-dark"><pre id="form-grid" class="text-white"><code>
&lt;script id="cognitiveaiChatWidgetId" src="https://widget.cognitiveai.ru/chat/widgets.js?widgetId={{ w.code }}" async&gt;&lt;/script&gt;</code></pre></kbd>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="!widget.loading && widget.length > widget.limit" class="col-lg-12">
          <pagination :pages="Math.ceil(widget.length / widget.limit)" :model-value="widget.page" @update:model-value="editPage"/>
        </div>
        <div v-if="widget.widgetList.length > 0" class="col-sm-12 col-lg-12">
          <div class="alert alert-primary" role="alert">
            <div class="iq-alert-text">
                <div class="mb-2"><my-required-field /> "Дневной лимит" - предотвращает превышение установленной вами суммы расходов с вашего аккаунта в течение дня.</div>
                <div class="mb-2"> <my-required-field /><my-required-field /> "Проверочное слово" - обеспечивает дополнительную безопасность в виджете чата с нейронными сетями. При указании любого проверочного слова, в интерфейсе чата виджета, появится новое поле для ввода проверочного слова. Для каждого обращения к нейронной сети вами, другими администраторами, а также пользователями вашего сайта если виджет будет размещен в открытом месте для всех пользователей сайта, необходимо будет вводить данное слово. Эта функция гарантирует, что доступ к нейронной сети на вашем сайте имеют только лица, знающие проверочное слово. Если вы не желаете использовать проверочное слово, оставьте в настройках виджета данное поле пустым. </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="`modal fade ${widget.modal ? 'show' : ''}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" :style="`padding-right: 4px; ${widget.modal ? 'display: block' : 'display: none'}`">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5>Вы уверены, что хотите удалить виджет?</h5>
          <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-footer">
          <button @click="actionRemoveWidget" type="button" class="btn btn-danger">Да</button>
          <button @click="closeModal" type="button" class="btn btn-secondary">Отмена</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="`${widget.modal ? 'modal-backdrop-pers' : ''}`"></div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    Loading
  },
  methods: {
    ...mapMutations({setWidgetList: 'chatWidget/setWidgetList', setPage: 'chatWidget/setPage', setLength: 'chatWidget/setLength', setDomain: 'chatWidget/setDomain', setMaxAtm: 'chatWidget/setMaxAtm', setSecretWord: 'chatWidget/setSecretWord', setModal: 'chatWidget/setModal', setTemporaryId: 'chatWidget/setTemporaryId', setActiveIndex: 'chatWidget/setActiveIndex'}),
    ...mapActions({actionGetWidget: 'chatWidget/actionGetWidget', actionCreateWidget: 'chatWidget/actionCreateWidget', actionEditStrWidget: 'chatWidget/actionEditStrWidget', actionEditStatusWidget: 'chatWidget/actionEditStatusWidget', actionEditAiDataWidget: 'chatWidget/actionEditAiDataWidget', actionRemoveWidget: 'chatWidget/actionRemoveWidget'}),

    openModal(param){
      this.setModal(true)
      this.setTemporaryId(param)
    },
    closeModal(){
      this.setModal(false)
      this.setTemporaryId(0)
    },
    editPage(e){
      this.setPage(e)
      this.actionGetWidget()
    },
    toggleContent(index) {
      this.setActiveIndex(this.widget.activeIndex === index ? null : index)
    }
  },

  computed: {
    ...mapState({
      widget: state => state.chatWidget,
      aiList: state => [...state.ai.modelAiList, ...state.ai.modelAiImagesList]
    }),
  },

  mounted(){
    this.actionGetWidget()
    document.title = "Виджеты чата для сайта - CognitiveAI"
  },

  unmounted(){
    this.setWidgetList([])
  }
}
</script>

<style>
table .checkbox-ai-table .custom-checkbox .custom-control-label:after, table .checkbox-ai-table .custom-checkbox .custom-control-label:before, table .checkbox-ai-table .custom-radio .custom-control-label:after, table .checkbox-ai-table .custom-radio .custom-control-label:before{
  top: 0.1rem;
}
.fl-model{display: flex;}
.widget-head-block{
  display: flex !important;
  justify-content: space-between;
}
button.bg-danger{border: none;}
.widget-block-left{width: 30px;}
.bg-dark{display: block;}
.widget-block h4{cursor: pointer;}
.widget-content {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.slide-fade-enter-active {
  max-height: 0;
}
.slide-fade-enter-to {
  max-height: 500px; /* Установите максимальную возможную высоту содержимого */
}
.slide-fade-leave-active {
  max-height: 500px; /* Установите максимальную возможную высоту содержимого */
}
.slide-fade-leave-to {
  max-height: 0;
}


</style>