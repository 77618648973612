<template>
  <div v-if="loading">
    <loading />
  </div>
  <section class="login-content">
    <div class="container h-100">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-md-5">
          <div class="card p-3">
            <div class="card-body">
              
              <div class="auth-logo">
                <a href="https://cognitiveai.ru/"><img src="/images/96.png" class="img-fluid rounded-normal light-logo" alt="logo"></a>
              </div>
              <h3 class="mb-3 font-weight-bold text-center">Зарегистрируйтесь</h3>
              <div class="mb-5">
                <p class="line-around text-secondary mb-0"><span class="line-around-1">по номеру телефона</span></p>
              </div>
              <div v-if="statusCode"><valid-login-form /></div>
              <div v-else><registration-form /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import RegistrationForm from '@/components/RegistrationForm'
import ValidLoginForm from '@//components/ValidLoginForm'
import Loading from '@/components/Loading'
export default {
  components: {
    RegistrationForm,
    Loading,
    ValidLoginForm
  },

  methods: {
    ...mapActions({actionAuthorization: 'actionAuthorization'})
  },

  computed: {
    ...mapState({
      loading: state => state.loading,
      statusCode: state => state.statusCode

    }),
  },

  mounted(){
    document.title = 'Регистрация - CognitiveAI'
    this.actionAuthorization()
  }
}
</script>