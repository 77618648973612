<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Мои изображения</h4>
                </div>
                <div class="create-workform">
                  <div class="d-flex flex-wrap align-items-center justify-content-between">
                   
                    <div class="modal-product-search d-flex">
                      <div class="btn-group btn-group-toggle btn-group-sm btn-group-edges mr-5 model-ai-preb"> 
                        <span @click="selectNameAiImage('Kandinsky')" :class="`button btn button-icon ${listNameAiImage === 'Kandinsky' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">Kandinsky</span>
                        <span @click="selectNameAiImage('DALL-E')" :class="`button btn button-icon ${listNameAiImage === 'DALL-E' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">DALL-E</span>
                        <span @click="selectNameAiImage('Midjourney')" :class="`button btn button-icon ${listNameAiImage === 'Midjourney' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">Midjourney</span>
                      </div>
                      <router-link to="/create-image" class="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                        <svg width="20px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                        </svg>Сгенерировать изображение
                      </router-link>
                    </div>
                  </div>
                </div> 
              </div>
              <div v-if="loading" class="card-body"><loading-small /></div>
              <div v-else class="row">
                
                <div class="col-md-6" v-for="chat in imageList" :key="chat.id">

                  <div class="card">
                    <div class="card-body">
                      <div class="">
                        <div class="mb-2">{{ chat.name }}</div>
                        <img :src="`https://cognitiveai.hb.bizmrg.com/${chat.url}.jpg`" width="500">
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div v-if="!loading && length > limit" class="col-lg-12">
              <pagination :pages="Math.ceil(length / limit)" :model-value="page" @update:model-value="editPage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import TextList from '@/components/TextList'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    TextList,
  },
  methods: {
    ...mapMutations({setImageList: 'ai/setImageList', setPage: 'ai/setPage', setLength: 'ai/setLength', setListNameAiImage: 'ai/setListNameAiImage'}),
    ...mapActions({actionGetImageList: 'ai/actionGetImageList'}),

    editPage(e){
      this.setPage(e)
      this.actionGetImageList()
    },

    getImageSrc(binaryData) {
      console.log(binaryData.toString('base64'))
      return `data:image/jpeg;base64,${binaryData.toString('base64')}`;
    },

    selectNameAiImage(param){
      this.setPage(1)
      this.setLength(0)
      this.setListNameAiImage(param)
      this.actionGetImageList();
    },
  },

  computed: {

    ...mapState({
      imageList: state => state.ai.imageList,
      listNameAiImage: state => state.ai.listNameAiImage,
      loading: state => state.ai.loading,
      limit: state => state.ai.limitImg,
      page: state => state.ai.page,
      length: state => state.ai.length
    }),
  },

  mounted(){
    this.actionGetImageList();
    document.title = 'Мои диалоги - CognitiveAI"'
  },

  unmounted(){
    this.setImageList([])
    this.setPage(1)
    this.setLength(0)
  }
}
</script>

<style>
  .chat-choose{
    cursor: pointer;
  }
  .chat-choose:hover{
    background-color: rgba(51,120,255,.2);
  }
  .model-ai-preb span {
    padding: 9px 25px 5px 25px !important;
  }

</style>