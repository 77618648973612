import { axiosAiContext, axiosAiRewriteText, axiosAiTextList, axiosCreateAiToken, axiosGetPromptSystem, axiosGetModelAi, axiosGetChatNameLimit, axiosChooseChat, axiosEditPrompt, axiosAddPrompt, axiosGetImageList, axiosAiImage, axiosSystemList, axiosRemoveSystemList, axiosGetListApiDomain, axiosCreateApiDomain, axiosRemoveApiDomain } from '@/api/index'

export const aiModule = {
  state: () => ({
    loading: false,
    loadingChatHome: false,
    loadingImageHome: false,
    error: '',
    textList: [],
    systemList: [],
    chatList: [],
    imageList: [],
    chatListLimit: [],
    chatListHome: [],
    imageListHome: [],
    promptSystemList: [],
    modelAiList: [],
    modelAiImagesList: [],
    mode: 'create',
    modeList: [{id: 'create', name: 'Создать изображение'}, {id: 'blend', name: 'Объединить изображения в одно'}],
    imagesUrl: null,
    resultImage: null,
    qualityList: ['standard'],
    quality: 'standard',
    styleList: [{id: 'NO', name: 'Свой стиль'},{id: 'UHD', name: 'Детальное фото'},{id: 'ANIME', name: 'Аниме'}],
    style: 'NO',
    sizeList: [],
    size: null,
    mySize: null,
    chatListNameAI: 'GPT-3.5',
    listNameAiImage: 'Kandinsky',
    readyPrompt: null,
    nameAi: null,
    modelAi: null,
    nameAiImage: null,
    modelAiImage: null,
    prompt: null,
    promptChat: null,
    context: null,
    chatData: [],
    chat: false,
    chatId: 0,
    promptSave: null,
    temp: 7,
    roleSystem: null,
    maxToken: 1000,
    limitToken: 0,
    amountDebited: 0,
    enterTokens: 0,
    exitTokens: 0,
    promptType: ['Все', 'Бизнесу', 'Студенту', 'Копирайтеру', 'Маркетологу'],
    promptUser: 'Все',
    promptTypeAi: 'Все',
    rewriteText: {
      textResult: '',
      text: '',
      keywords: '',
      creative: 1,
      strong: false
    },
    temporary: {
      id: 0,
      data: {}
    },
    show: false,
    edit: {
      id: 0,
      name: null,
      text: null
    },
    page: 1,
    length: 0,
    limit: 50,
    limitImg: 10,
    domainList: [],
    newDomain: null,
    loadingDomain: false
    
  }),

  getters: {
    sortTypeUser(state, getters, rootState){
      let promptList = [...rootState.user.prompt, ...state.promptSystemList]
      if (state.promptUser === 'Пользовательские'){
        promptList = promptList.filter(p => p.type_user === 'user')
      } else if (state.promptUser === 'Системные'){
        promptList = promptList.filter(p => p.type_user === 'admin')
      }
      return promptList.map(({ text, type_user, type_ai }) => ({ text, type_user, type_ai }))
    },

    sortTypeAi(state, getters){
      return state.promptTypeAi === 'Все' ?  getters.sortTypeUser : state.promptUser === 'Пользовательские' ? getters.sortTypeUser :  getters.sortTypeUser.filter(p => p.type_ai == state.promptTypeAi)
    },

    modelAi(state){
      return state.modelAiList.find(({name}) => name === state.nameAi).data.map(({model}) => model)
    },

    modelImageAi(state){
      return state.modelAiImagesList.find(({name}) => name === state.nameAiImage).data.map(({model}) => model)
    }
  },

  mutations: {
    setLoadingChatHome(state, v){state.loadingChatHome = v},
    setLoadingImageHome(state, v){state.loadingImageHome = v},
    setShow(state, v){state.show = v},
    setEditId(state, v){state.edit.id = v},
    setEditName(state, v){state.edit.name = v},
    setEditText(state, v){state.edit.text = v},
    setLoading(state, v){state.loading = v},
    setError(state, v){state.error = v},
    setPromptSystemList(state, v){state.promptSystemList = v},
    setChatListNameAI(state, v){state.chatListNameAI = v},
    setListNameAiImage(state, v){state.listNameAiImage = v},
    setResultImage(state, v){state.resultImage = v},
    setMode(state, v){state.mode = v},
    setImagesUrl(state, v){state.imagesUrl = v},
    setMySize(state, v){state.mySize = v},
    setQualityList(state, v){state.qualityList = v},
    setQuality(state, v){state.quality = v
      const data = state.modelAiImagesList.find(({name}) => name === state.nameAiImage).data.find(({model}) => model === state.modelAiImage)
      state.sizeList = data.format[v]
      state.size = data.format[v][0]
    },
    setStyle(state, v){state.style = v},
    setSizeList(state, v){state.sizeList = v},
    setSize(state, v){state.size = v},
    setModelAiList(state, v){
      state.modelAiList = v
      if(v.length){
        state.nameAi = v[0].name
        state.modelAi = v[0].data[0].model
      }
    },
    setModelAiImagesList(state, v){
      state.modelAiImagesList = v
      if(v.length){
        state.nameAiImage = v[0].name
        state.modelAiImage = v[0].data[0].model
      }
    },
    setPrompt(state, v){state.prompt = v},
    setPromptChat(state, v){state.promptChat = v},
    setPromptUser(state, v){state.promptUser = v; state.promptTypeAi = 'Все'},
    setPromptTypeAi(state, v){state.promptTypeAi = v},
    setNameAi(state, v){state.nameAi = v
      state.modelAi = state.modelAiList.find(({name}) => name === v).data[0].model
      const limitToken = state.modelAiList.find(({name}) => name === v).data[0].maxTokens
      state.limitToken = limitToken
      if(state.maxToken > limitToken){
        state.maxToken = limitToken
      }
    },
    setModelAi(state, v){state.modelAi = v
      const limitToken = state.modelAiList.find(({name}) => name === state.nameAi).data.find(({model}) => model === v).maxTokens
      state.limitToken = limitToken
      if(state.maxToken > limitToken){
        state.maxToken = limitToken
      }
    },
    setNameAiImage(state, v){state.nameAiImage = v
      const data = state.modelAiImagesList.find(({name}) => name === v).data
      state.modelAiImage = data[0].model
      const qualitySet = new Set();
      data.forEach(item => {if (item.format.standard) {qualitySet.add('standard')}})
      data.forEach(item => {if (item.format.hd) {qualitySet.add('hd')}})
      data.forEach(item => {if (item.format.relax) {qualitySet.add('relax')}})
      state.qualityList = Array.from(qualitySet)
      state.quality = state.qualityList[0]
      const sizeList = data[0].format[state.quality]
      state.sizeList = sizeList
      state.size = sizeList[0]
      
    },
    setModelAiImage(state, v){state.modelAiImage = v
      const data = state.modelAiImagesList.find(({name}) => name === state.nameAiImage).data.find(({model}) => model === v)
      const qualitySet = new Set();
      if (data.format.standard && data.format.standard.length) {qualitySet.add('standard')}
      if (data.format.hd && data.format.hd.length) {qualitySet.add('hd')}
      if (data.format.relax && data.format.relax.length) {qualitySet.add('relax')}
      state.qualityList = Array.from(qualitySet)
      state.quality = state.qualityList[0]
      const sizeList = data.format[state.quality]
      state.sizeList = sizeList
      state.size = sizeList[0]
    },
    setContext(state, v){state.context = v},
    setChat(state, v){state.chat = v},
    setChatData(state, v){state.chatData = v},
    setChatId(state, v){state.chatId= v},
    setTemp(state, v){state.temp = v},
    setRoleSystem(state, v){state.roleSystem = v},
    setMaxToken(state, v){state.maxToken = Number(v)},
    setLimitToken(state, v){state.limitToken = Number(v)},
    setPromptSave(state, v){state.promptSave = v},
    setReadyPrompt(state, v){state.readyPrompt = v; state.prompt = v},
    setAmountDebited(state, v){state.amountDebited = v},
    setEnterTokens(state, v){state.enterTokens = v},
    setExitTokens(state, v){state.exitTokens = v},
    setChatList(state, v){state.chatList = v},
    setChatListLimit(state, v){state.chatListLimit = v},
    setImageList(state, v){state.imageList = v},
    setTemporaryId(state, v){state.temporary.id = v},
    setTemporaryData(state, v){state.temporary.data = v},
    setChatListHome(state, v){state.chatListHome = v},
    setImageListHome(state, v){state.imageListHome = v},

    setRewriteTextTextResult(state, v){state.rewriteText.textResult = v},
    setRewriteTextText(state, v){state.rewriteText.text = v},
    setRewriteTextKeywords(state, v){state.rewriteText.keywords = v},
    setRewriteTextCreative(state, v){state.rewriteText.creative = Number(v)},
    setRewriteTextStrong(state, v){state.rewriteText.strong = v},

    setTextList(state, v){state.textList = v},
    setSystemList(state, v){state.systemList = v},
    setPage(state, v){state.page = v},
    setLength(state, v){state.length = v},

    setDomainList(state, v){state.domainList = v},
    setNewDomain(state, v){state.newDomain = v},
    setLoadingDomain(state, v){state.loadingDomain = v},
  },

  actions: {
    async actionAiContext({state, commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        let prompt = state.prompt
        if(state.chat){
          if(state.prompt){
            commit('setPromptChat', state.prompt)
          }
          prompt = state.promptChat
          commit('setPrompt', null)
        }
        const data = {prompt, nameAi: state.nameAi, modelAi: state.modelAi, temp: state.temp, chat: state.chat, chatId: state.chatId, maxToken: state.maxToken}
        const response = await axiosAiContext(rootState.token, data)
        if(state.chat){
          await dispatch('actionGetChatNameLimit')
          commit('setChatData', response.chat.data)
          commit('setChatId', response.chat.id)
        } else {
          commit('setPrompt', null)
          commit('setReadyPrompt', null)
          commit('setContext', response.context)
        }
        commit('setAmountDebited', response.amountDebited)
        commit('setEnterTokens', response.enterTokens)
        commit('setExitTokens', response.exitTokens)
        commit('setBalance', response.balance, { root: true })
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionAiContext')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 10000);
        }
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Запрос по генерации изображений
    async actionAiImage({state, commit, rootState, dispatch}){
      try {
        let images = state.imagesUrl ? state.imagesUrl.split(',').map(link => link.trim()).filter(link => link).slice(0, 5) : [];
        let size = state.size
        if(state.nameAiImage === 'Midjourney'){
          size = state.mySize ? state.mySize : state.size
          if (images.length === 1) {
            commit('setError', 'Добавьте несколько ссылок на изображения.')
            setTimeout(function () {
              commit('setError', '')
            }, 10000);
            return
          }
        }
        commit('setLoading', true)
        const data = {prompt: state.prompt, nameAi: state.nameAiImage, modelAi: state.modelAiImage, quality: state.quality, mode: state.mode, images, size}
        data.style =  state.style === 'NO' ? '' : state.style
        const response = await axiosAiImage(rootState.token, data)
        commit('setResultImage', response.image)
        commit('setAmountDebited', response.amountDebited)
        commit('setBalance', response.balance, { root: true })
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionAiImage')
        } else {
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 10000);
        }
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionChooseChat({commit, state, rootState, dispatch}, data){
      try {
        if(Object.keys(data).length === 0){
          return
        }
        commit('setTemporaryData', data)
        commit('setLoading', true)
        const response = await axiosChooseChat(rootState.token, state.temporary.data.nameAi, state.temporary.data.id)
        commit('setTemporaryData', {})
        if (Object.keys(response).length !== 0) {
          commit('setChatData', response.data)
          commit('setChatId', response.id)
          commit('setNameAi', response.nameAi)
          commit('setModelAi', response.modelAi)
          commit('setAmountDebited', 0)
          commit('setEnterTokens', 0)
          commit('setExitTokens', 0)
        }
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionChooseChat', state.temporary.id)
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionAiRewriteText({state, commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const data = {text: state.rewriteText.text, keywords: state.rewriteText.keywords, creative: state.rewriteText.creative, strong: state.rewriteText.strong ? 1 : 0}
        const response = await axiosAiRewriteText(rootState.token, data)
        if(response.status === 'error'){
          commit('setError', response.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
          return
        }
        commit('setRewriteTextTextResult', response.text)
        commit('setRewriteTextText', '')
        commit('setRewriteTextKeywords', '')
        commit('setRewriteTextCreative', 1)
        commit('setRewriteTextStrong', false)
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionAiRewriteText')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionAiTextList({state, commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosAiTextList(rootState.token, state.page)
        commit('setTextList', response.texts)
        commit('setLength', response.count)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionAiTextList')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Получаем список системных сообщений
    async actionSystemList({state, commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosSystemList(rootState.token, state.page, state.limit)
        commit('setSystemList', response.data)
        commit('setLength', response.count)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionSystemList')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Удаляем системное сообщения 
    async actionRemoveSystemList({commit, state, rootState, dispatch}, id){
      try {
        commit('setTemporaryId', id)
        await axiosRemoveSystemList(rootState.token, state.temporary.id)
        await dispatch('actionSystemList')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionChooseChat', state.temporary.id)
        }
        console.log(e.response.data)
      }
    },



    async actionCreateAiToken({commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        await axiosCreateAiToken(rootState.token)
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateAiToken')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // промпты
    async actionGetPromptSystem({commit, rootState, dispatch}){
      try {
        const response = await axiosGetPromptSystem(rootState.token)
        commit('setPromptSystemList', response)
      } catch (e) {
        console.log(e.response.data)
      }
    },

    // Получаем текстовые модели
    async actionGetModelAi({commit, rootState, dispatch}){
      try {
        const response = await axiosGetModelAi(rootState.token, 'text')
        commit('setModelAiList', response)
        commit('setLimitToken', response[0].data[0].maxTokens)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetModelAi')
        }
        console.log(e.response.data)
      }
    },

    //Получаем модели изображений
    async actionGetModelImageAi({commit, rootState, dispatch}){
      try {
        const response = await axiosGetModelAi(rootState.token, 'image')
        commit('setModelAiImagesList', response)
        const data = response[0].data[0]
        const qualitySet = new Set();
        if (data.format.standard && data.format.standard.length) {qualitySet.add('standard')}
        if (data.format.hd && data.format.hd.length) {qualitySet.add('hd')}
        if (data.format.relax && data.format.relax.length) {qualitySet.add('relax')}
        commit('setQualityList', Array.from(qualitySet))
        commit('setQuality', Array.from(qualitySet)[0])
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetModelImageAi')
        }
        console.log(e.response.data)
      }
    },

    // Получаем названия последних 5 чатов
    async actionGetChatNameLimit({state, commit, rootState, dispatch}){
      try {
        const response = await axiosGetChatNameLimit(rootState.token, state.nameAi, 1, 5)
        commit('setChatListLimit', response.chats)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetChatNameLimit')
        }
        console.log(e.response.data)
      }
    },

    // Получаем чаты для главной
    async actionGetChatListHome({commit, state, rootState, dispatch}){
      try {
        commit('setLoadingChatHome', true)
        const response = await axiosGetChatNameLimit(rootState.token, state.chatListNameAI, 1, 15)
        commit('setChatListHome', response.chats)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetChatListHome')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingChatHome', false)
        }, 500);
      }
    },

    // Получаем изображения для главной
    async actionGetImageListHome({commit, state, rootState, dispatch}){
      try {
        commit('setLoadingImageHome', true)
        const response = await axiosGetImageList(rootState.token, state.listNameAiImage, 1, 10)
        commit('setImageListHome', response.images)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetImageListHome')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingImageHome', false)
        }, 500);
      }
    },

    // Получаем чаты постранично
    async actionGetChatList({commit, state, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosGetChatNameLimit(rootState.token, state.chatListNameAI, state.page, state.limit)
        commit('setChatList', response.chats)
        commit('setLength', response.count)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetChatList')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Получаем изображения по странично
    async actionGetImageList({commit, state, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosGetImageList(rootState.token, state.listNameAiImage, state.page, state.limitImg)
        commit('setImageList', response.images)
        commit('setLength', response.count)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetImageList')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // редактируем промпт
    async actionEditPrompt({commit, state, rootState, dispatch}){
      try {
        commit('setLoading', true)
        await axiosEditPrompt(rootState.token, {name: state.edit.name, id: state.edit.id, text: state.edit.text})
        commit('setShow', false)
        commit('setEditName', null)
        commit('setEditText', null)
        commit('setEditId', 0)
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditPrompt')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // создаем промпт
    async actionAddPrompt({commit, state, rootState, dispatch}){
      try {
        commit('setLoading', true)
        await axiosAddPrompt(rootState.token, {name: state.edit.name, text: state.edit.text})
        commit('setShow', false)
        commit('setEditName', null)
        commit('setEditText', null)
        commit('setEditId', 0)
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionAddPrompt')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    // Добавляем, удаляем, получаем домены

    async actionGetListDomain({commit, rootState, dispatch}){
      try {
        commit('setLoadingDomain', true)
        const response = await axiosGetListApiDomain(rootState.token)
        commit('setDomainList', response.domains)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetListDomain')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingDomain', false)
        }, 500);
      }
    },

    async actionCreateDomain({commit, state, rootState, dispatch}){
      try {
        await axiosCreateApiDomain(rootState.token, {domain: state.newDomain})
        commit('setNewDomain', null)
        await dispatch('actionGetListDomain');
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateDomain')
        }
        console.log(e.response.data)
      }
    },

    async actionRemoveDomain({commit, state, rootState, dispatch}, id){
      try {
        commit('setTemporaryId', id)
        await axiosRemoveApiDomain(rootState.token, state.temporary.id)
        await dispatch('actionGetListDomain');
        commit('setTemporaryId', 0)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionRemoveDomain', state.temporary.id)
        }
        console.log(e.response.data)
      }
    },

    
  },
  namespaced: true
}