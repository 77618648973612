<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }}</p>
      </div>
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">

              <div class="row">
                <div class="col-sm-12 col-lg-9 chat-right-wrapper">
                  <div class="chat-content animate__animated animate__fadeIn   active">
                    <div class="card">
                      <div class="card-body msg-content">
                        <div class="msg-list">
                          <div v-if="loading">
                            <loading-small />
                            <div class="text-secondary text-center">Ответ нейросети займет некоторое количество времени</div>
                          </div>
                          <div v-else>
                            <div v-if="context" class="">
                              <div class="single-msg-content">
                                
                                  <img :src="`https://cognitiveai.hb.bizmrg.com/${context}.jpg`" width="500">
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="context" class="mt-2 fz-12"><span class="text-danger ml-3"> ( - {{ amountDebited }} руб.)</span></div>
                      <div class="card-footer px-3 py-3">
                        <div v-if="mode === 'blend' && nameAiImage === 'Midjourney'" class="form-group">
                          <label>Пожалуйста, укажите через запятую ссылки на изображения в количестве от 2 до 5.</label>
                          <my-textarea
                            :model-value="imagesUrl" @update:model-value="setImagesUrl"
                            class="form-text-neur-img"
                            placeholder="https://example.com/image1.jpg, https://example.com/image2.jpg, https://example.com/image3.jpg"
                          />
                        </div>
                        <div v-else class="form-group">
                          <label>Промпт</label>
                          <my-textarea
                            :model-value="prompt" @update:model-value="setPrompt"
                            class="form-text-neur-img"
                            placeholder="Введите запрос. Например: Нарисуй маленького котенка, который играет с клубком пряжи на теплом солнечном подоконнике."
                          />
                          <span class="hint-open text-primary" v-if="nameAiImage === 'Midjourney'"> Рекомендуем составлять промпты для Midjourney на английском языке. </span>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 text-right">
                            <div v-if="prompt && !loading" @click="start" class="chat-button chat-button-active mt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                              </svg>
                            </div>
                            <div v-else class="chat-button mt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-3">
                  <div class="card ">
                    <div class="card-body">
                      <div class="form-group">
                        <label>Модель</label><br>
                        <my-select-array
                        class="custom-select"
                        :model-value="nameAiImage"
                        @update:model-value="setNameAiImage"
                        :options="nameAi"
                        />
                      </div>
                      <div class="form-group mb-4">
                        <my-select-array
                        class="custom-select"
                        :model-value="modelAiImage"
                        @update:model-value="setModelAiImage"
                        :options="modelImageAi()"
                        />
                      </div>
                      <div class="form-group">
                        <label v-if="nameAiImage === 'Midjourney'">Режим</label>
                        <label v-else>Тип изображения</label>
                        <br>
                        <my-select-array
                        class="custom-select"
                        :model-value="quality"
                        @update:model-value="setQuality"
                        :options="qualityList"
                        />
                        <span class="hint-open text-primary" v-if="nameAiImage === 'Midjourney' && quality === 'relax'"> В режиме "relax" генерация изображения занимает больше времени, что приводит к ограничению общего количества запросов для всех пользователей в сутки по данному режиму </span>
                      </div>
                      <div class="form-group">
                        <label>Формат изображения</label><br>
                        <my-select-array
                        class="custom-select"
                        :model-value="size"
                        @update:model-value="setSize"
                        :options="sizeList"
                        />
                      </div>
                      <div v-if="nameAiImage === 'Midjourney'" class="form-group">
                        <label>или укажите свой формат</label><br>
                        <my-input
                        :model-value="mySize"
                        @update:model-value="setMySize"
                        placeholder="21:3"
                        />
                      </div>
                      <div v-if="nameAiImage === 'Midjourney'" class="form-group">
                        <label>Действия</label><br>
                        <my-select-id
                        class="custom-select"
                        :model-value="mode"
                        @update:model-value="setMode"
                        :options="modeList"
                        />
                      </div>
                      <div v-if="nameAiImage === 'Kandinsky'" class="form-group">
                        <label>Стиль изображения</label><br>
                        <my-select-id
                        class="custom-select"
                        :model-value="style"
                        @update:model-value="setStyle"
                        :options="styleList"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import CopyText from '@/components/CopyText'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    CopyText
  },
  methods: {
    ...mapMutations({setAmountDebited: 'ai/setAmountDebited', setPrompt: 'ai/setPrompt', setNameAiImage: 'ai/setNameAiImage', setModelAiImage: 'ai/setModelAiImage', setContext: 'ai/setContext', setQuality: 'ai/setQuality', setSize: 'ai/setSize', setStyle: 'ai/setStyle', setResultImage: 'ai/setResultImage', setMySize: 'ai/setMySize', setMode: 'ai/setMode', setImagesUrl: 'ai/setImagesUrl'}),
    ...mapActions({actionAiImage: 'ai/actionAiImage' }),
    ...mapGetters({sortTypeAi: 'ai/sortTypeAi', modelImageAi: 'ai/modelImageAi'}),

    start(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.actionAiImage()
    }
  },

  computed: {
    ...mapState({
      error: state => state.ai.error,
      loading: state => state.ai.loading,
      context: state => state.ai.resultImage,
      prompt: state => state.ai.prompt,
      nameAiImage: state => state.ai.nameAiImage, 
      modelAiImage: state => state.ai.modelAiImage,
      size: state => state.ai.size,
      quality: state => state.ai.quality,
      mode: state => state.ai.mode,
      modeList: state => state.ai.modeList,
      imagesUrl: state => state.ai.imagesUrl,
      mySize: state => state.ai.mySize,
      style: state => state.ai.style,
      styleList: state => state.ai.styleList,
      qualityList: state => state.ai.qualityList,
      sizeList: state => state.ai.sizeList,
      amountDebited: state => state.ai.amountDebited,
      nameAi: state => state.ai.modelAiImagesList.map(({name}) => name),
      message: state => state.message,
    }),
  },

  mounted(){
    document.title = 'Генерация изображения - CognitiveAI"'
  },

  unmounted(){
    this.setAmountDebited(0)
    this.setPrompt(null)
    this.setResultImage(null)
    this.setMySize(null)
  }
}
</script>

<style>
  .form-text-neur-neg-img{
    line-height: 17px;
    min-height: 50px;
  }
  .form-text-neur-img{
    line-height: 17px;
    min-height: 80px;
  }
  .hint-open{
    font-size: 12px;
  }
  
</style>