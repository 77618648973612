<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }} </p>
      </div>
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Базы знаний</h4>
            </div>
          </div>
          <div class="card card-block card-stretch">
            <div class="card-body p-0">
              <div v-if="widgetNameList.length > 0" class="d-flex justify-content-between align-items-center p-3">
                <div>
                  <label>Виджеты техподдержки</label>
                  <div class="form-group mb-4">
                    <my-select-arr
                    class="custom-select"
                    :model-value="widgetName"
                    @update:model-value="openInfoBase"
                    :options="widgetNameList.map(({ id, domain: name }) => ({ id, name }))"
                    />
                  </div>
                </div>
                <div v-if='tariff && tariff.status && tariff.type === "Business"'>
                  <button @click="actionCreateInfoBaseSupportWidget" class="btn btn-primary position-relative d-flex align-items-center justify-content-between mch">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>Создать базу знаний для <b class="pl-1">{{ `${widgetName}` }}</b>
                  </button>
                </div>
              </div>
              <div v-else-if="!loading && widgetNameList.length === 0" class="col-lg-12 col-md-12"><p class="bg-warning-light pl-3 pr-3 pt-2 pb-2 rounded pskdi">Вы пока не создали ни одного виджета технической поддержки. <router-link to="/support-widget">Создать виджет</router-link></p></div>
            </div>
          </div>
          
          <div class="pb-5" v-if="loading"><loading-small /></div>
          <div v-else v-for="w in infoBaseList" :key="w.id" class="card card-block card-stretch">
            <div class="card-header justify-content-between widget-block">
              <div class="header-title widget-head-block">
                <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule">
                  <h4 @click="toggleContent(w.id)" class="card-title text-primary">{{ w.name ? w.name : 'Без названия' }}
                    <span v-if="activeIndex === w.id">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" /></svg>
                    </span>
                    <span v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 widget-block-left text-secondary ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg>
                    </span>
                  </h4>
                </div>
                <div v-if="w.type === 'user'" class="widget-block-right">
                  <button @click="openModal(w.id)" class="badge bg-danger" title="Удалить" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <transition name="slide-fade">
              <div class="card-body" v-show="activeIndex === w.id">
                <div class="row">
                  <div v-if="w.type !== 'system_general'" class="col-sm-12 col-md-12 mb-3">
                    <div class="d-flex">
                      <label>Название базы знаний</label>
                      <div class="ml-2">
                        <my-informer color="orange" type="warning" text="Если вы планируете использовать несколько баз знаний, важно давать им короткие и точные названия, которые отражают их содержание. Например, если ваша база знаний касается расценок, подходящими названиями будут «Цены» или «Стоимость». Если нужно разделить цены по разным базам, можно детализировать названия соответствующим образом. Для разделов, посвященных вашей продукции, подойдут названия как «Продукция», «О продукции» или «Наша продукция»<br><br> <b>Почему это важно?</b> Когда пользователи вручную выбирают раздел в чате, они видят названия разделов баз знаний в виде кнопок. Нажатие на кнопку позволяет задать вопросы по интересующему разделу. Если используется автоматический выбор базы знаний, нейронная сеть на основе названия раздела самостоятельно определяет, к какому разделу вопрос наиболее релевантен, и отвечает пользователю, используя наиболее подходящую базу знаний. Поэтому крайне важно давать базам знаний правильные и понятные названия, чтобы они были очевидны как для пользователей, так и для нейросетей." />
                      </div>
                    </div>
                    <div class="input-group">
                      <my-input-array
                        :id="w.id"
                        :model-value="w.name" @update:model-value="setNameInfoBase"
                      />
                    </div>
                  </div>
                  <!--<div v-if="w.type !== 'system_general'" class="col-sm-12 col-md-6 mb-3">
                    <div class="d-flex">
                      <label>Дополнительные правила для нейросети</label>
                      <div class="ml-2">
                        <my-informer color="" type="" text="Вы имеете возможность сформулировать дополнительные правила для нейронной сети, соответствующие любому вашему предпочтению, которые могут быть учтены нейросетью при формировании ответов пользователям на основе вашей базы знаний. Это правило будет применяться в контексте, указанном ниже вашей базы знаний." />
                      </div>
                    </div>
                    <div class="input-group">
                      <my-textarea-array
                        :id="w.id"
                        :model-value="w.dop_text" @update:model-value="setDopTextInfoBase"
                        rows="1"
                      />
                    </div>
                  </div>-->
                  <div class="col-md-12">
                    <div class="d-flex">
                      <label>Содержание базы знаний для нейросети.</label>
                      <div v-if="w.type === 'system_general'" class="ml-2">
                        <my-informer color="red" type="warning" text="Раздел &quot;Другое&quot; используется, когда нейронная сеть сталкивается с затруднениями при определении, к какой базе знаний отнести возникший вопрос от пользователя, или когда по другим причинам она не может предоставить ответ, например, из-за задержки в обработке запросов. Мы крайне не рекомендуем оставлять этот раздел без содержимого. Но у вас есть возможность модифицировать данный текст в соответствии с вашими предпочтениями и нуждами." />
                      </div>
                      <div v-else class="ml-2 d-flex">
                        <my-informer color="" type="" text="Укажите базу знаний для нейронной сети, чтобы она могла корректно отвечать на вопросы пользователей, основываясь на предоставленной базе знаней. Чем обширнее и точнее информация, которой обладает нейронная сеть о вас, тем более точные и релевантные ответы она сможет предоставлять пользователям." />

                        <div class="ml-2"><my-informer color="orange" type="warning" text="При добавлении базы знаний важно учитывать ограничения по длине контекста, которые установлены для нейронной сети. Превышение этих пределов, например, 4000 токенов, приведет к ошибке обработки запроса. Даже если размер контекста окажется в пределах допустимого, но тем не менее слишком большим, это может снизить точность ответов и увеличить стоимость обработки. Мы рекомендуем ограничить объем до 6000 символов для GPT-3.5 и до 10 000 для других моделей. Большие базы знаний целесообразно разделить на несколько меньших баз, чтобы обеспечить эффективность ответов и снизить затраты. Экспериментируйте с объемом, учитывая, что каждый новый вопрос пользователя в одном непрерывном диалоге расширяет контекст." /></div>
                      </div>
                    </div>
                    <div class="input-group">
                      <my-textarea-array
                        :id="w.id"
                        :model-value="w.text" @update:model-value="setTextInfoBase"
                        rows="20"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-12 text-right flex-base">
                    <span class="mr-3">Кол-во символов: {{ w.text.length }}</span>
                    <button @click="actionEditInfoBaseSupportWidget(w.id)" type="button" class="btn btn-success text-center mt-4">Сохранить</button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="`modal fade ${popup ? 'show' : ''}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" :style="`padding-right: 4px; ${popup ? 'display: block' : 'display: none'}`">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5>Вы уверены, что хотите удалить базу знаний?</h5>
          <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-footer">
          <button @click="actionRemoveInfoBaseSupportWidget" type="button" class="btn btn-danger">Да</button>
          <button @click="closeModal" type="button" class="btn btn-secondary">Отмена</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="`${popup ? 'modal-backdrop-pers' : ''}`"></div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import SupportWidget from '@/components/SupportWidget'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    Loading,
    SupportWidget
  },
  methods: {
    ...mapMutations({setWidgetNameList: 'supportWidget/setWidgetNameList', setWidgetName: 'supportWidget/setWidgetName', setPopup: 'supportWidget/setPopup', setTemporaryId: 'supportWidget/setTemporaryId', setActiveIndex: 'supportWidget/setActiveIndex', setNameInfoBase: 'supportWidget/setNameInfoBase', setTextInfoBase: 'supportWidget/setTextInfoBase', setDopTextInfoBase: 'supportWidget/setDopTextInfoBase', setInfoBaseList: 'supportWidget/setInfoBaseList'}),

    ...mapActions({actionGetNameSupportWidget: 'supportWidget/actionGetNameSupportWidget', actionGetInfoBaseSupportWidget: 'supportWidget/actionGetInfoBaseSupportWidget', actionEditInfoBaseSupportWidget: 'supportWidget/actionEditInfoBaseSupportWidget', actionCreateInfoBaseSupportWidget: 'supportWidget/actionCreateInfoBaseSupportWidget', actionRemoveInfoBaseSupportWidget: 'supportWidget/actionRemoveInfoBaseSupportWidget'}),

    openInfoBase(obj){
      this.setWidgetName(obj)
      this.actionGetInfoBaseSupportWidget()
    },

    openModal(param){
      this.setPopup(true)
      this.setTemporaryId(param) 
    },
    closeModal(){
      this.setPopup(false)
      this.setTemporaryId(0)
    },
    toggleContent(index) {
      this.setActiveIndex(this.activeIndex === index ? null : index)
    },

  },

  computed: {
    ...mapState({
      loading: state => state.supportWidget.loading,
      widgetNameList: state => state.supportWidget.widgetNameList,
      infoBaseList: state => state.supportWidget.infoBaseList,
      widgetName: state => state.supportWidget.widgetName,
      popup: state => state.supportWidget.popup,
      message: state => state.supportWidget.message,
      error: state => state.supportWidget.error,
      activeIndex: state => state.supportWidget.activeIndex,
      tariff: state => state.user.tariff,
    }),
  },

  mounted(){
    this.actionGetNameSupportWidget()
    document.title = "Создание и настройка баз знаний для техподдержки - CognitiveAI"
  },

  unmounted(){
    this.setInfoBaseList([])
    this.setWidgetName({id: 0, name: null})
  }
}
</script>

<style scoped>
  .flex-base{
    display: flex;
    align-items: baseline;
    flex-direction: row;
    justify-content: flex-end;
}
</style>