<template>
  <form @submit.prevent>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <div v-if="errors.length">
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors" :key="error">{{error}}</p>
          </div>
          <label class="text-secondary">Имя: <my-required-field /></label>
          <my-input
            :model-value="reg.name" @update:model-value="setRegName"
            type="name"
            required
            placeholder="Ваше имя"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label class="text-secondary">Телефон: <my-required-field /></label>
          <div class="input-group mb-4">
            <div class="input-group-prepend select-country">
              <div class="input-group-text">
                <div class="custom-control custom-switch custom-switch-color">
                  <my-select-id
                    class="custom-select"
                    :model-value="authCountry"
                    @update:model-value="setAuthCountry"
                    :options="country"
                  />
                </div>
              </div>
            </div>
            <my-input
            :model-value="reg.phone" @update:model-value="setRegPhone"
            type="tel"
            :placeholder="authPlaceholder"
            v-mask="authMask"
            required
          />
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label class="text-secondary">Электронная почта:</label>
          <my-input
            :model-value="reg.email" @update:model-value="setRegEmail"
            type="email"
            placeholder="Ваш е-mail"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label class="text-secondary">Промокод:</label>
          <my-input
            :model-value="reg.promocode" @update:model-value="setRegPromocode"
            type="text"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-lg-12 mt-2">
        <div class="form-check form-check-inline">
          <label> Нажимая кнопку, я соглашаюсь с  <a href="https://cognitiveai.ru/polzovatelskoe-soglashenie/" target="_blank">Договором оферты</a> и <a href="https://cognitiveai.ru/politika-konfidentsialnosti" target="_blank">Политикой конфиденциальности</a> </label>
        </div>
      </div>
    </div>
    <my-button type="submit" class="btn-primary btn-block mt-2" @click="actionReg">Зарегистрироваться</my-button>
    <div class="col-lg-12 mt-3">
      <p class="mb-0 text-center">У вас уже есть аккаунт? <router-link to="/login">Войти</router-link></p>
    </div>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mask} from 'vue-the-mask'
export default {
  directives: {mask},
  name: 'registration-form',
  methods: {
    ...mapMutations(['setRegName', 'setRegEmail', 'setRegPhone', 'setRegPromocode', 'setAuthCountry']),

    ...mapActions(['actionReg'])
  },
  computed: {
    ...mapState({
      reg: state => state.reg,
      authCountry: state => state.authCountry,
      country: state => state.country,
      authMask: state => state.authMask,
      authPlaceholder: state => state.authPlaceholder,
      errors: state => state.errors
    }),
  }
}
</script>