<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <i class="fa fa-viber"></i>
              <h5><a class="text-danger" href="mailto:info@cognitiveai.ru">info@cognitiveai.ru</a></h5><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
export default {
  components: {
    Header,
  },
  methods: {
  },

  computed: {
  },

  mounted(){
    document.title = "Поддержка - CognitiveAI"
  },
  
}
</script>
<style>
  .sc-icon {
    margin-left:20px;
    font-size:25px
  }
</style>