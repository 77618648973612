<template>
  <partner-payment-details-form />
  <div class="col-lg-12 mt-2 mb-3">
        <div class="form-check form-check-inline">
          <label> Нажимая кнопку <b>"Стать партнером"</b>, я соглашаюсь с  <a href="https://cognitiveai.ru/dogovor-ob-uchastii-v-partnerskoj-programme/" target="_blank">Договором оферты об участии в партнерской программе</a> </label>
        </div>
      </div>
  <button class="btn btn-primary btn-lg" @click="actionCreatePartner" type="submit">Стать партнером</button>
</template>


<script>
import PartnerPaymentDetailsForm from '@/components/PartnerPaymentDetailsForm'
import {mapActions} from 'vuex'
export default {
  name: 'partner-form',
  components: {
    PartnerPaymentDetailsForm
  },

  methods: {
    ...mapActions({actionCreatePartner: 'partner/actionCreatePartner'})
  },

  
}
</script>