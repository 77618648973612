<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">API</h4>
                </div>
                <div class="create-workform">
              <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div class="modal-product-search d-flex">
                  <div class="btn-group btn-group-toggle btn-group-sm btn-group-edges mr-5 model-ai-preb"> 
                    <my-input
                    :model-value="newDomain" @update:model-value="setNewDomain"
                    type="text"
                    required
                    placeholder="Введите ваш домен, например: my-domain.ru "
                    /> <span class="mr-2"><my-informer color="orange" type="warning" text="Указание домена, с которого будут поступать запросы, и выполнение этих запросов с помощью JavaScript из браузера поможет вам избежать ошибок, связанных с CORS." /></span>
                  </div>
                  <button @click="actionCreateDomain" class="btn btn-primary position-relative d-flex align-items-center justify-content-between mch">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>Сохранить домен
                  </button>
                </div>
              </div>
            </div>  
              </div>

              <div class="card">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-spacing mb-0">
                      <tr class="white-space-no-wrap">
                        <td>
                          <h6 class="mb-0 text-uppercase text-secondary">API-Токен:</h6>
                        </td>
                        <td class="pl-0 py-3">
                          <span v-if="loading" class="card-body"><loading-small /></span>
                          <span v-else class="token">{{ token ? token.token : '' }}</span><br>
                         
                        </td>
                        <td v-if="token" class="text-right">
                            <copy-token :token="token.token" />
                            <button class="btn btn-primary btn-sm mr-2 ml-3" @click="actionCreateAiToken">Сгенерировать новый токен</button>
                        </td>
                        <td v-else class="text-right">
                            <button class="btn btn-primary btn-sm mr-2" @click="actionCreateAiToken">Сгенерировать токен</button>
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td>
                          <h6 class="mb-0 text-uppercase text-secondary">Дата окончания действия API-токена</h6>
                        </td>
                        <td class="pl-0 py-3">
                          <span v-if="token">{{ new Date(token.date).toLocaleString() }}</span>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div> 
              <div class="card">
                <div class="card-body"><a href="https://cognitive-ai.ru/api/docs/" target="_blank" class="btn btn-outline-success mt-2">Инструкция по настройке API. Swagger <i class="fa fa-angle-double-right ml-2" aria-hidden="true"></i></a></div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h3>Списки доменов</h3>
                  <div v-if="loadingDomain" class="col-sm-12"><loading-small /></div>
                  <div v-else class="col-sm-12">
                    <table class="table data-table mb-0 dataTable">
                      <tbody>
                        <tr v-for="domain in domainList" :key="domain.id">
                          <td><b>{{ domain.domain }}</b></td>
                          <td><div class="text-right"><button @click="actionRemoveDomain(domain.id)" class="badge bg-danger" title="Удалить" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg></button></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import TextList from '@/components/TextList'
import CopyToken from '@/components/CopyToken'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    TextList,
    CopyToken
  },
  methods: {
    ...mapMutations({setNewDomain: 'ai/setNewDomain'}),
    ...mapActions({actionCreateAiToken: 'ai/actionCreateAiToken', actionCreateDomain: 'ai/actionCreateDomain', actionRemoveDomain: 'ai/actionRemoveDomain', actionGetListDomain: 'ai/actionGetListDomain'}),
  },

  computed: {

    ...mapState({
      token: state => state.user.user_token_neurnetwork,
      message: state => state.message,
      loading: state => state.ai.loading,
      newDomain: state => state.ai.newDomain,
      domainList: state => state.ai.domainList,
      loadingDomain: state => state.ai.loadingDomain,
    }),
  },

  mounted(){
    this.actionGetListDomain()
    document.title = 'API-токен - CognitiveAI"'
  }
}
</script>