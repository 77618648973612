<template>
    <span class="beta">beta</span>
</template>


<script>
export default {
  name: 'beta',
}
</script>
<style>
.beta{
  color: #f42b3d;
}

</style>