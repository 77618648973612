<template>
  <span class="required"> *</span>
</template>

<script>
export default {
  name: 'my-required-field'
}
</script>

<style>
  .required{
    color: red !important;
    position: relative;
    top:-2px;
  }
</style>