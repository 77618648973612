<template>
  <pre>
  </pre>
  <div v-if="loading" class="card-body"><loading-small /></div>
  <Accordion v-else class=" accard">
    <AccordionTab v-for="(text, index) in systemList" :key="index">
      <template v-slot:header>
        <div class="dis-flex">
          <span>{{ text.nameAi }} <span class="small text-warning">({{ text.modelAi }})</span></span>
          <div class="widget-block-right">
            <span class="date mr-3">{{ new Date(text.createdAt).toLocaleString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' }).replace(' г.,', 'г.').replace(/^0+/, '') }}</span>
            <button @click="actionRemoveSystemList(text.id)" class="badge bg-danger" title="Удалить" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
              </svg>
            </button>
          </div>
        </div>
      </template>
      <div class="mb-0">
        <h6 class="mb-2">Промпт:</h6>
        <pre>{{ text.prompt }}</pre>
        <h6 class="mt-5 mb-2">Ответ:</h6>
        <pre>{{ text.context }}</pre>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import 'primevue/resources/themes/lara-light-green/theme.css'
export default {
  components: {
    Accordion,
    AccordionTab
  },
  name: 'system-list',
  props: {
    systemList: Array,
    loading: Boolean
  },
  methods: {
    ...mapMutations({setEditId: 'ai/setEditId', setEditName: 'ai/setEditName', setShow: 'ai/setShow'}),
    ...mapActions({actionRemoveSystemList: 'ai/actionRemoveSystemList'}), 
  },
}
</script>
<style scoped>
  .dis-flex{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .date{
    font-size: 12px;
    color: #cd2433;
  }
</style>