<template>
  <pre>
  </pre>
  <div v-if="loading" class="card-body"><loading-small /></div>
  <Accordion v-else class=" accard">
    <AccordionTab v-for="(text, index) in textList" :key="index">
      <template v-slot:header>
        <span>{{ text.name }}</span>
        <i class="fa fa-pencil icon-edit" aria-hidden="true" @click.stop="edit({id: text.id, name: text.name, text: text.text})"></i>
      </template>
      <div class="mb-0">
        <pre>{{ text.text }}</pre>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import 'primevue/resources/themes/lara-light-green/theme.css'
export default {
  components: {
    Accordion,
    AccordionTab
  },
  name: 'prompt-list',
  props: {
    textList: Array,
    loading: Boolean
  },
  methods: {
    ...mapMutations({setEditId: 'ai/setEditId', setEditName: 'ai/setEditName', setShow: 'ai/setShow', setEditText: 'ai/setEditText'}),
    ...mapActions({}),

    edit(arr){
      this.setEditId(arr.id)
      this.setEditName(arr.name)
      this.setEditText(arr.text)
      this.setShow(true)
    }

    
  },

  computed: {
    ...mapState({}),
  },
}
</script>
<style>
.accard .p-icon{
  background: none;
  height: auto;
  width: auto;
  color: #324253;
}

.accard .p-accordion-tab{
  
  margin-bottom: 30px;

}

.accard .p-accordion-header .p-accordion-header-link{
  box-shadow: 0 10px 30px 0 rgba(0,60,179,.05);
    border: 0 solid #dfe7ff;
  background-color: #FFF;
  border-radius: 5px
}

.accard .p-accordion-header-text{
  font-size: 16px;
  color: #01041b;
  font-weight: normal;
}

.icon-edit{
  position: absolute;
  right: 20px;
  padding: 10px;
}

.icon-edit:hover{
  color: red;
}

pre {
  overflow-x: hidden;
}
  
</style>