<template>
  <Header></Header>
  <div class="content-page">
      <div class="container-fluid">
        <div class="row no-gutters height-self-center">
            <div class="col-sm-12 text-center align-self-center">
               <div class="iq-error position-relative">
                     <h2 class="mb-0">Спасибо!</h2>
                     <p>Данные для оплаты отправлены на ваш адрес электронной почты <b>{{user.email}}</b></p>
               </div>
            </div>
         </div>
      </div>
      </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import {mapState} from 'vuex'
export default {
  components: {
    Header,
  },

  computed: {
    ...mapState({
      user: state => state.user
    })
  },

  mounted(){
    document.title = "Спасибо - CognitiveAI"
  }
}
</script>