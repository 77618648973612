<template>
  <form enctype="multipart/form-data" @submit.prevent>
    <div class="row">
      <div v-if="errors.length" class="form-group col-12">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors" :key="error">{{error}}</p>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Имя: <my-required-field /></label>
        <my-input
          :model-value="user.name" @update:model-value="setEditName"
          type="text"
          required
        />
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Адрес электронной почты </label>
        <my-input
          :model-value="user.email" @update:model-value="setEditEmail"
          type="email"
          placeholder="info@examples.com"
        />
        <span class="inform" v-if="!user.email">Укажите электронную почту, это поможет вам восстановить доступ к аккаунту в случае потери доступа к номеру телефона</span>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Телефон: <my-required-field /></label>
        <div class="input-group mb-4">
          <div class="input-group-prepend select-country">
            <div class="input-group-text">
              <div class="custom-control custom-switch custom-switch-color">
                <my-select-id
                  class="custom-select"
                  :model-value="newAuthCountry"
                  :options="country"
                  disabled
                />
              </div>
            </div>
          </div>
          <my-input
          :model-value="user.phone"
          type="tel"
          :placeholder="authPlaceholder"
          disabled
        />
        </div>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label class="" for="customFile">Аватар (png, jpg, jpeg): </label>
        <img v-if="img" :src="img" width="50" />
        <my-file
          @file="setImageFile"
          :model-value="image" @update:model-value="setImageFile"
          class="file-inp"
        />
      </div>
    </div>
     <my-button type="submit" class="btn btn-primary mr-2 mr-2 pl-5 pr-5" @click="actionEditUser">Сохранить</my-button>
  </form>
  <image-upload :image="image" @update:image="saveFile" />
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mask} from 'vue-the-mask'
export default {
  directives: {mask},
  name: 'my-profile-form',
  methods: {
    ...mapMutations({
      setEditName: 'setEditName', setEditPhone:'setEditPhone', setEditEmail: 'setEditEmail', setImage: 'setImage', setModal: 'setModal', setImg: 'setImg', setAuthCountry: 'setAuthCountry'}),

    ...mapActions({actionEditUser: 'actionEditUser'}),

    setImageFile(e){
      this.setImg(null)
      this.setImage(e)
      this.setModal()
    },

    saveFile(e){
      this.setImage(e)
    }
    
  },
  computed: {
    ...mapState({
      user: state => state.user,
      image: state => state.image,
      newAuthCountry: state => state.newAuthCountry,
      country: state => state.country,
      authMask: state => state.authMask,
      authPlaceholder: state => state.authPlaceholder,
      errors: state => state.errors,
      img: state => state.img
    }),
  }
}
</script>

<style>
.hover:after{
  content: attr(data-title);
  display: none;
  position: absolute;
  bottom: 130%;
  left: 0px;
  color: #3aaeda;
  padding: 5px;
  text-align: center;
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,.16);
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.16);
  box-shadow: 0 1px 1px rgba(0,0,0,.16);font-size: 12px;}
  .hover:hover:after{display: block;
  }
  .file-inp{
    line-height:1.5
  }
  .site{
    margin-right:10px;
    font-weight: bold;
  }
  .alert2{
    border: none;
    display: block;
  }
  .color-red{
    color: red;
    cursor: pointer;
    position: relative;
    top:-8px;
    font-size: 12px;
  }
  .img{
    width: 120px;
  }
  .i-img{
    font-size:90px;
    color: #db7e06;
  }
  .inform{
    font-size: 12px;
    color: red;
  }
  #multiple{position: absolute; background-color: #FFF; z-index: 999;}
</style>