import { createApp } from 'vue'
import App from '@/App'
import components from '@/components/UI';
import router from "@/router";
import store from '@/store';
import 'croppie/croppie.css'
import PrimeVue from "primevue/config";


const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})

app
    .use(router)
    .use(store)
    .use(PrimeVue, { /* options */ })
    .mount('#app');
    
