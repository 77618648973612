<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }} </p>
      </div>
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Оставленные контакты</h4>
            </div>
          </div>
          
          
          <div class="pb-5" v-if="loading"><loading-small /></div>
          <div v-else class="row">
            <div class="col-lg-12">
              <table class="table data-table mb-0 dataTable no-footer">
                <thead class="table-color-heading">
                  <tr class="text-light">
                    <th>Имя</th>
                    <th>Контакты</th>
                    <th></th>
                    <th></th>
                  </tr>
              </thead>
              </table>
            </div>
          </div>
          
          
          <div v-for="w in contactsList" :key="w.id">
            

          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import SupportWidget from '@/components/SupportWidget'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    Loading,
    SupportWidget
  },
  methods: {
    ...mapMutations({setWidgetNameList: 'supportWidget/setWidgetNameList', setWidgetName: 'supportWidget/setWidgetName', setPopup: 'supportWidget/setPopup', setTemporaryId: 'supportWidget/setTemporaryId', setActiveIndex: 'supportWidget/setActiveIndex'}),

    ...mapActions({actionGetContactsSupportWidget: 'supportWidget/actionGetContactsSupportWidget',}),

  },

  computed: {
    ...mapState({
      loading: state => state.supportWidget.loading,
      contactsList: state => state.supportWidget.contactsList,
      message: state => state.supportWidget.message,
      error: state => state.supportWidget.error,
      page: state => state.supportWidget.page,
      limit: state => state.supportWidget.limit,
    }),
  },

  mounted(){
    this.actionGetContactsSupportWidget()
    document.title = "Диалоги пользователей обратившись через виджет техподдержки - CognitiveAI"
  },

  unmounted(){
    this.setWidgetNameList([])
    this.setWidgetName({id: 0, name: null})
  }
}
</script>

<style>
</style>