import MyInput from '@/components/UI/MyInput'
import MyRange from '@/components/UI/MyRange'
import MyRangeArray from '@/components/UI/MyRangeArray'
import MyInputArray from '@/components/UI/MyInputArray'
import MyButton from '@/components/UI/MyButton'
import MySelect from '@/components/UI/MySelect'
import MySelectArray from '@/components/UI/MySelectArray'
import MySelectId from '@/components/UI/MySelectId'
import MyTextarea from '@/components/UI/MyTextarea'
import MyTextareaArray from '@/components/UI/MyTextareaArray'
import MySelectSArr from '@/components/UI/MySelectSArr'
import MyFile from '@/components/UI/MyFile'
import MyRequiredField from '@/components/UI/MyRequiredField'
import MyCheckboxVal from '@/components/UI/MyCheckboxVal'
import MyCheckbox from '@/components/UI/MyCheckbox'
import MyRadio from '@/components/UI/MyRadio'
import MyDate from '@/components/UI/MyDate'
import MySumWords from '@/components/UI/MySumWords'
import MyPagination from '@/components/UI/MyPagination'
import LoadingSmall from '@/components/UI/LoadingSmall'
import LoadingSmallTwo from '@/components/UI/LoadingSmallTwo'
import MySelectSelected from '@/components/UI/MySelectSelected'
import ImageUpload from '@/components/UI/ImageUpload'
import MyFooter from '@/components/UI/MyFooter'
import Beta from '@/components/UI/Beta'
import MyInformer from '@/components/UI/MyInformer'


export default [
  MyInput,
  MyRange,
  MyRangeArray,
  MyInputArray,
  MyButton,
  MySelect,
  MySelectArray,
  MySelectId,
  MyTextarea,
  MyTextareaArray,
  MySelectSArr,
  MyFile,
  MyRequiredField,
  MyCheckboxVal,
  MyCheckbox,
  MyRadio,
  MyDate,
  MySumWords,
  MyPagination,
  LoadingSmall,
  LoadingSmallTwo,
  MySelectSelected,
  ImageUpload,
  MyFooter,
  Beta,
  MyInformer
]