<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="mobile-my d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Мои чаты</h4>
                </div>
                <div class="create-workform">
                  <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="modal-product-search d-flex">
                      <div class="btn-group btn-group-toggle btn-group-sm btn-group-edges mr-5 model-ai-preb"> 
                        <span @click="selectNameAi('GPT-3.5')" :class="`button btn button-icon ${chatListNameAI === 'GPT-3.5' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">GPT-3.5</span>
                        <span @click="selectNameAi('GPT-4')" :class="`button btn button-icon ${chatListNameAI === 'GPT-4' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">GPT-4</span>
                        <span @click="selectNameAi('GPT-4o')" :class="`button btn button-icon ${chatListNameAI === 'GPT-4o' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">GPT-4o</span>
                        <span @click="selectNameAi('YandexGPT')" :class="`button btn button-icon ${chatListNameAI === 'YandexGPT' ? 'bg-success' : 'btn-outline-success'}`" target="_blank" href="#">YandexGPT</span>
                      </div>
                      <router-link to="/chat" class="btn btn-primary position-relative d-flex align-items-center justify-content-between mch">
                        <svg width="20px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                        </svg>Начать диалог
                      </router-link>
                    </div>
                  </div>
                </div> 
              </div>
              <div v-if="loading" class="card-body"><loading-small /></div>
              <div v-else class="iq-accordion career-style faq-style">
                
                <div class="card iq-accordion-block chat-choose" v-for="chat in chatList" :key="chat.id" @click="chooseChat({id: chat.id, nameAi: chatListNameAI})">
                  <div class="clearfix">
                    <div class="container">
                      <div class="row py-2">
                        <div class="col-sm-12">
                          <span>{{ chat.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div v-if="!loading && length > limit" class="col-lg-12">
              <pagination :pages="Math.ceil(length / limit)" :model-value="page" @update:model-value="editPage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import TextList from '@/components/TextList'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    TextList,
  },
  methods: {
    ...mapMutations({setChatList: 'ai/setChatList', setPage: 'ai/setPage', setLength: 'ai/setLength', setChatListNameAI: 'ai/setChatListNameAI'}),
    ...mapActions({actionGetChatList: 'ai/actionGetChatList', actionChooseChat: 'ai/actionChooseChat'}),

    editPage(e){
      this.setPage(e)
      this.actionGetChatList()
    },

    selectNameAi(param){
      this.setPage(1)
      this.setLength(0)
      this.setChatListNameAI(param)
      this.actionGetChatList();
    },

    chooseChat(data){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.actionChooseChat(data)
      this.$router.push('/chat')
    },
  },

  computed: {

    ...mapState({
      chatList: state => state.ai.chatList,
      chatListNameAI: state => state.ai.chatListNameAI,
      loading: state => state.ai.loading,
      limit: state => state.ai.limit,
      page: state => state.ai.page,
      length: state => state.ai.length
    }),
  },

  mounted(){
    this.actionGetChatList();
    document.title = 'Мои диалоги - CognitiveAI"'
  },

  unmounted(){
    this.setChatList([])
    this.setPage(1)
    this.setLength(0)
  }
}
</script>

<style>
  .chat-choose{
    cursor: pointer;
  }
  .chat-choose:hover{
    background-color: rgba(51,120,255,.2);
  }
  .model-ai-preb span {
    padding: 9px 25px 5px 25px !important;
  }
  @media (max-width: 767px) {
    .mobile-my .button{
      display: block !important;
      border-radius: 5px !important;
      margin-bottom: 10px;
    }
    .mobile-my .d-flex, .mobile-my.d-flex{
      display: block !important;
    }
    .mobile-my .btn-group{
      display: block !important;
    }
    .mobile-my .btn-group>.btn-group:not(:last-child)>.btn, .mobile-my .btn-group>.btn:not(:last-child):not(.dropdown-toggle){
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .mobile-my .btn-group>.btn-group:not(:first-child)>.btn, .mobile-my .btn-group>.btn:not(:first-child){
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .mobile-my .mr-5, .mobile-my .mx-5{
      margin-right: auto !important;
    }

}
  
</style>