<template>
  <select v-model="modelValue" @change="changeOption">
    <option
      v-for="option in options"
      :key="option.id"
      :value="option.name"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'my-select-arr',
  props: {
    modelValue: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    changeOption() {
      const selectedOption = this.options.find(option => option.name === this.modelValue);
      if (selectedOption) {
        this.$emit('update:modelValue', { id: selectedOption.id, name: selectedOption.name });
      }
    }
  }
}
</script>