<template>
  <button class="btn btn-info btn-sm" @click="copyText">{{ name }}</button>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  name: 'copy-text',
  props: {
    text: String,
    name: String,
    message: String
  },
  methods: {
    ...mapMutations({setMessage: 'setMessage'}),

    copyText(){
      navigator.clipboard.writeText(this.text).then(() => {
          this.setMessage(this.message)
          setTimeout(() => this.setMessage(''), 2000)
        })
    }
  }


}
</script>

