import { createRouter, createWebHistory } from 'vue-router'
import Auth from '@/pages/Auth'
import Home from '@/pages/Home'
import Registration from '@/pages/Registration'
import Login from '@/pages/Login'
import store from '@/store/index'
import Error404 from '@/pages/Error404'
import MyProfile from '@/pages/users/MyProfile'
import RequisitesYur from '@/pages/users/RequisitesYur'
import BankTransfer from '@/pages/users/BankTransfer'
import InvoiceBalance from '@/pages/users/InvoiceBalance'
import MyBalanceHistory from '@/pages/users/MyBalanceHistory'
import Thanks from '@/pages/users/Thanks'
import Payment from '@/pages/users/Payment'
import Support from '@/pages/users/Support'
import CreateText from '@/pages/users/neurnetwork/CreateText'
import CreateImage from '@/pages/users/neurnetwork/CreateImage'
import MyChat from '@/pages/users/neurnetwork/MyChat'
import MyImages from '@/pages/users/neurnetwork/MyImages'
import MyText from '@/pages/users/neurnetwork/MyText'
import Api from '@/pages/users/neurnetwork/Api'
import Chat from '@/pages/users/neurnetwork/Chat'
import Prompt from '@/pages/users/neurnetwork/Prompt'
import PartnerInformation from '@/pages/users/partner/PartnerInformation'
import PartnerPromocode from '@/pages/users/partner/PartnerPromocode'
import PartnerReferrals from '@/pages/users/partner/PartnerReferrals'
import PartnerPayments from '@/pages/users/partner/PartnerPayments'
import PartnerReferralAccrual from '@/pages/users/partner/PartnerReferralAccrual'
import PartnerReferralPayments from '@/pages/users/partner/PartnerReferralPayments'
import MyTariff from '@/pages/users/MyTariff'
import Price from '@/pages/users/Price'
import ChatWidget from '@/pages/users/business/ChatWidget'
import SupportWidget from '@/pages/users/business/support-widget/SupportWidget'
import SupportInfoBase from '@/pages/users/business/support-widget/SupportInfoBase'
import SupportWidgetDialogs from '@/pages/users/business/support-widget/SupportWidgetDialogs'
import SupportWidgetContacts from '@/pages/users/business/support-widget/SupportWidgetContacts'
import MySystem from '@/pages/users/neurnetwork/MySystem'


const routes = [
  {path: '/', name: 'Auth', component: Auth },
  {path: '/?ref=:ref', redirect: '/' },
  {path: '/:pathMatch(.*)*', name: 'Error404', component: Error404 },
  {path: '/feed', name: 'Home', component: Home},
  {path: '/registration', name: 'Registrations', component: Registration},
  {path: '/login', name: 'Login', component: Login},
  {path: '/my-profile', name: 'MyProfile', component: MyProfile},
  {path: '/payment-details-yur', name: 'RequisitesYur', component: RequisitesYur},
  {path: '/bank-transfer', name: 'BankTransfer', component: BankTransfer},
  {path: '/invoice-balance', name: 'InvoiceBalance', component: InvoiceBalance},
  {path: '/my-balance-history', name: 'MyBalanceHistory', component: MyBalanceHistory},
  {path: '/thanks', name: 'Thanks', component: Thanks},
  {path: '/payment', name: 'Payment', component: Payment},
  {path: '/support', name: 'Support', component: Support},
  {path: '/create-text', name: 'CreateText', component: CreateText},
  {path: '/create-image', name: 'CreateImage', component: CreateImage},
  {path: '/my-chat', name: 'MyChat', component: MyChat},
  {path: '/my-images', name: 'MyImages', component: MyImages},
  {path: '/my-text', name: 'MyText', component: MyText},
  {path: '/api', name: 'Api', component: Api},
  {path: '/chat', name: 'Chat', component: Chat},
  {path: '/my-prompts', name: 'Prompt', component: Prompt},
  //{path: '/partner-information', name: 'PartnerInformation', component: PartnerInformation},
  //{path: '/partner-promocode', name: 'PartnerPromocode', component: PartnerPromocode},
  //{path: '/partner-referrals', name: 'PartnerReferrals', component: PartnerReferrals},
  //{path: '/partner-payments', name: 'PartnerPayments', component: PartnerPayments},
  //{path: '/partner-accrual', name: 'PartnerReferralAccrual', component: PartnerReferralAccrual},
  //{path: '/partner-referral-payments', name: 'PartnerReferralPayments', component: PartnerReferralPayments},
  {path: '/my-tariff', name: 'MyTariff', component: MyTariff},
  //{path: '/price', name: 'Price', component: Price},
  {path: '/chat-widget', name: 'ChatWidget', component: ChatWidget},
  {path: '/support-widget', name: 'SupportWidget', component: SupportWidget},
  {path: '/support-info-base', name: 'SupportInfoBase', component: SupportInfoBase},
  {path: '/support-widget-dialogs', name: 'SupportWidgetDialogs', component: SupportWidgetDialogs},
  //{path: '/support-widget-contacts', name: 'SupportWidgetContacts', component: SupportWidgetContacts},
  {path: '/my-system', name: 'MySystem', component: MySystem},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
  
})


router.beforeEach(async (to, from) => {
   if (!store.state.isAuth && to.name !== 'Login' && to.name !== 'Auth' && to.name !== 'Registrations' && to.name !== 'AuthRecover' && to.name !== 'Activation' && to.name !== 'Error404' && to.name !== 'ResetPassword') {
    return { name: 'Auth' }
  } else if (store.state.isAuth && to.name === 'Login' || store.state.isAuth && to.name === 'Registrations' || store.state.isAuth && to.name === 'AuthRecover' || store.state.isAuth && to.name === 'ResetPassword') {
    return { name: 'Home' }
  }
})

export default router
