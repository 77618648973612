<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }}</p>
      </div>
      <div v-if="ai.error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ ai.error }}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">

              <div class="row">
                <div class="col-sm-12 col-lg-9 chat-right-wrapper">
                  <div class="chat-content animate__animated animate__fadeIn   active">
                    <div class="card">
                      <div class="card-body msg-content">
                        <div class="msg-list">
                          <div v-if="ai.loading">
                            <loading-small />
                            <div class="text-secondary text-center">Ответ нейросети займет некоторое количество времени</div>
                          </div>
                          <div v-else>
                            <div v-if="ai.context" class="single-msg ">
                              <div class="single-msg-content">
                                <div class="msg-detail">
                                  <span>{{ ai.context }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="ai.context" class="mt-2 fz-12"><span class="text-secondary ml-3"> Промпт-токены: <b>{{ ai.enterTokens }}</b></span><span class="text-secondary ml-3"> Ответ-токены: <b>{{ ai.exitTokens }}</b></span><span class="text-danger ml-3"> ( - {{ ai.amountDebited }} руб.)</span></div>
                      <div class="card-footer px-3 py-3">
                        <div class="row mb-2">
                          <div class="col-sm-12 col-lg-4">
                            <transition name="fade">
                              <div v-if="svgRotate" class="form-group">
                                <label>Выберите готовый промпт</label>
                                <my-input list="brow" class="mt-2" :model-value="ai.readyPrompt" @update:model-value="setReadyPrompt" placeholder="Поиск по содержанию промпта" /> 
                                <datalist id="brow">
                                  <option
                                  v-for="option in sortTypeAi()"
                                  :key="option.id"
                                  :value="option.text"
                                  >
                                  </option>
                                </datalist> 
                              </div>
                            </transition>
                          </div>
                          <div class="col-sm-12 col-lg-7 text-right">
                            <transition name="fade">
                              <div v-if="svgRotate">
                                <!--<div class="btn-group btn-group-toggle btn-group-sm  btn-group-edges mb-2">
                                  <button @click="setPromptUser('Все')" :class="`button btn button-icon btn-outline-primary ${ai.promptUser === 'Все' ? 'bg-primary' : ''}`">Все</button>
                                  <button @click="setPromptUser('Пользовательские')" target="_blank" href="#" :class="`button btn button-icon btn-outline-primary ${ai.promptUser === 'Пользовательские' ? 'bg-primary' : ''}`">Мои</button>
                                  <button @click="setPromptUser('Системные')" target="_blank" href="#" :class="`button btn button-icon btn-outline-primary ${ai.promptUser === 'Системные' ? 'bg-primary' : ''}`">Системные</button>
                                </div><br>-->
                                <!--<my-select-array 
                                  class="custom-select w-272"
                                  :model-value="ai.promptTypeAi"
                                  @update:model-value="setPromptTypeAi"
                                  :options="ai.promptUser === 'Пользовательские' ? ['Все'] : ai.promptType"
                             
                                />-->
                              </div>
                            </transition>
                          </div>
                          <div class="col-sm-12 col-lg-1">
                            <svg @click="promptShowClose" :class="`${svgRotate ? 'svg-rotate svg-click' : 'svg-click'}`" width="50" height="50">
                              <line x1="10" y1="25" x2="40" y2="25" stroke="black" stroke-width="1" />
                              <line x1="25" y1="10" x2="25" y2="40" stroke="black" stroke-width="1" />
                            </svg>
                          </div>
                        </div>
                        <div class="form-group">
                        <my-textarea
                          :model-value="ai.prompt" @update:model-value="setPrompt"
                          class="form-text-neur"
                          placeholder="Обращение к нейроннной сети"
                        />
                        <div class="row">
                          <div class="col-lg-12 text-right">
                            <div v-if="ai.prompt && !ai.loading" @click="start" class="chat-button chat-button-active mt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                              </svg>
                            </div>
                            <div v-else class="chat-button mt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-3">
                  <div class="card ">
                    <div class="card-body">
                      <div class="form-group">
                        <label>Модель</label><br>
                        <my-select-array
                        class="custom-select"
                        :model-value="ai.nameAi"
                        @update:model-value="setNameAi"
                        :options="nameAi"
                        />
                      </div>
                      <div class="form-group mb-4">
                        <my-select-array
                        class="custom-select"
                        :model-value="ai.modelAi"
                        @update:model-value="setModelAi"
                        :options="modelAi()"
                        />
                      </div>
                      <div class="form-group mb-4">
                        <label>Креативность нейросети <my-required-field /></label><br>
                        <div class="row">
                          <div class="col-sm-12 col-lg-12">
                            <my-range 
                              :model-value="ai.temp" @update:model-value="setTemp"
                              :min="1" 
                              :max="10" 
                              :step="1"
                            />
                          </div>
                          <div class="col-sm-12 col-lg-12">
                            {{ ai.temp }}/10
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-4">
                        <label>Максимальное количество токенов <my-required-field /><my-required-field /></label><br>
                        <div class="row">
                          <div class="col-sm-12 col-lg-12">
                            <my-range 
                              :model-value="ai.maxToken" @update:model-value="setMaxToken"
                              :min="50" 
                              :max="ai.limitToken" 
                              :step="1"
                            />
                          </div>
                          <div class="col-sm-12 col-lg-12">
                            {{ ai.maxToken }}/{{ ai.limitToken }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12">
                  <div class="alert alert-primary" role="alert">
                    <div class="iq-alert-text">
                      <h5><b>Рекомендации по генерации текста на основе нейронной сети</b></h5><br>
                      <ul>
                        <li class="mb-2">Нейронные сети обладают обширными знаниями, но иногда они могут выдумывать информацию, которая не соответствует действительности. Поэтому важно всегда проверять сгенерированную информацию на точность перед ее использованием.</li>
                        <li class="mb-2"><my-required-field /> Параметр "Креативность нейронной сети" определяет, насколько оригинальными или тривиальными будут её ответы. Низкая креативность приводит к более точным и консервативным ответам, в то время как высокая креативность приводит к более творческим и менее предсказуемым результатам. Подстройка уровня креативности позволяет вам выбирать между надёжностью и оригинальностью в ответах нейронной сети. По умолчанию стоят оптимальные настройки креативности</li>
                        <li class="mb-2"> <my-required-field /><my-required-field /> "Максимальное количество токенов" для нейронной сети определяет объем текста, который искусственный интеллект может произвести за один запрос. Минимальное значение этого параметра приведет к более лаконичным ответам. Если ваша цель — получение обширного текста, например статьи, следует установить более высокий лимит токенов, что позволит модели развернуть мысль и представить более полную информацию. Это управление помогает находить баланс между информативностью ответов и их экономической эффективностью.</li>
                        
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import CopyText from '@/components/CopyText'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  data(){
    return {
      svgRotate: false,
    }
  },
  components: {
    Header,
    CopyText
  },
  methods: {
    ...mapMutations({setPromptTypeAi: 'ai/setPromptTypeAi', setPromptUser: 'ai/setPromptUser', setPrompt: 'ai/setPrompt', setReadyPrompt: 'ai/setReadyPrompt', setNameAi: 'ai/setNameAi', setModelAi: 'ai/setModelAi', setTemp: 'ai/setTemp', setMaxToken: 'ai/setMaxToken', setAmountDebited: 'ai/setAmountDebited', setEnterTokens: 'ai/setEnterTokens', setExitTokens: 'ai/setExitTokens', setContext: 'ai/setContext'}),
    ...mapActions({actionAiContext: 'ai/actionAiContext' }),
    ...mapGetters({sortTypeAi: 'ai/sortTypeAi', modelAi: 'ai/modelAi'}),
    
    promptShowClose() {
      this.svgRotate = !this.svgRotate
      this.sortTypeAi()
    },

    start(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.actionAiContext()
    }
  },

  computed: {
    ...mapState({
      ai: state => state.ai,
      nameAi: state => state.ai.modelAiList.map(({name}) => name),
      message: state => state.message,
    }),
  },

  mounted(){
    document.title = 'Генерация любого текста - CognitiveAI"'
  },

  unmounted(){
    this.setAmountDebited(0)
    this.setEnterTokens(0)
    this.setExitTokens(0)
    this.setPrompt(null)
    this.setContext(null)
  }
}
</script>

<style>
  .form-text-neur{
    line-height: 17px;
    min-height: 200px;
  }

  .fz-13{
    font-size: 13px;
  }

  .svg-rotate{
    rotate:45deg;
  }

  .svg-click{
    cursor: pointer;
  }

  datalist::-webkit-calendar-picker-indicator, input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
.w-272{
  width: 272px;
}
textarea.form-control{
  resize: block;
}
.chat-button{
  display: inline-block;
  padding: 12px;
}
.chat-button svg{
  color: #f1f1f1;
  width: 25px;
}
.chat-button-active{
  cursor: pointer;
}

.chat-button-active svg{
  color: #3378ff;
}

.chat-button-active:hover{
  border-radius: 5px;
  background-color: #f1f1f1;
}

.fz-12{
  font-size: 12px;
}
  
</style>