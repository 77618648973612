<template>
  <input type="range" :min="min" :max="max" :step="step" :value="modelValue"  @input="updateInput" class="custom-range" />
</template>

<script>
export default {
  name: 'my-range-array',
  props: {
    modelValue: [Number, String],
    min: Number,
    max: Number,
    step: Number,
    id: [String, Number]
  },

  methods: {
    updateInput(event){
      this.$emit('update:modelValue',{id: this.id, name: event.target.value}) 
    }
  }

}
</script>